import TSVGPropTypes from '../SVGPropTypes';

function ArrowButtonCircle({
  strokeWidth,
  strokeColor,
  bgColor,
  ...props
}: TSVGPropTypes) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M43.5 22C43.5 10.1259 33.8741 0.5 22 0.5C10.1259 0.5 0.5 10.1259 0.5 22C0.5 33.8741 10.1259 43.5 22 43.5C33.8741 43.5 43.5 33.8741 43.5 22Z"
        fill={bgColor ?? 'black'}
      />
      <path
        d="M43.5 22C43.5 10.1259 33.8741 0.5 22 0.5C10.1259 0.5 0.5 10.1259 0.5 22C0.5 33.8741 10.1259 43.5 22 43.5C33.8741 43.5 43.5 33.8741 43.5 22Z"
        stroke={bgColor ?? 'black'}
      />
      <path
        d="M28.875 22H15.125"
        stroke={strokeColor ?? 'white'}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.75 16.375L15.125 22L20.75 27.625"
        stroke={strokeColor ?? 'white'}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ArrowButtonCircle;
