const mapData = {
  toronto: [
    {
      type: 'point',
      coords: [-79.383935, 43.653482],
      name: 'Toronto',
    },
  ],
  rexall: [
    {
      type: 'point',
      coords: [-79.477694, 43.670094],
      name: 'Origin',
    },
  ],
  transport: [
    {
      name: '512 Streetcar',
      coords: [
        [-79.47177, 43.67201],
        [-79.471142, 43.67179],
        [-79.467527, 43.672562],
        [-79.462455, 43.673656],
        [-79.458241, 43.674606],
        [-79.454318, 43.675459],
        [-79.450277, 43.67635],
        [-79.445935, 43.677307],
        [-79.441643, 43.678266],
        [-79.437248, 43.679238],
        [-79.43335, 43.680095],
        [-79.428557, 43.681111],
        [-79.424602, 43.681934],
        [-79.420811, 43.682632],
        [-79.417455, 43.683315],
      ],
      poiNumber: 1,
      lineColor: 'red',
      lineWidth: 3,
      lineOpacity: 0,
      source: 'street-car',
      index: 38,
    },
    {
      name: 'Subway Bloor–Danforth Line',
      coords: [
        [-79.524305, 43.645377],
        [-79.511143, 43.648248],
        [-79.494088, 43.649714],
        [-79.483812, 43.649882],
        [-79.475726, 43.651693],
        [-79.467856, 43.653678],
        [-79.459485, 43.655581],
        [-79.452894, 43.657003],
        [-79.44261, 43.659101],
        [-79.43536, 43.660312],
        [-79.425987, 43.662391],
        [-79.418244, 43.664142],
        [-79.41102, 43.665992],
        [-79.404615, 43.668209],
        [-79.398741, 43.668393],
        [-79.390172, 43.67019],
        [-79.385665, 43.670818],
        [-79.376465, 43.672212],
        [-79.372983, 43.672137],
        [-79.372684, 43.672174],
        [-79.372273, 43.672218],
        [-79.371666, 43.672334],
        [-79.371262, 43.672442],
        [-79.370799, 43.672588],
        [-79.368806, 43.673599],
        [-79.358424, 43.676789],
        [-79.352535, 43.678195],
        [-79.344822, 43.679865],
        [-79.330188, 43.682546],
        [-79.323019, 43.684103],
        [-79.312579, 43.686259],
        [-79.3016, 43.689031],
      ],
      lineColor: 'green',
      lineWidth: 3,
      lineOpacity: 0,
      source: 'bloor-danforth',
      poiNumber: 2,
      index: 39,
    },
    {
      name: 'UP Express / GO Transit',
      coords: [
        [-79.442405, 43.649321],
        [-79.456934, 43.66396],
        [-79.459247, 43.666487],
        [-79.468001, 43.675214],
        [-79.471721, 43.678691],
        [-79.472991, 43.679741],
        [-79.474987, 43.681119],
        [-79.476619, 43.682103],
        [-79.478615, 43.683186],
        [-79.481337, 43.684563],
        [-79.483514, 43.685547],
        [-79.487233, 43.687351],
        [-79.489002, 43.688237],
        [-79.496668, 43.692009],
        [-79.501388, 43.694338],
        [-79.502931, 43.695289],
      ],
      lineColor: 'salmon',
      lineWidth: 3,
      lineOpacity: 0,
      poiNumber: 3,
      source: 'up-express',
      index: 40,
    },
    {
      name: 'West Toronto Rail Path',
      poiNumber: 4,
      coords: [
        [-79.441243, 43.648504],
        [-79.450466, 43.657687],
        [-79.454998, 43.662392],
        [-79.465204, 43.672675],
        [-79.465421, 43.673673],
        [-79.4655, 43.674357],
        [-79.4655, 43.674429],
      ],
      lineColor: 'yellow',
      lineWidth: 3,
      lineOpacity: 0,
      source: 'west-toronto',
      index: 41,
    },
  ],
  schools: [
    {
      index: 0,
      poiNumber: 1,
      type: 'point',
      coords: [-79.478944, 43.675903],
      zoom: 15,
      name: 'Harwood Public School',
    },
    {
      index: 1,
      poiNumber: 2,
      type: 'point',
      coords: [-79.48687, 43.66313],
      zoom: 15,
      name: 'King George Junior Public School',
    },
    {
      index: 2,
      poiNumber: 3,
      type: 'point',
      coords: [-79.47236, 43.662826],
      zoom: 15,
      name: 'The Clover School Montessori',
    },
    {
      index: 3,
      poiNumber: 4,
      type: 'point',
      coords: [-79.473168, 43.661405],
      zoom: 15,
      name: 'Annette Street Junior & Senior Public School',
    },
    {
      index: 4,
      poiNumber: 5,
      type: 'point',
      coords: [-79.474533, 43.660623],
      zoom: 15,
      name: 'Saint Cecilia Catholic Elementary School',
    },
    {
      index: 5,
      poiNumber: 6,
      type: 'point',
      coords: [-79.472249, 43.661409],
      zoom: 15,
      name: 'High Park Alternative Junior School',
    },
  ],
  recreation: [
    {
      index: 6,
      poiNumber: 1,
      type: 'point',
      coords: [-79.480147, 43.668034],
      zoom: 15,
      name: 'Runnymede Park',
    },
    {
      index: 7,
      poiNumber: 2,
      type: 'point',
      coords: [-79.496773, 43.658126],
      zoom: 15,
      name: 'Humber River Recreational Trail',
    },
    {
      index: 8,
      poiNumber: 3,
      type: 'point',
      coords: [-79.460766, 43.653117],
      zoom: 15,
      name: 'High Park',
    },
    {
      index: 9,
      poiNumber: 4,
      type: 'point',
      coords: [-79.477377, 43.668438],
      zoom: 15,
      name: 'George Bell Arena',
    },
    {
      index: 10,
      poiNumber: 5,
      type: 'point',
      coords: [-79.497588, 43.675461],
      zoom: 15,
      name: 'Smythe Park Outdoor Pool',
    },
    {
      index: 11,
      poiNumber: 6,
      type: 'point',
      coords: [-79.507931, 43.669854],
      zoom: 15,
      name: 'Lambton Golf & Country Club',
    },
    {
      index: 12,
      poiNumber: 7,
      type: 'point',
      coords: [-79.468303, 43.673337],
      zoom: 15,
      name: 'BATL Axe Throwing',
    },
    {
      index: 13,
      poiNumber: 8,
      type: 'point',
      coords: [-79.467981, 43.672901],
      zoom: 15,
      name: 'Anytime Fitness',
    },
    {
      index: 14,
      poiNumber: 9,
      type: 'point',
      coords: [-79.460406, 43.672372],
      zoom: 15,
      name: 'Felix’s Swim School',
    },
  ],
  restaurants: [
    {
      index: 15,
      poiNumber: 1,
      type: 'point',
      coords: [-79.47873, 43.673579],
      zoom: 15,
      name: 'Junction Craft Brewery',
    },
    {
      index: 16,
      poiNumber: 2,
      type: 'point',
      coords: [-79.483169, 43.669836],
      zoom: 15,
      name: 'High Park Brewery',
    },
    {
      index: 17,
      poiNumber: 3,
      type: 'point',
      coords: [-79.481373, 43.669615],
      zoom: 15,
      name: 'Stockyards Grind',
    },
    {
      index: 18,
      poiNumber: 4,
      type: 'point',
      coords: [-79.470965, 43.67212],
      zoom: 15,
      name: 'Shoeless Joe’s',
    },
    {
      index: 19,
      poiNumber: 5,
      type: 'point',
      coords: [-79.46741, 43.671564],
      zoom: 15,
      name: 'Swiss Chalet',
    },
    {
      index: 20,
      poiNumber: 6,
      type: 'point',
      coords: [-79.465817, 43.664872],
      zoom: 15,
      name: 'Nodo Junction',
    },
    {
      index: 21,
      poiNumber: 7,
      type: 'point',
      coords: [-79.466806, 43.664705],
      zoom: 15,
      name: 'Playa Cabana Cantina',
    },
    {
      index: 22,
      poiNumber: 8,
      type: 'point',
      coords: [-79.469885, 43.664667],
      zoom: 15,
      name: 'Pizzeria Libretto',
    },
    {
      index: 23,
      poiNumber: 9,
      type: 'point',
      coords: [-79.472495, 43.665614],
      zoom: 15,
      name: 'Dirty Food Eatery',
    },
  ],
  shops: [
    {
      index: 24,
      poiNumber: 1,
      type: 'point',
      coords: [-79.469886, 43.673402],
      zoom: 15,
      name: 'Stockyards Village Mall',
    },
    {
      index: 25,
      poiNumber: 2,
      type: 'point',
      coords: [-79.469356, 43.672444],
      zoom: 15,
      name: 'Nations Experience',
    },
    {
      index: 26,
      poiNumber: 3,
      type: 'point',
      coords: [-79.472154, 43.67062],
      zoom: 15,
      name: 'Canadian Tire',
    },
    {
      index: 27,
      poiNumber: 4,
      type: 'point',
      coords: [-79.472983, 43.670501],
      zoom: 15,
      name: 'LCBO',
    },
    {
      index: 28,
      poiNumber: 5,
      type: 'point',
      coords: [-79.472713, 43.669882],
      zoom: 15,
      name: 'The Beer Store',
    },
    {
      index: 29,
      poiNumber: 6,
      type: 'point',
      coords: [-79.4732, 43.669654],
      zoom: 15,
      name: 'Metro',
    },
    {
      index: 30,
      poiNumber: 7,
      type: 'point',
      coords: [-79.467671, 43.671761],
      zoom: 15,
      name: 'Shoppers Drug Mart',
    },
    {
      index: 31,
      poiNumber: 8,
      type: 'point',
      coords: [-79.463974, 43.667711],
      zoom: 15,
      name: 'Organic Garage',
    },
    {
      index: 32,
      poiNumber: 9,
      type: 'point',
      coords: [-79.468554, 43.666784],
      zoom: 15,
      name: 'The Sweet Potato',
    },
    {
      index: 33,
      poiNumber: 10,
      type: 'point',
      coords: [-79.471077, 43.662575],
      zoom: 15,
      name: 'The Good Neighbour Espresso Bar',
    },
    {
      index: 34,
      poiNumber: 11,
      type: 'point',
      coords: [-79.484928, 43.668463],
      zoom: 15,
      name: 'Walmart',
    },
    {
      index: 35,
      poiNumber: 12,
      type: 'point',
      coords: [-79.495917, 43.66537],
      zoom: 15,
      name: 'Loblaws',
    },
    {
      index: 36,
      poiNumber: 13,
      type: 'point',
      coords: [-79.486119, 43.667557],
      zoom: 15,
      name: 'Starbucks',
    },
    {
      index: 37,
      poiNumber: 14,
      type: 'point',
      coords: [-79.496731, 43.66522],
      zoom: 15,
      name: 'Starbucks',
    },
  ],
};

export default mapData;
