import { useEffect, useState } from 'react';
import styled from 'styled-components';

import AmenitiesController from 'common/src/web_components/AmenitiesController';
import Popup from 'common/src/components/Popup';
import CloseIcon from 'common/src/components/CloseIcon';
import Render from 'common/src/components/Render';
import { useStore } from '@state/store';
import globalStyles from 'common/src/data/globalStyles';
import { amenitiesData } from 'common/src/data/amenitiesData';
import { useSyncStateWithSocket } from '@hooks/socket/useSyncStateWithSocket';
import CubeIcon from 'common/src/components/SVGIcons/CubeIcon';
import EyeIcon from 'common/src/components/SVGIcons/EyeIcon';

function Amenities() {
  const { syncMultipleStatesWithSocket } = useSyncStateWithSocket();
  const iconsArr = [EyeIcon, CubeIcon];

  const { amenityIndex, amenityRenderOpen } = useStore((s) => ({
    amenityIndex: s.amenityIndex,
    amenityRenderOpen: s.amenityRenderOpen,
  }));
  const renderData = amenitiesData;

  const { unitCardSection, syncStates } = useStore((s) => ({
    unitCardSection: s.unitCardSection,

    syncStates: s.syncStates,
  }));

  useEffect(() => {
    return () => {
      syncMultipleStatesWithSocket({
        amenityIndex: null,
        amenityRenderOpen: false,
      });
    };
  }, []);

  return (
    <AmenitiesWrapper>
      <AmenitiesController
        amenityIndex={amenityIndex}
        amenityRenderOpen={amenityRenderOpen}
        onInit={() => {
          syncMultipleStatesWithSocket({
            amenityIndex: null,
            amenityRenderOpen: false,
          });
        }}
        onLabelClick={(index: number) => {
          syncMultipleStatesWithSocket({
            amenityIndex: index,
          });
        }}
        onExplore={(amenityIndex: number) => {
          syncMultipleStatesWithSocket({
            amenityIndex,
            amenityRenderOpen: true,
          });
        }}
        onListOpenChange={(open) => {
          if (!open) {
            // TODO - do we need this on the controller or even send it to the immersion room ?
            syncMultipleStatesWithSocket({
              amenityIndex: null,
            });
          }
        }}
      />
      <Popup
        openState={amenityRenderOpen}
        setOpenState={(v) => null}
        durationMS={800}
        // TODO - should this be 'transparent' or 'black' ?
        closeColor={globalStyles.colors.black}
        // closeColor={"transparent"}
      >
        <Render
          url={amenitiesData[amenityIndex]?.src}
          condition={amenityRenderOpen && unitCardSection === 0}
        />
        <RenderWrapper className="withBg">
          <h1>{renderData[amenityIndex]?.label}</h1>
          <p>{renderData[amenityIndex]?.copy}</p>
        </RenderWrapper>
        <CloseIcon
          className="closeIcon"
          bgColor={globalStyles.colors.second}
          strokeColor={globalStyles.colors.white}
          strokeWidth={2}
          onClick={() => {
            syncMultipleStatesWithSocket({
              amenityRenderOpen: false,
            });
            syncStates({ unitCardSection: 0 });
          }}
        />
        {/* TODO - do we need these inside the controller or just web ? */}
        <Icons>
          {iconsArr.map((Icon, i) => {
            return (
              <Icon
                key={`unitCardIcon${i}`}
                bgColor={
                  unitCardSection === i
                    ? globalStyles.colors.white
                    : globalStyles.colors.black
                }
                strokeColor={
                  unitCardSection === i
                    ? globalStyles.colors.black
                    : globalStyles.colors.white
                }
                strokeWidth={0.7}
                className="sectionIcon"
                onClick={() => {
                  syncStates({ unitCardSection: i });
                }}
              />
            );
          })}
        </Icons>
      </Popup>
    </AmenitiesWrapper>
  );
}

export default Amenities;

const AmenitiesWrapper = styled.div`
  width: 100%;
  height: 100%;
  pointer-events: none;

  .btns {
    cursor: pointer;
    > div {
      opacity: 0.5;
    }
  }

  .right {
    top: unset;
    bottom: 9%;
  }

  .left {
    position: absolute;
    bottom: 8%;
  }

  .building {
    width: 20vw;
    height: 20vw;
    bottom: 0%;
    left: 50%;
    transform: translate(-50%, 0%);
    position: absolute;
    pointer-events: none;
  }

  .closeIcon {
    position: absolute;
    top: ${globalStyles.margin};
    left: ${globalStyles.margin};
    width: 3.5rem;
    height: 3.5rem;
    z-index: 5;
    pointer-events: all;
    cursor: pointer;
  }
`;

const RenderWrapper = styled.div`
  position: absolute;
  top: 10%;
  left: ${globalStyles.margin};
  width: fit-content;
  height: fit-content;
  padding: ${globalStyles.margin};

  &.withBg {
    background-color: #0000007b;
    border-color: ${globalStyles.colors.main};
    border-radius: ${globalStyles.borderRadius};
  }

  & h1 {
    position: relative;
    font-size: 2.5rem;
    font-family: title;
    color: ${globalStyles.colors.second};
    margin-bottom: 0.5rem;
  }

  & p {
    position: relative;
    font-size: 1rem;
    line-height: 1.8rem;
    font-family: title;
    font-weight: 100;
    color: ${globalStyles.colors.white};
    max-width: clamp(400px, 33vw, 600px);
  }
`;

const Icons = styled.div`
  pointer-events: all;
  cursor: pointer;
  position: absolute;
  bottom: ${globalStyles.margin};
  right: ${globalStyles.margin};
  display: flex;
  gap: ${globalStyles.margin};
  z-index: 1000;
  width: 10rem;
`;
