/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, forwardRef } from 'react';
import styled from 'styled-components';

import VideoOrbit from '../../components/VideoOrbit';

import { amenitiesData } from '../../data/amenitiesData';
import globalStyles from '../../data/globalStyles';
import { TInitialState } from '../../data/initialState';
// import meshOrbitData from '../../data/meshOrbitData';

interface IOrbitProps
  extends Pick<
    TInitialState,
    | 'orbitIndex'
    | 'orbitDir'
    | 'orbitRunning'
    | 'unitCardOpen'
    | 'amenityRenderOpen'
    | 'rexallSection'
    | 'amenityIndex'
  > {
  onDestroy: () => void;
  onOrbitIndexChange: (index: number) => void;
  onOrbitRunningChange: (running: boolean) => void;
  udpateAmenityIndex?: (index: number) => void;
}

const AmenitiesOrbit = forwardRef(function Orbit(
  {
    orbitIndex,
    orbitDir,
    orbitRunning,
    unitCardOpen,
    amenityRenderOpen,
    rexallSection,
    amenityIndex,
    onDestroy,
    onOrbitIndexChange,
    onOrbitRunningChange,
    udpateAmenityIndex,
  }: IOrbitProps,
  ref,
) {
  useEffect(() => {
    return () => {
      onDestroy();
    };
  }, []);

  const isTouch =
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    //@ts-expect-error n\a
    navigator.msMaxTouchPoints > 0;

  return (
    <OrbitWrapper
      className="centerABS"
      id="orbitWrapper"
      style={{
        opacity:
          rexallSection !== 'amenities' || unitCardOpen || amenityRenderOpen
            ? 0
            : 1,
      }}
    >
      <VideoOrbit
        direction={orbitDir}
        fwdVideos={[
          '/assets/video/0.mp4',
          '/assets/video/1.mp4',
          '/assets/video/2.mp4',
          '/assets/video/3.mp4',
        ]}
        bwdVideos={[
          '/assets/video/3-reversed.mp4',
          '/assets/video/0-reversed.mp4',
          '/assets/video/1-reversed.mp4',
          '/assets/video/2-reversed.mp4',
        ]}
        wrapperProps={{
          className: 'videoWrapper centerABS show',
        }}
        videoProps={{
          playsInline: true,
        }}
        indexState={orbitIndex}
        indexSet={
          rexallSection === 'aerial view'
            ? undefined
            : (n) => {
                onOrbitIndexChange(n);
              }
        }
        playState={orbitRunning && rexallSection === 'amenities'}
        playSet={(v) => {
          onOrbitRunningChange(v);
        }}
        lockWhilePLaying={true}
        delayMS={200}
      />
      {!orbitRunning && rexallSection === 'amenities' && (
        <MarkersWrapper $istouch={isTouch}>
          <svg
            x="0"
            y="0"
            width="100%"
            height="100%"
            viewBox="0 0 2500 1000"
            className="markers"
          >
            {/* <image height={1000} width={2500} href={meshOrbitData.day.base[orbitIndex]} className="img" /> */}
            {amenitiesData.map((data, i) => {
              const match = amenityIndex !== null && amenityIndex === i;
              const inView = data.svgConfig.visible[orbitIndex];
              return (
                <g
                transform={`translate(${data?.svgConfig.x[orbitIndex] ?? 0}, ${data?.svgConfig.y[orbitIndex] ?? 0})`}
                key={`amenityOverlay${i}`}
                className="groupAmenity"
                onClick={(e) => {
                  e.stopPropagation();
                  udpateAmenityIndex && udpateAmenityIndex(i);
                }}
              >
                <circle
                  cx={match ? 75 : 75}
                  cy={match ? 75 : 75}
                  r={20}
                  fill="none"
                  stroke={match ? globalStyles.colors.second : 'white'}
                  strokeWidth={10}
                  strokeOpacity={match ? 1 : 0.5}
                  strokeDasharray={inView ? 0 : 10}
                  strokeDashoffset={inView ? 0 : 10}
                  style={{ transform: `scale(${match ? 1 : 1})`, transition: "0.3s" }}
                />
                <circle
                  cx={match ? 75 : 75}
                  cy={match ? 75 : 75}
                  r={10}
                  fill={
                    inView
                      ? match
                        ? globalStyles.colors.second
                        : 'white'
                      : 'transparent'
                  }
                  fillOpacity={match ? 1 : 0.5}
                  style={{ transform: `scale(${match ? 1 : 1})`, transition: "0.3s" }}
                />
                <text
                  transform={`scale(${match ? 1.2 : 1})`}
                  x={45}
                  y={120}
                  className="amenityLabel"
                  style={{ transform: `scale(${match ? 1 : 1})`, transition: "0.3s" }}
                >
                  {data?.label}
                </text>
              </g>
              
              );
            })}
          </svg>
        </MarkersWrapper>
      )}
    </OrbitWrapper>
  );
});
export default AmenitiesOrbit;

const OrbitWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transition: opacity 0.8s linear;
  top: 0%;

  .videoWrapper {
    transition: opacity;
    transition-duration: 200ms;
    & video {
      object-fit: cover;
    }
  }

  .show {
    opacity: 1;
  }

  .hide {
    opacity: 0;
  }
`;

const MarkersWrapper = styled.div<{ $istouch: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  top: 0;
  left: 0;
  svg {
    width: auto;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  g {
    pointer-events: all;
    cursor: pointer;
    .amenityLabel {
      display: none;
    }
  }

  ${(p) =>
    !p.$istouch &&
    `
    g.groupAmenity:hover {
      transition: 0.6s;
    cursor: pointer;
    circle:first-child {
      transition: 0.3s;
      stroke: ${globalStyles.colors.second};
    }
    .amenityLabel {
    transition: 0.3s;
      stroke: ${globalStyles.colors.second};
      fill: ${globalStyles.colors.second};
      display: initial;
    }
  }`}
`;
