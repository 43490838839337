import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

type ControlsCatcherProps = {
  onRefChange: (ref: HTMLDivElement | null) => void;
  onDestroy: () => void;
};

const ControlsCatcher = ({ onRefChange, onDestroy }: ControlsCatcherProps) => {
  const canvasReference = React.useRef<HTMLDivElement | null>(null);
  const { pathname } = useLocation();

  const controlPaths = ['/rexall', '/tour'];

  useEffect(() => {
    onRefChange(canvasReference.current);
    return () => {
      onDestroy();
    };
  }, [canvasReference]);

  return (
    <EventElementWr
      ref={canvasReference}
      style={{
        display: controlPaths.includes(pathname) ? 'initial' : 'none',
      }}
    />
  );
};

export default ControlsCatcher;

const EventElementWr = styled.div`
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
