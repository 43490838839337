import { TSheetUnit, TUnit, formatData } from "./formatData";

const dataURL =
'https://docs.google.com/spreadsheets/d/e/2PACX-1vSmmCjfga-RZVrjp8SfolhrPEUk6GZKJeaiZX5FNTVO4FF22EPf1l5pcRUSqSSTeKhJM6FKrcNyG3qL/pub?gid=0&single=true&output=csv';


export const fetchData = async () => {
  try {
    const response = await fetch(dataURL);
    const text = await response.text();
    const data = text.split('\r\n').map((line) => line.split(','));

    const header = data[0];
    const values = data.slice(1);
    const formatedData = [] as TUnit[];

    values?.forEach((value) => {
      const obj = {};
      header.forEach((key, i) => {
        obj[key] = value[i];
      });

      const unit: TUnit = formatData(obj as TSheetUnit);
      if (unit.status) formatedData.push(unit);
    });
    return formatedData;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
}