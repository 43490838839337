import { useEffect } from 'react'
import styled from 'styled-components'
import gsap from 'gsap'

function FullWidthLiving({finishState, imgProps={}, ...props}) {
  const paths = {
    full: [
      "/assets/images/interiors/full-width/immersion-kitchen-light.webp",
      "/assets/images/interiors/full-width/immersion-kitchen-medium.webp",
      "/assets/images/interiors/full-width/immersion-kitchen-dark.webp",
    ],
    thumbs: [
      "/assets/images/interiors/full-width/thumb/immersion-kitchen-light.webp",
      "/assets/images/interiors/full-width/thumb/immersion-kitchen-medium.webp",
      "/assets/images/interiors/full-width/thumb/immersion-kitchen-dark.webp",
    ],
  }

  useEffect(() => {
    const images = gsap.utils.toArray<HTMLImageElement>(".fullWidthRender")
    gsap.to(".fullWidthRender", {
      opacity: (i) => i === finishState ? 1 : 0,
      onStart: () => {
        images.forEach((img, i) => {
          img.src = paths.thumbs[i]
        })
      }, 
      onComplete: () => {
        images.forEach((img, i) => {
          if(i === finishState){
            img.src = paths.full[i]
          }
        })
      }, 
    })
  }, [finishState])
  return (
    <FullLivingWrapper {...props}>
      {
        paths.thumbs.map((path, i) => {
           return (
            <img key={`fullWidthRender${i}`} src={path} className='fullWidthRender' {...imgProps}/>
           )
        })
      }
    </FullLivingWrapper>
  )
}

export default FullWidthLiving

const FullLivingWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("/assets/images/interiors/full-width/thumb/immersion-kitchen-medium.webp");
  background-size: 100% 100%;

  .fullWidthRender{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`