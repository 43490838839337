import styled from 'styled-components';

type TButtonProps = {
  label: string;
  active?: boolean;
  notClickable?: boolean;
  animated?: boolean;
  [x: string]: unknown;
};

function Button(props: TButtonProps) {
  const { label, active=true, notClickable = false, animated = true, ...rest } = props;
  return (
    <Btn
      $active={active}
      $notClickable={notClickable}
      $animated={animated}
      {...rest}
    >
      {label}
    </Btn>
  );
}

export default Button;

type TStyledButton = {
  $active: boolean;
  $notClickable: boolean;
  $animated?: boolean;
};

const Btn = styled.div<TStyledButton>`
  width: clamp(150px, 8rem, 200px);
  height: clamp(30px, 4rem, 50px);
  display: grid;
  place-content: center;
  overflow: clip;
  font-size: 100%;
  letter-spacing: 0.1rem;
  pointer-events: all;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  font-family: title;
  border-radius: 100px;
  border: 1px solid;
  border-color: ${p => p.$active || p.$notClickable ? "black" : "white"};
  background-color: ${p => p.$active || p.$notClickable ? "white" : "black"};
  color: ${p => p.$active || p.$notClickable ? "black" : "white"};
  transition: ${p => p.$animated ? "all 0.5s ease-in-out" : "none"};
  opacity: ${p => p.$notClickable ? "0.5" : "1"};
  pointer-events: ${p => p.$notClickable ? "none" : "all"};  
`;
