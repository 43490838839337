import TSVGPropTypes from '../SVGPropTypes';

function Heart({
  strokeWidth,
  strokeColor,
  bgColor,
  ...props
}: TSVGPropTypes) {
  return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 23 22"
        width="100%"
        height="100%"
        {...props}
      >
        <path
          d="M12.9944 19.832C12.9243 19.8987 12.8538 19.9658 12.783 20.0331C12.7824 20.0337 12.7818 20.0342 12.7812 20.0348L11.5001 21.2325L10.2189 20.0348C10.2182 20.0343 10.2176 20.0337 10.217 20.0331C10.1449 19.9644 10.073 19.8961 10.0014 19.828C7.12745 17.094 4.78182 14.8626 3.13631 12.7667C1.45724 10.6281 0.560692 8.69544 0.560692 6.59421C0.560692 3.16482 3.09137 0.560692 6.32475 0.560692C8.15811 0.560692 9.89922 1.43561 11.0647 2.87114L11.5 3.40734L11.9353 2.8711C13.1005 1.43562 14.8416 0.560692 16.6753 0.560692C19.9089 0.560692 22.4393 3.16465 22.4393 6.59421C22.4393 8.69543 21.5428 10.6283 19.8637 12.7669C18.2174 14.8639 15.8703 17.0964 12.9944 19.832Z"
          stroke={strokeColor}
          fill={bgColor}
          strokeWidth={strokeWidth}
        />
      </svg>
  )
}

export default Heart

