import styled from 'styled-components';

import AerialController from 'common/src/web_components/AerialController';

import { useStore } from '@state/store';
import { useSyncStateWithSocket } from '@hooks/socket/useSyncStateWithSocket';

function Aerial() {
  const { aerialUp } = useStore(
    (s) => ({
      aerialUp: s.aerialUp
    }),
  );
  const { syncMultipleStatesWithSocket } = useSyncStateWithSocket();

  const handleStateChange = () => {
    // TODO - this is not required on the web build mode, should probably do check inside the hook
    syncMultipleStatesWithSocket({
      aerialDir: !aerialUp,
      aerialRunning: true,
    });
  }


  return (
    <AerialContainer > 
      <AerialController onInit={handleStateChange}/>
    </AerialContainer>
  );
}

export default Aerial;

const AerialContainer = styled.div`
  width: 100%;
  height: 100%;
  opacity: 1;

  .left {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
  }

  .right {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
  }

  .gradient {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 110%;
  }
`;
