import styled from 'styled-components';
import globalStyles from '../../data/globalStyles';

import CloseIcon from '../CloseIcon';
import Button from '../Button';

import { TUnit } from '../../lib/formatData';

type TFavCardProps = {
  data: TUnit;
  exploreClick?: () => void;
  closeClick?: () => void;
  [x: string]: unknown;
};

function FavoriteCard({ data, exploreClick, closeClick, ...props }: TFavCardProps) {
  return (
    <FavoriteCardWrapper {...props}>
      <Title>
        <h1>Residence {data.unit}</h1>
        <CloseIcon
          bgColor={"transparent"}
          strokeColor={globalStyles.colors.white}
          strokeWidth={2}
          className="close"
          onClick={() =>{
            closeClick && closeClick()
          }}
        />
      </Title>
      <Floorplan src={`/assets/images/floorplans/2d/${data?.type}.png`} />
      <Info>
        <h3>
          BEDROOMS:<span> {data && BEDS[data?.beds]}</span>
        </h3>
        <h3>
          FLOOR:<span> {[data?.level]}</span>
        </h3>
        <h3>
          EXT. AREA:<span> {[data?.extArea]} sqft</span>
        </h3>
        <h3>
          INT. AREA:<span> {[data?.intArea]} sqft</span>
        </h3>
        <h3>
          ORIENTATION:<span> {[data?.aspect]}</span>
        </h3>
      </Info>
      <Button
        label="EXPLORE"
        active={false}
        style={{
          width: '85%',
        }}
        onClick={() => {
          exploreClick && exploreClick()
        }}
      />
    </FavoriteCardWrapper>
  );
}

export default FavoriteCard;

const FavoriteCardWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  flex-shrink: 0;
  width: clamp(300px, 500px, 80vw);
  height: clamp(550px, 800px, 70vh);
  border: 2px solid ${globalStyles.colors.white};
  border-radius: ${globalStyles.borderRadius};
  touch-action: pan-x;
  gap: 1rem;
  background-color: ${globalStyles.colors.black};
  opacity: 0.9;
  
  > * {
    touch-action: pan-x;
  }
`;

const Title = styled.div`
  width: 95%;
  border-bottom: 1px solid ${globalStyles.colors.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;

  > * {
    touch-action: pan-x;
  }

  & h1 {
    font-size: 3rem;
    color: ${globalStyles.colors.white};
    font-family: title;
    font-weight: 100;
  }

  .close {
    width: 3rem;
    height: 3rem;
    border: 1px solid ${globalStyles.colors.white};
    border-radius: 100%;
  }
`;

const Floorplan = styled.img`
  position: relative;
  width: 80%;
  height: 40%;
  object-fit: contain;
`;

const Info = styled.div`
  position: relative;
  width: 95%;
  height: 25%;
  padding: 10px;
  border-top: 1px solid ${globalStyles.colors.white};

  > * {
    touch-action: pan-x;
  }

  & h3 {
    position: relative;
    font-size: 1.2rem;
    font-family: title;
    font-weight: 100;
    letter-spacing: 0.1rem;
    width: 100%;
    height: 2.5rem;
    color: ${globalStyles.colors.white};
  }

  & h3 span {
    width: 50%;
    display: inline-block;
    position: absolute;
    right: 0%;
    color: ${globalStyles.colors.white};
    font-size: 1rem;
    font-family: main;
    font-weight: 600;
    letter-spacing: initial;
    text-align: left;
    text-transform: capitalize;
  }
`;

const BEDS = {
  1: 'One',
  2: 'Two',
  3: 'Three',
};
