import { useRef, useEffect, useState } from 'react';
import gsap from 'gsap';

import CameraControls from './components/CameraControls';
import Sphere from './components/Sphere';

type TProps = {
  textures: string[];
  camera?: unknown;
  initialPosition?: [number, number, number];
  controls?: boolean;
  controlsProps?: { [x: string]: unknown };
  activeIndex?: number;
  show?: boolean;
  hotspotClick?: (v: number) => void;
  [x: string]: unknown;
};

function SphericalTour(props: TProps) {
  const {
    show = true,
    controls,
    textures,
    initialPosition,
    controlsProps = {},
    activeIndex = 0,
    hotspotClick,
  } = props;
  return (
    <group {...props}>
      {controls && <CameraControls {...controlsProps} />}
      <Sphere
        textures={textures}
        initialPosition={hotspotIndexMap}
        activeIndex={activeIndex}
        display={show}
      />
      {show && hotspotClick && <Hotspots activeIndex={activeIndex} hotspotClick={hotspotClick} />}
    </group>
  );
}

export default SphericalTour;

function Hotspots({
  activeIndex,
  hotspotClick,
}: {
  activeIndex: number;
  hotspotClick?: (v: number) => void;
}) {
  const [index, setIndex] = useState<null | number>(null);
  const groupRef = useRef<THREE.Group>(null);

  useEffect(() => {
    if (index === null) {
      setIndex(activeIndex);
    } else {
      if (!groupRef.current) return;
      const children = groupRef.current.children;
      children.forEach((mesh) => {
        //@ts-expect-error jjj
        gsap.to(mesh.material, {
          opacity: 0,
          onComplete: () => {
            setIndex(activeIndex);
          },
        });
      });
    }
  }, [activeIndex]);

  useEffect(() => {
    if (!groupRef.current) return;
    const children = groupRef.current.children;
    children.forEach((mesh) => {
      //@ts-expect-error jjj
      gsap.to(mesh.material, {
        opacity: 1,
      });
    });
  }, [index]);
  return (
    index !== null && (
      <group ref={groupRef} renderOrder={1}>
        {hotspotIndexMap[index].map((obj, i) => {
          return (
            <mesh
              key={`hotspot${i}`}
              position={obj.position}
              onClick={() => {
                hotspotClick && hotspotClick(obj.toIndex);
              }}
            >
              <sphereGeometry args={[12, 20, 20]} />
              <meshBasicMaterial color={'#000000'} opacity={0} transparent={true} />
            </mesh>
          );
        })}
      </group>
    )
  );
}

const hotspotsData = {
  mainBedroom: [
    {
      name: 'mainBedroom2',
      toIndex: 1,
      position: [0, 0, 400],
      rotation: [0,-2,0]
    },
    {
      name: 'hallway',
      toIndex: 4,
      position: [300, 0, -260],
      rotation: [0,0,0]
    },
  ],
  mainBedroom2: [
    {
      name: 'mainBedroom',
      toIndex: 0,
      position: [-350, 0, -100],
      rotation: [0,-0.75,0]
    },
  ],
  secondBedroom: [
    {
      name: 'hallway',
      toIndex: 4,
      position: [350, 0, -120],
      rotation: [0,-0.5,0]
    },
    {
      name: 'secondBedroom2',
      toIndex: 3,
      position: [-300, 0, -200],
      rotation: [0,1,0]
    },
  ],
  secondBedroom2: [
    {
      name: 'secondBedroom',
      toIndex: 2,
      position: [-380, 0, 0],
      rotation: [0,-0.1,0]
    },
  ],
  hallway: [
    {
      name: 'mainBedroom',
      toIndex: 0,
      position: [300, 0, 250],
      rotation: [0,2.5,0]
    },
    {
      name: 'secondBedroom',
      toIndex: 2,
      position: [-350, 0, -120],
      rotation: [0,5,0]
    },
    {
      name: 'livingRoom',
      toIndex: 5,
      position: [380, 0, 100],
      rotation: [0,5,0]
    },
  ],
  livingRoom: [
    {
      name: 'mainBedroom',
      toIndex: 0,
      position: [180, 0, 350],
      rotation: [0,-2,0]
    },
    {
      name: 'hallway',
      toIndex: 4,
      position: [-150, 0, 350],
      rotation: [0,5,0]
    },
    {
      name: 'livingRoom2',
      toIndex: 6,
      position: [-200, 0, -300],
      rotation: [0,5,0]
    },
  ],
  livingRoom2: [
    {
      name: 'livingRoom',
      toIndex: 5,
      position: [250, 0, 250],
      rotation: [0,5,0]
    },
    {
      name: 'hallway',
      toIndex: 4,
      position: [140, 0, 360],
      rotation: [0,2,0]
    },
    {
      name: 'mainBedroom',
      toIndex: 0,
      position: [200, 0, 300],
      rotation: [0,-2,0]
    },
  ],
};

const hotspotIndexMap = {
  0: hotspotsData.mainBedroom,
  7: hotspotsData.mainBedroom,
  14: hotspotsData.mainBedroom,
  1: hotspotsData.mainBedroom2,
  8: hotspotsData.mainBedroom2,
  15: hotspotsData.mainBedroom2,
  2: hotspotsData.secondBedroom,
  9: hotspotsData.secondBedroom,
  16: hotspotsData.secondBedroom,
  3: hotspotsData.secondBedroom2,
  10: hotspotsData.secondBedroom2,
  17: hotspotsData.secondBedroom2,
  4: hotspotsData.hallway,
  11: hotspotsData.hallway,
  18: hotspotsData.hallway,
  5: hotspotsData.livingRoom,
  12: hotspotsData.livingRoom,
  19: hotspotsData.livingRoom,
  6: hotspotsData.livingRoom2,
  13: hotspotsData.livingRoom2,
  20: hotspotsData.livingRoom2,
};
