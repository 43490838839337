import { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';

import TextureTransitionMaterial from './TextureTransitionMaterial';

import gsap from 'gsap';

type TProps = {
  textures: string[];
  textureTransform?: (texture: THREE.Texture) => void;
  initialPosition: any;
  activeIndex?: number;
  display?: boolean;
};

function Sphere({
  textures,
  activeIndex = 0,
  initialPosition,
  display,
}: TProps) {
  const [visible, setVisible] = useState(false);
  const matRef = useRef<THREE.MeshBasicMaterial>();

  useEffect(() => {
    //@ts-expect-error N/A
    gsap.to(matRef.current.uniforms.uOpacity, {
      value: display ? 1 : 0,
      delay: display ? 2 : 0,
      onStart: () => {
        display && setVisible(true);
      },
      onComplete: () => {
        !display && setVisible(false);
      },
    });
  }, [display]);

  const rotation = initialPosition[activeIndex][0].rotation


  return (
    <mesh
      visible={visible}
      position={[0, -10, 0]}
      rotation={rotation}
    >
      <sphereGeometry args={[400, 60, 40]} />
      <TextureTransitionMaterial
        triggerIndex={activeIndex}
        textures={textures}
        toneMapped={false}
        side={THREE.BackSide}
        ref={matRef}
      />
    </mesh>
  );
}

export default Sphere;
