import { useRef, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';

import { randomNumberInRange } from '../../utils/functions';
import globalStyles from '../../data/globalStyles';

function AnimatedCircles({ cue, colorsArr }) {
  const circlesRef = useRef<HTMLDivElement[] | []>([]);
  const circleTimelineRef = useRef<gsap.core.Timeline>(
    gsap.timeline({ repeat: -1, yoyo: true, paused: false }),
  );

  const bgRef = useRef(null);

  const [positionx] = useState(
    colorsArr.map(() => randomNumberInRange(20, 80)),
  );
  const [positiony] = useState(
    colorsArr.map(() => randomNumberInRange(45, 55)),
  );

  const [size] = useState(colorsArr.map(() => randomNumberInRange(50, 100)));

  const circles = useMemo(
    () =>
      colorsArr.map((color, i) => {
        // console.log(color)
        return (
          <Circle
            color={color}
            position={[positionx[i], positiony[i]]}
            size={size[i]}
            className="centerABS"
            key={`circle${i}`}
            ref={(node) => {
              if (node) {
                circlesRef.current[i] = node;
              }
            }}
          />
        );
      }),
    [colorsArr, positionx, positiony, size],
  );

  const circleTweenConfig = {
    duration: 10,
    ease: 'power1.inOut',
  };

  function tween() {
    gsap.to(bgRef.current, {
      duration: 2,
      opacity: cue ? 1 : 0,
      delay: 1.5,
      ease: 'none',
      onComplete: () => {
        if (!cue) {
          gsap.set(circlesRef.current, {
            y: 0,
            x: 0,
          });
        }
      },
    });
  }

  const minTranslation = -3500;
  const maxTranslation = 3500;

  useEffect(() => {
    try {
      const circleTimeline = circleTimelineRef.current;
      circleTimeline.clear();
      circleTimeline.to(circlesRef.current, {
        y: `random(-${minTranslation * 0.2}, ${maxTranslation * 0.2})`,
        x: `random(-${minTranslation}, ${maxTranslation})`,
        scale: `random(0.8, 2)`,
        ...circleTweenConfig,
      });
      if (bgRef.current) {
        tween();
      }
      if (cue) {
        if (circleTimeline.paused()) {
          circleTimeline.play();
        }
      } else {
        if (!circleTimeline.paused()) {
          circleTimeline.pause();
        }
      }
    } catch (e) {
      console.error('Error animating circles:', e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cue, circlesRef]);

  useEffect(() => {
    return () => {
      // gsap.ticker.remove(update)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      circleTimelineRef.current.kill();
    };
  }, []);

  return <GradientWrapper ref={bgRef}>{circles}</GradientWrapper>;
}

export default AnimatedCircles;

type TCircleProps = {
  color: string;
  position: [number, number];
  size: number;
};

const GradientWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: ${globalStyles.colors.black};
  opacity: 0;
`;

const Circle = styled.div<TCircleProps>`
  position: absolute;
  left: ${(p) => p.position[0]}%;
  top: ${(p) => p.position[1]}%;
  background: ${(p) => p.color};
  width: ${(p) => p.size}vh;
  aspect-ratio: 1/1;
  border-radius: 100%;
  filter: blur(250px);
`;
