const specCopy = [
  {
    list: false,
    title: 'The start of beautiful living.',
    body: [
      'Inspired by the philosophy that a home should be more than just four walls, our team of visionary architects and designers crafted a living space that seamlessly blends modern sophistication with a rich tapestry of texture, form, and artistry.',
    ],
  },
  {
    list: true,
    title: 'GENERAL',
    body: [
      'Laminate flooring in living, dining, kitchen, and slepping spaces.',
      'Laundry areas to be 12"x12" floor tiles.',
      'White stacked front-loading washer and dryer',
      'Approximately 4" baseboards with coordinating 2" door casings.',
    ],
  },
  {
    list: true,
    title: 'MECHANICAL / ELECTRICAL',
    body: [
      'Individual in-suite controlled all-season heating and cooling system.',
      'Individual suite electricity meter(s) and water meter(s).',
      'Fibre cable to each suite location determined by vendor.',
    ],
  },
  {
    list: true,
    title: 'KITCHEN / LIVING',
    body: [
      'Custom-designed kitchen with approximately 12" deep uppers and slab cabinetry in one of 3 colour palettes.',
      'Contemporary kitchen backsplash.',
      'Single basin stainless steel undermount sink with single-lever pull-down spray faucet in chrome finish.',
    ],
  },
  {
    list: true,
    title: 'BATHROOM',
    body: [
      'Bathroom vanity with quartz countertop and integrated sink.',
      'Pressure-balanced valve(s) for tub and/or shower.',
      'Clear glass shower partition or chrome framed shower enclosure with door (as per plan) with recessed shower pot light where separate shower stall is shown.',
    ],
  },
];

export default specCopy;
