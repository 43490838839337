/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import styled from 'styled-components';

import Button from 'common/src/components/Button';
import ArrowButtonIcon from 'common/src/components/SVGIcons/ArrowButtonIcon';

import globalStyles from 'common/src/data/globalStyles';
import { TInitialState } from '../../data/initialState';

interface IRexallSectionsControlProps extends Pick<TInitialState, 'rexallSection' | 'unitCardOpen' | 'orbitIndex' | 'amenityRenderOpen'> {
  handleSectionClick: (label: any) => void;
  onOrbitButtonClick: (dir: boolean, index: number) => void;
  onDestroy: () => void;
}

function RexallSectionsControl({
  rexallSection,
  unitCardOpen,
  // aerialUp,
  orbitIndex,
  amenityRenderOpen,
  handleSectionClick,
  onOrbitButtonClick,
  onDestroy
}: IRexallSectionsControlProps) {
  const sections = ['residences', 'amenities', 'aerial view'];
  // const { syncMultipleStatesWithSocket } = useSyncStateWithSocket();

  function handleClick(label: any) {
    handleSectionClick(label);
  }

  function getButtonBg(index, dir) {
    const maxIndex = 3;
    const currentIndex = dir
      ? index + 1 > maxIndex
        ? 0
        : index + 1
      : index - 1 < 0
        ? maxIndex
        : index - 1;

    return `/assets/images/nav/orbitButtons/${currentIndex}.webp`;
  }

  useEffect(() => {
    return () => {
      onDestroy();
    };
  }, []);

  return (
    <Controls
      style={{
        opacity: unitCardOpen ? 0 : 1,
        pointerEvents: unitCardOpen ? 'none' : 'all',
      }}
    >
      <Left>
        {sections.map((label, i) => {
          return (
            <Button
              key={i}
              onClick={() => {
                handleClick(label);
              }}
              label={label}
              active={rexallSection === label}
              notClickable={
                label === 'aerial view' && rexallSection === 'aerial view'
              }
              className="btn"
            />
          );
        })}
      </Left>
      <Right>
        <OrbitButton
          style={{
            backgroundImage: `url(${getButtonBg(orbitIndex, false)})`,
          }}
          onClick={() => {
            onOrbitButtonClick(false, orbitIndex);
          }}
          className="btn"
          $notClickable={
            rexallSection === 'aerial view' || unitCardOpen || amenityRenderOpen
          }
        >
          <ArrowButtonIcon strokeWidth={1} strokeColor="white" />
        </OrbitButton>
        <OrbitButton
          style={{
            backgroundImage: `url(${getButtonBg(orbitIndex, true)})`,
          }}
          onClick={() => {
            onOrbitButtonClick(true, orbitIndex);
          }}
          $notClickable={
            rexallSection === 'aerial view' || unitCardOpen || amenityRenderOpen
          }
          className="btn"
        >
          <ArrowButtonIcon
            strokeWidth={1}
            strokeColor="white"
            className="rightArrow"
          />
        </OrbitButton>
      </Right>
    </Controls>
  );
}

export default RexallSectionsControl;

const Controls = styled.div`
  position: absolute;
  z-index: 5;
  width: calc((100% - ${globalStyles.margin}) - ${globalStyles.margin});
  bottom: ${globalStyles.margin};
  left: ${globalStyles.margin};
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  transition: opacity 0.5s ease-in-out;

  .btn {
    transition-property: opacity, color;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
    cursor: pointer;
  }

  .rightArrow {
    transform: scaleX(-1);
  }
`;

const Left = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  gap: 2%;
`;

const Right = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 2%;
`;

type TStyledButton = {
  $notClickable: boolean;
};

const OrbitButton = styled.div<TStyledButton>`
  width: clamp(150px, 8rem, 200px);
  height: clamp(30px, 4rem, 50px);
  border-radius: 100px;
  border: 1px solid;
  transition-property: background-image, opacity;
  transition-duration: 0.5s;
  transition-timing-function: linear;
  opacity: ${(p) => (p.$notClickable ? '0' : '1')};
  pointer-events: ${(p) => (p.$notClickable ? 'none' : 'all')};
  background-size: 100% auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0.5%;
  cursor: pointer;

  :nth-of-type(1) {
    justify-content: flex-start;
  }

  :nth-of-type(2) {
    justify-content: flex-end;
  }

  & svg {
    height: 90%;
    width: auto;
  }
`;
