import TSVGPropTypes from '../SVGPropTypes';

function ShareIcon({
  strokeWidth,
  strokeColor,
  bgColor,
  ...props
}: TSVGPropTypes) {
  return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        width="100%"
        height="100%"
        cursor="pointer"
        {...props}
      >
        <circle
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          fill={bgColor}
          cx="22.7"
          cy="50"
          r="13"
        />
        <circle
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          fill={bgColor}
          cx="77"
          cy="22.7"
          r="13"
        />
        <circle
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          fill={bgColor}
          cx="77"
          cy="77"
          r="13"
        />

        <line
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          x1="34"
          y1="43.9"
          x2="65.1"
          y2="28.3"
        />
        <line
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          x1="33.2"
          y1="56.4"
          x2="65.1"
          y2="73.1"
        />
      </svg>
  )
}

export default ShareIcon
