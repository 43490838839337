import { useState, useEffect, useRef } from 'react';
import * as THREE from 'three';
import { useLoader } from '@react-three/fiber';
import gsap from 'gsap';

type TProps = {
  activeIndex: number;
  show: boolean;
  initialRotation: [number, number, number];
  [x: string]: unknown;
};

function View360(props: TProps) {
  const { activeIndex, show, initialRotation, ...rest } = props;
  const [visible, setVisible] = useState(false);
  const matRef = useRef<THREE.MeshBasicMaterial>(null);
  const textures = [
    '/assets/images/views/4.webp',
    '/assets/images/views/6.webp',
    '/assets/images/views/9.webp',
    '/assets/images/views/12.webp',
  ];
  const mainTextures = useLoader(THREE.TextureLoader, textures, () => null);

  mainTextures.forEach((texture) => {
    texture.minFilter = THREE.LinearFilter;
    texture.wrapS = THREE.RepeatWrapping;
    texture.repeat.x = -1
    texture.repeat.y = 3.1
    texture.wrapT = THREE.RepeatWrapping
    texture.offset.y = 0
  });

  useEffect(() => {
    if (show) {
    gsap.to(matRef.current, {
      opacity:   1 ,
    })
    setVisible(true);
    }
    else if (!show) {
      setVisible(false);
      gsap.to(matRef.current, {
        opacity:   1 ,
        duration: 2
      })
      }
  }, [show]);

  return (
    <group {...rest}>
      <mesh visible={visible} position={[100, 100, 100]} rotation={initialRotation}>
        <sphereGeometry args={[1500, 60, 40]} />
        <meshBasicMaterial
          toneMapped={false}
          map={mainTextures[activeIndex]}
          side={THREE.BackSide}
          transparent
          ref={matRef}
        />
      </mesh>
    </group>
  );
}

export default View360;
