import { Routes, Route, Outlet } from 'react-router-dom';
import styled from 'styled-components';
import routes from './routes/routes';
import gsap from 'gsap';
import CustomEase from 'gsap/CustomEase';
import { useLocation } from 'react-router-dom';

import { useStore } from '@state/store';

gsap.registerPlugin(CustomEase);
CustomEase.create('spring', '0.175, 0.885, 0.32, 1.275');

function Content() {
  const { pathname } = useLocation();
  const controlPaths = ["/rexall", "/tour"];
  return (
    <ContentWrapper
      style={{
        pointerEvents: controlPaths.includes(pathname) ? 'none' : 'initial',
      }}
    >
      <Routes>{generateRoutes(routes)}</Routes>
    </ContentWrapper>
  );
}

function generateRoutes(routeArray) {
  return routeArray.map((route, idx) => {
    const { element: Element, path, sub } = route;

    return (
      Element && (
        <Route key={idx} path={path} element={<Element />}>
          {sub && (
            <>
              {generateRoutes(sub)}
              {/* <Outlet /> */}
            </>
          )}
        </Route>
      )
    );
  });
}

export default Content;

const ContentWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;
