/* eslint-disable @typescript-eslint/no-explicit-any */
import { TransformWrapper, TransformComponent, ReactZoomPanPinchRef } from "react-zoom-pan-pinch";
import './styles.css';
import { useEffect, useRef } from "react";

export const UnitViewPan = ({src}: {src: string, offset: string}) => {
  // const unit = document.documentElement.window / 100;
  // const scrollVal = unit * Number(offset.replace('%', ''));
  const zoomRef = useRef<ReactZoomPanPinchRef>(null);
  // TODO - use offset to scroll the image

  // scrollBy(scrollVal, 0);
  useEffect(() => {
    if (zoomRef.current) {
      setTimeout(() => {
        zoomRef?.current?.centerView()
        // zoomRef?.current?.setTransform(-2300, 0, 0.5)
      }, 80)
    }
  }, [src])


  return (
    <div className="unit-view-pan-container"
      style={{
        width: '100%', 
        height: '100%',
    }}>
      <TransformWrapper
        ref={zoomRef}
        initialScale={0.7}
        minScale={0.7}
        maxScale={0.7}
        centerOnInit={true}
        limitToBounds={true}
        panning={{ lockAxisY: true }}
      >
        <TransformComponent
          wrapperStyle={{
            height: "100%",
            width: "100%",
            display: "grid",
            placeItems: "center",
          }}
          contentStyle={{
            height: "100%",
            width: "100%",
            display: "grid",
            placeItems: "center",
          }}
        >
          {src && (
            <img
              src={src}
              alt="Unit View"
            />
          )}
        </TransformComponent>
      </TransformWrapper>
    </div>
  );
};

