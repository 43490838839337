export const stackImages = [
  '/assets/images/amenities/chefs-room.webp',
  '/assets/images/amenities/fitness-centre.webp',
  '/assets/images/amenities/fitness-yoga.webp',
  '/assets/images/amenities/kids-room.webp',
  '/assets/images/amenities/lobby.webp',
  '/assets/images/amenities/media-room.webp',
  '/assets/images/amenities/social-lounge.webp',
];

export const controllerFinalPositions = [
  [innerWidth * 0.97, innerHeight * 0.42],
  [innerWidth * 0.97, innerHeight * 0.5],
  [innerWidth * 0.97, innerHeight * 0.58],
  [innerWidth * 0.97, innerHeight * 0.66],
  [innerWidth * 0.97, innerHeight * 0.74],
  [innerWidth * 0.97, innerHeight * 0.82],
  [innerWidth * 0.97, innerHeight * 0.9],
];

export const amenitiesData = [
  {
    src: '/assets/images/amenities/lobby.webp',
    immersionSrc: '/assets/images/amenities/immersion/lobby.webp',
    label: 'Lobby',
    copy: 'Prepare to be embraced by the opulent Lobby as you enter Origin, where a true sense of arrival awaits, foreshadowing the numerous exquisite amenities that lie beyond.',
    location: 'Ground Floor',
    index: 0,
    svgConfig: {
      visible: [true, true, false, true],
      x: [1050, 750, 1350, 1600],
      y: [670, 600, 550, 650],
      line: {
        x1: 75,
        y1: 75,
        x2: 500,
        y2: 430,
      },
    },
  },
  {
    src: '/assets/images/amenities/fitness-centre.webp',
    immersionSrc: '/assets/images/amenities/immersion/fitness-centre.webp',
    label: 'Fitness Center',
    copy: 'Start your fitness journey at Origin, where style meets function in a state-of-the-art space complete with weight training and aerobic equipment.',
    location: 'Ground Floor',
    index: 1,
    svgConfig: {
      visible: [true, true, false, false],
      x: [1450, 1150, 850, 1000],
      y: [670, 770, 640, 650],
      line: {
        x1: 75,
        y1: 75,
        x2: -50,
        y2: 615,
      },
    },
  },
  {
    src: '/assets/images/amenities/fitness-yoga.webp',
    immersionSrc: '/assets/images/amenities/immersion/fitness-yoga.webp',
    label: 'Yoga Room',
    copy: 'Indulge in true relaxation and immerse yourself in an environment specifically designed to enhance your overall well-being. Embrace tranquility like never before. Namaste.',
    location: 'Ground Floor',
    index: 2,
    svgConfig: {
      visible: [true, true, false, false],
      x: [1250, 950, 1050, 1200],
      y: [675, 700, 600, 670],
      line: {
        x1: 75,
        y1: 75,
        x2: -50,
        y2: 615,
      },
    },
  },

  {
    src: '/assets/images/amenities/social-lounge.webp',
    immersionSrc: '/assets/images/amenities/immersion/social-lounge.webp',
    label: 'Social Hub',
    location: 'Terrace',
    copy: 'The Social Lounge, perfect for hosting cozy get-togethers, embodies a delightful contemporary ambiance that seamlessly blends comfort with style.',
    index: 3,
    svgConfig: {
      visible: [true, true, false, true],
      x: [980, 850, 1350, 1570],
      y: [180, 200, 170, 270],
      line: {
        x1: 75,
        y1: 75,
        x2: 300,
        y2: 180,
      },
    },
  },
  {
    src: '/assets/images/amenities/chefs-room.webp',
    immersionSrc: '/assets/images/amenities/immersion/chefs-room.webp',
    label: "Chef's Table",
    copy: 'Whether you decide to handle the catering for your event on your own, or hire a professional, the Private Dining Room is an exceptional spot to break bread and make memories.',
    location: 'Terrace',
    index: 4,
    svgConfig: {
      visible: [true, false, false, true],
      x: [870, 970, 1450, 1530],
      y: [220, 190, 170, 280],
      line: {
        x1: 75,
        y1: 75,
        x2: 200,
        y2: 300,
      },
    },
  },
  {
    src: '/assets/images/amenities/kids-room.webp',
    immersionSrc: '/assets/images/amenities/immersion/kids-room.webp',
    label: "Kid's Room",
    copy: 'Designed to appeal to kids of all ages, the Playroom at Origin is a bright, colourful space beautifully appointed with kid-friendly furnishings and outfitted with places to explore, create, and play.',
    location: 'Terrace',
    index: 5,
    svgConfig: {
      visible: [true, false, true, true],
      x: [760, 1090, 1550, 1490],
      y: [265, 180, 170, 290],
      line: {
        x1: 75,
        y1: 75,
        x2: 0,
        y2: 200,
      },
    },
  },

  {
    src: '/assets/images/amenities/terrace.webp',
    immersionSrc: '/assets/images/amenities/immersion/terrace.webp',
    label: 'Roof Terrace',
    copy: 'Experience the perfect blend of open-air dining, relaxed socializing with friends, and immersing yourself in the captivating night sky - all against a backdrop of breathtaking panoramic views! Welcome to The Terrace at Origin, where the beauty of nature effortlessly transforms into an upscale, refined setting.',
    location: 'Terrace',
    index: 6,
    svgConfig: {
      visible: [true, true, true, true],
      x: [1350, 1320, 1000, 850],
      y: [250, 50, 50, 200],
      line: {
        x1: 75,
        y1: 75,
        x2: 0,
        y2: 200,
      },
    },
  },
  {
    src: '/assets/images/amenities/media-room.webp',
    immersionSrc: '/assets/images/amenities/immersion/media-room.webp',
    label: 'Media Room',
    copy: '',
    location: 'MediaRoom',
    index: 7,
    svgConfig: {
      visible: [true, false, false, false],
      x: [850, 940, 950, 1100],
      y: [672, 580, 619, 650],
      line: {
        x1: 75,
        y1: 75,
        x2: 0,
        y2: 200,
      },
    },
  },
];

export type TAnmData = {
  src: string;
  immersionSrc: string;
  label: string;
  copy: string;
  location: 'Ground Floor' | 'Terrace';
  index: number;
  svgConfig: {
    visible: boolean[];
    x: number[];
    y: number[];
    line: {
      x1: number;
      y1: number;
      x2: number;
      y2: number;
    };
  };
};
