import { useState, useEffect } from 'react';
import styled from 'styled-components';

import RexallSectionsControl from 'common/src/web_components/RexallSectionsControl';
import Amenities from './Amenities';
import Residences from './Residences';
import Aerial from './Aerial';
import TransitionDiv from 'common/src/components/TransitionDiv';
import NightOrbit from 'common/src/web_components/NightOrbit';
import ResidencesOrbit from 'common/src/web_components/ResidencesOrbit';
import AmenitiesOrbit from 'common/src/web_components/Orbit';
import AerialOrbit from 'common/src/web_components/AerialOrbit';

import { useStore } from '@state/store';
import UnitCard from 'common/src/web_components/UnitCard';
import MenuButton from 'common/src/web_components/MenuButton';
import { useSyncStateWithSocket } from '@hooks/socket/useSyncStateWithSocket';
import { useSocketIO } from 'common/src/providers/sockets/SocketIOProvider';
import gsap from 'gsap';

function Rexall() {
  const [sectionIndex, setSectionIndex] = useState(0);

  const {
    menuOpen,
    rexallSection,
    selectedUnit,
    orbitIndex,
    aerialDir,
    aerialRunning,
    aerialUp,
    unitCardOpen,
    amenityRenderOpen,
    favorites,
    unitCardSection,
    palette,
    orbitDir,
    orbitRunning,
    amenityIndex,
    fullUnits,
    filteredUnits,
    rowClicked,
    syncStates,
  } = useStore((s) => ({
    rexallSection: s.rexallSection,
    menuOpen: s.menuOpen,
    selectedUnit: s.selectedUnit,
    orbitIndex: s.orbitIndex,
    aerialDir: s.aerialDir,
    aerialRunning: s.aerialRunning,
    aerialUp: s.aerialUp,
    unitCardOpen: s.unitCardOpen,
    amenityRenderOpen: s.amenityRenderOpen,
    favorites: s.favorites,
    unitCardSection: s.unitCardSection,
    palette: s.palette,
    orbitDir: s.orbitDir,
    orbitRunning: s.orbitRunning,
    amenityIndex: s.amenityIndex,
    fullUnits: s.fullUnits,
    filteredUnits: s.filteredUnits,
    rowClicked: s.rowClicked,
    syncStates: s.syncStates,
  }));
  const sections = ['residences', 'amenities', 'aerial view'];
  const childrens = [<Residences />, <Amenities />, <Aerial />, <></>];
  const { syncMultipleStatesWithSocket } = useSyncStateWithSocket();
  const { emitSyncStateEvent } = useSocketIO();

  useEffect(() => {
    const index = sections.indexOf(rexallSection);
    setSectionIndex(index === -1 ? 0 : index);
    emitSyncStateEvent({
      stateToUpdate: 'rexallSection',
      payload: rexallSection,
    });
  }, [rexallSection]);

  useEffect(() => {
    if (unitCardOpen) gsap.to('.markers', { autoAlpha: 0 });
    else if (!unitCardOpen) gsap.to('.markers', { autoAlpha: 1 });
  }, [unitCardOpen]);

  useEffect(() => {
    if (amenityIndex !== null) gsap.to('.markers', { autoAlpha: 0 });
    else gsap.to('.markers', { autoAlpha: 1 });
  }, [amenityIndex]);

  useEffect(() => {
    return () => {
      syncStates({
        rexallSection: 'amenities',
        unitCardOpen: false,
        unitCardSection: 0,
        selectedUnit: null,
        orbitIndex: 0,
        amenityRenderOpen: false,
        amenityIndex: null,
      });
    };
  }, []);

  useEffect(() => {
    {
      console.log(rowClicked);
      if (selectedUnit) {
        let newOrbitIndex;
        let aspect;
        if (selectedUnit.aspect[1])
          aspect = JSON.stringify(
            selectedUnit.aspect[0] + selectedUnit.aspect[1],
          );
        else aspect = selectedUnit.aspect[0];

        const unitStr = String(selectedUnit.unit);
        const lastTwoDigits = parseInt(unitStr.slice(-2), 10);
        console.log(lastTwoDigits);

        if (selectedUnit.unit === '1107' || selectedUnit.unit === '1007')
          newOrbitIndex = 2;
        else if (lastTwoDigits > 28 && selectedUnit.level < 10)
          newOrbitIndex = 2;
        else if (
          lastTwoDigits <= 17 &&
          lastTwoDigits >= 14 &&
          selectedUnit.level < 10
        )
          newOrbitIndex = 1;
        else if (
          lastTwoDigits >= 2 &&
          lastTwoDigits <= 6 &&
          aspect === 'S' &&
          selectedUnit.level < 10
        )
          newOrbitIndex = 3;
        else if (
          lastTwoDigits >= 1 &&
          lastTwoDigits < 2 &&
          aspect === 'S' &&
          selectedUnit?.level < 10
        )
          newOrbitIndex = 2;
        else if (aspect === 'N' || aspect === '"NE"') newOrbitIndex = 0;
        else if (aspect === '"NW"') newOrbitIndex = 1;
        else if (aspect === 'W') newOrbitIndex = 1;
        else if (aspect === '"SW"') newOrbitIndex = 3;
        else if (aspect === 'S') newOrbitIndex = 3;
        else if (aspect === 'E') newOrbitIndex = 0;
        else if (aspect === '"SE"') newOrbitIndex = 3;
        else newOrbitIndex = 0;

        const tl = gsap.timeline();

        tl.to(`._0, ._1, ._2, ._3`, { opacity: 0, duration: 0.3 })
          .to(`.svgOverlays`, { opacity: 0, duration: 0.3 }, '<')
          .to(
            `._${newOrbitIndex}, .markers`,
            { opacity: 1, duration: 0.3 },
            '<',
          )
          .add(() => {
            syncStates({ orbitIndex: newOrbitIndex });
          })
          .to(`.svgOverlays`, { opacity: 1, autoAlpha: 1, duration: 0.3 })
          .to(`._${newOrbitIndex}`, { opacity: 0, duration: 0.3 });
      }
    }
  }, [rowClicked]);

  return (
    <RexallWrapper
      style={{
        backgroundImage:
          amenityRenderOpen || unitCardOpen || rexallSection === 'aerial view'
            ? 'none'
            : `none`,
      }}
    >
      <SectionWrapper>
        <TransitionDiv
          childrenArr={childrens}
          triggerIndex={sectionIndex}
          durationMS={1000}
        />
      </SectionWrapper>
      {!amenityRenderOpen && (
        <RexallSectionsControl
          rexallSection={rexallSection}
          unitCardOpen={unitCardOpen}
          orbitIndex={orbitIndex}
          amenityRenderOpen={amenityRenderOpen}
          handleSectionClick={(label) => {
            if (aerialUp) {
              syncMultipleStatesWithSocket({
                aerialDir: !aerialUp,
                aerialRunning: true,
              });
              setTimeout(() => {
                syncStates({
                  rexallSection: label,
                });
              }, 1100);
            } else {
              syncStates({
                rexallSection: label,
              });
            }
          }}
          onOrbitButtonClick={(dir, index) => {
            emitSyncStateEvent({
              stateToUpdate: 'orbitIndex',
              payload: index,
            });
            syncMultipleStatesWithSocket({
              orbitDir: dir,
              orbitRunning: true,
              selectedUnit: null,
            });
          }}
          onDestroy={() => {
            syncMultipleStatesWithSocket({
              rexallSection: 'amenities',
            });
          }}
        />
      )}
      <UnitCard
        excludeFinishes={true}
        data={selectedUnit}
        favorites={favorites}
        unitCardOpen={unitCardOpen}
        unitCardSection={unitCardSection}
        palette={palette}
        onCloseClicked={() => {
          syncMultipleStatesWithSocket({
            unitCardOpen: false,
            unitCardSection: 0,
          });
        }}
        onFavouritesToggled={(favorites) => {
          syncMultipleStatesWithSocket({
            favorites,
          });
        }}
        onPaletteClicked={(palette) => {
          syncMultipleStatesWithSocket({
            palette,
          });
        }}
        onUnitCardSectionClicked={(unitCardSection) => {
          syncMultipleStatesWithSocket({
            unitCardSection,
          });
        }}
      />
      <MenuButton
        onMenuClicked={() => {
          syncMultipleStatesWithSocket({
            menuOpen: true,
            menuIndex: null,
          });
          console.log('button', menuOpen);
        }}
      />
      <AmenitiesOrbit
        orbitIndex={orbitIndex}
        orbitDir={orbitDir}
        orbitRunning={orbitRunning}
        unitCardOpen={unitCardOpen}
        amenityRenderOpen={amenityRenderOpen}
        rexallSection={rexallSection}
        amenityIndex={amenityIndex}
        udpateAmenityIndex={(index) => {
          syncMultipleStatesWithSocket({
            amenityIndex: index === amenityIndex ? null : index,
          });
        }}
        onDestroy={() => {
          syncStates({
            orbitLoaded: false,
          });
        }}
        onOrbitIndexChange={(index) => {
          syncStates({
            orbitIndex: index,
          });
        }}
        onOrbitRunningChange={(running) => {
          syncStates({
            orbitRunning: running,
          });
        }}
      />
      <div className="svgOverlays">
        <ResidencesOrbit
          orbitIndex={orbitIndex}
          selectedUnit={selectedUnit}
          orbitDir={orbitDir}
          orbitRunning={orbitRunning}
          unitCardOpen={unitCardOpen}
          amenityRenderOpen={amenityRenderOpen}
          rexallSection={rexallSection}
          fullUnits={fullUnits}
          filteredUnits={filteredUnits}
          udpateAmenityIndex={(index) => {
            syncMultipleStatesWithSocket({
              amenityIndex: index === amenityIndex ? null : index,
            });
          }}
          onDestroy={() => {
            syncStates({
              orbitLoaded: false,
            });
          }}
          onOrbitIndexChange={(index) => {
            syncStates({
              orbitIndex: index,
            });
          }}
          onOrbitRunningChange={(running) => {
            syncStates({
              orbitRunning: running,
            });
          }}
          onUnitClick={(unit) => {
            syncMultipleStatesWithSocket({
              selectedUnit: unit || null,
            });
          }}
        />
      </div>
      <svg
        x="0"
        y="0"
        width="100%"
        height="100%"
        className="markers"
        preserveAspectRatio="xMidYMid slice"
      >
        <image
          href={`/assets/lightsFrames/base/${orbitIndex}.jpg`}
          x="0"
          y="0"
          width="100%"
          height="100%"
          className="mainImage"
          preserveAspectRatio="xMidYMid slice"
          style={{
            opacity: selectedUnit ? '1' : '1',
          }}
        />
        <image
          className="_0"
          href={`/assets/lightsFrames/base/0.jpg`}
          x="0"
          y="0"
          width="100%"
          height="100%"
          opacity={0}
          preserveAspectRatio="xMidYMid slice"
        />
        <image
          className="_1"
          href={`/assets/lightsFrames/base/1.jpg`}
          x="0"
          y="0"
          width="100%"
          height="100%"
          opacity={0}
          preserveAspectRatio="xMidYMid slice"
        />
        <image
          className="_2"
          href={`/assets/lightsFrames/base/2.jpg`}
          x="0"
          y="0"
          width="100%"
          height="100%"
          opacity={0}
          preserveAspectRatio="xMidYMid slice"
        />
        <image
          className="_3"
          href={`/assets/lightsFrames/base/3.jpg`}
          x="0"
          y="0"
          width="100%"
          height="100%"
          opacity={0}
          preserveAspectRatio="xMidYMid slice"
        />
      </svg>

      <AerialOrbit
        onDirectionSet={(v: boolean) => {
          syncStates({
            aerialDir: v,
          });
        }}
        onPlaySet={(v: boolean) => {
          syncStates({
            aerialRunning: v,
          });
        }}
        onInit={() => {
          syncStates({
            orbitLoaded: false,
          });
        }}
        onComplete={() => {
          syncStates({
            aerialUp: !aerialUp,
          });
        }}
        rexallSection={rexallSection}
        aerialDir={aerialDir}
        orbitIndex={orbitIndex}
        aerialRunning={aerialRunning}
        aerialUp={aerialUp}
      />
    </RexallWrapper>
  );
}

export default Rexall;

const RexallWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-size: auto 100%;
  background-position: center;

  .placeholder {
    width: 100%;
    height: 100%;
    object-fit: cover !important;
  }

  .aerial {
    width: 100%;
    height: 100%;
    object-fit: cover !important;
  }
`;

const SectionWrapper = styled.div`
  position: absolute;
  z-index: 1;
  height: 100%;
  max-height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`;
