import { create } from "zustand"

import {initialState, TInitialState} from "common/src/data/initialState"

type SetStoreState = {
  syncStates: (state: Partial<TInitialState>) => void
}


const useStore = create<TInitialState & SetStoreState>()((set, get) => ({
  ...initialState,
  syncStates: (state) => {
    set({ ...state })
  }
}))

export { useStore }