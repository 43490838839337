/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, forwardRef } from 'react';
import { useThree } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';

import { useSocketIO } from 'common/src/providers/sockets/SocketIOProvider';

type TProps = { [x: string]: unknown };
type TSocketsOrbitControlsProps = {
  controlProps?: TProps;
  role: 'emitter' | 'receiver';
  room: string | null;   
  id: string | undefined;
};

const SocketsOrbitControls = forwardRef(function SocketsOrbitControls(
  props: TSocketsOrbitControlsProps,
  ref,
) {
  const { controlProps, role, room, id } = props;
  const {
    camera,
    gl: { domElement },
  } = useThree();
  const { emitSocketEvent, onSocketEvent } = useSocketIO();

  useEffect(() => {
    if (onSocketEvent && role === 'receiver') {
      onSocketEvent('got-cameraPos', (data) => {
        if (id !== data.id) {
          const { x, y, z } = data.data;
          camera.position.set(x, y, z);
        }
      });
    }
  }, []);

  return (
    <OrbitControls
      {...controlProps}
      args={[camera, domElement]}
      //@ts-expect-error |||
      ref={ref}
      onChange={() => {
        //@ts-expect-error |||
        if (role === 'emitter' && camera && ref.current) {
          const data = {
            x: camera.position.x,
            y: camera.position.y,
            z: camera.position.z,
          };
          if (emitSocketEvent) {
            emitSocketEvent('cameraPos', {
              data,
              room,
              id,
            });
          }
        }
      }}
    />
  );
});

export default SocketsOrbitControls;
