import styled from 'styled-components';

import MenuIcon from 'common/src/components/SVGIcons/MenuIcon';

import globalStyles from 'common/src/data/globalStyles';
interface IMenuButtonProps {
  onMenuClicked: () => void
}
function MenuButton({onMenuClicked}: IMenuButtonProps) {
  return (
    <MenuButtonWrapper
      onClick={() => {
        onMenuClicked()
      }}
      >
      <MenuIcon
        bgColor={globalStyles.colors.second}
        strokeColor={globalStyles.colors.white}
        strokeWidth={1}
      />
    </MenuButtonWrapper>
  );
}

export default MenuButton;

const MenuButtonWrapper = styled.div`
  height: clamp(30px, 4rem, 50px);
  width: clamp(30px, 4rem, 50px);
  position: absolute;
  top: ${globalStyles.margin};
  right: ${globalStyles.margin};
  z-index: 10;
  pointer-events: all;
  cursor: pointer;
`;
