import { useRef, useEffect } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';

import List from 'common/src/web_components/List';
import ResidencesController from 'common/src/web_components/ResidencesController';
import Filter from 'common/src/web_components/FilterComponent';
import UnitDetails from 'common/src/components/UnitDetails';

import { useStore } from '@state/store';
import { toggleArrayItem } from '@utils/functions';
import { useSyncStateWithSocket } from '@hooks/socket/useSyncStateWithSocket';
import { useNavigate } from 'react-router-dom';
import { useSocketIO } from 'common/src/providers/sockets/SocketIOProvider';

function Residences() {
  const {
    listOpen,
    unitCardOpen,
    orbitLoaded,
    selectedUnit,
    favorites,
    fullUnits,
    filterOpen,
    beds,
    baths,
    levelRange,
    areaRange,
    minLevel,
    maxLevel,
    minArea,
    maxArea,
    faces,
    orbitIndex,
    filteredUnits,
    syncStates,
  } = useStore((s) => ({
    listOpen: s.listOpen,
    unitCardOpen: s.unitCardOpen,
    orbitLoaded: s.orbitLoaded,
    selectedUnit: s.selectedUnit,
    favorites: s.favorites,
    fullUnits: s.fullUnits,
    filterOpen: s.filterOpen,
    beds: s.beds,
    baths: s.baths,
    levelRange: s.levelRange,
    minLevel: s.minLevel,
    maxLevel: s.maxLevel,
    minArea: s.minArea,
    maxArea: s.maxArea,
    areaRange: s.areaRange,
    faces: s.faces,
    orbitIndex: s.orbitIndex,
    filteredUnits: s.filteredUnits,
    syncStates: s.syncStates,
  }));
  const { syncMultipleStatesWithSocket } = useSyncStateWithSocket();
  const { emitSocketEvent, room, socket} = useSocketIO();
  const residencesRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate()

  useEffect(() => {
    if (residencesRef.current) {
      gsap.to(residencesRef.current, {
        opacity: unitCardOpen ? 0 : 1,
        duration: 0.3,

        onStart: () => {
          !unitCardOpen &&
            residencesRef?.current &&
            (residencesRef.current.style.display = 'initial');
        },
        onComplete: () => {
          unitCardOpen &&
            residencesRef?.current &&
            (residencesRef.current.style.display = 'none');
        },
      });
    }
  }, [unitCardOpen]);

  useEffect(() => {
    if (selectedUnit !== null && filterOpen) {
      syncStates({
        filterOpen: false
      })
    }
  }, [selectedUnit]);

  useEffect(() => {
    if (filterOpen) {
      syncStates({
        selectedUnit: null
      })
    }
  }, [filterOpen]);

  useEffect(() => {
    if (residencesRef.current) {
      gsap.to(residencesRef.current, {
        opacity: orbitLoaded ? 1 : 1,
      });
    }
  }, [orbitLoaded]);

  useEffect(() => {
    return () => {
      syncMultipleStatesWithSocket({
        unitCardOpen: false,
        selectedUnit: null,
      });
    };
  }, []);

  return (
    <ResidencesContainer ref={residencesRef}>
      <ResidencesController
        onFilterClick={() => {
          syncStates({
            filterOpen: !filterOpen,
            listOpen: false,
            selectedUnit: null,
            // TODO - remove line above, it was not in web ?
          });
        }}
        onListClick={() => {
          syncStates({
            listOpen: !listOpen,
            filterOpen: false,
          });
        }}
        onHeartClick={() => {
          emitSocketEvent('navigation', {
            path: '/favorites',
            id: socket?.id,
            room,
          });
          navigate("/favorites")
        }}
      />
      <List
        filteredUnits={filteredUnits}
        selectedUnit={selectedUnit}
        listOpen={listOpen}
        favorites={favorites}
        onClose={() => {
          syncStates({
            listOpen: false,
          });
        }}
        onDestroy={() => {
          syncStates({
            listOpen: false,
          });
        }}
        toggleUnit={(unit) => {
          syncMultipleStatesWithSocket({
            selectedUnit: unit,
          });
        }}
        toggleFavorites={(toggled) => {
          syncMultipleStatesWithSocket({
            favorites: toggled,
          });
        }}
        className="left"
      />
      <Filter
        fullUnits={fullUnits}
        filterOpen={filterOpen}
        beds={beds}
        baths={baths}
        levelRange={levelRange}
        areaRange={areaRange}
        faces={faces}
        orbitIndex={orbitIndex}
        onCloseClicked={() => {
          syncStates({
            filterOpen: false,
          });
        }}
        onDestroy={() => {
          syncStates({
            filterOpen: false,
          });
        }}
        onFilteredChangedEffect={(filteredUnits) => {
          syncMultipleStatesWithSocket({
            filteredUnits,
          });
        }}
        onResetClicked={() => {
          syncStates({
            beds: [],
            baths: [],
            faces: [],
            levelRange: [minLevel, maxLevel],
            areaRange: [minArea, maxArea],
          });
        }}
        className="right"
      />
      <UnitDetails
        unitData={selectedUnit}
        favorites={favorites}
        listOpen={listOpen}
        onClick={() => {
          if (selectedUnit === null) return;
          syncMultipleStatesWithSocket({
            unitCardOpen: true,
          });
        }}
        favoriteClick={() => {
          if (selectedUnit === null) return;
          const toggled = toggleArrayItem(favorites, selectedUnit.unit);
          syncMultipleStatesWithSocket({
            favorites: toggled,
          });
        }}
        closeClick={() => {
          if (selectedUnit === null) return;
          syncMultipleStatesWithSocket({
            selectedUnit: null,
          });
        }}
        className="cardSizing"
      />
    </ResidencesContainer>
  );
}

export default Residences;

const ResidencesContainer = styled.div`
  width: 100%;
  height: 100%;
  opacity: 1;

  .cardSizing{
    width: clamp(325px, 20%, 450px);
    height: auto;
    transition:  0.3s ;
    transform: translateX(0%);
  }

`;
