import styled from 'styled-components';

import TourController from 'common/src/web_components/TourController';
import { useSyncStateWithSocket } from '@hooks/socket/useSyncStateWithSocket';
import MenuButton from 'common/src/web_components/MenuButton';
import { useStore } from '@state/store';

function Tour() {
  const { tourIndex, palette } = useStore((s) => ({
    tourIndex: s.tourIndex,
    palette: s.palette
  }));
  const { syncMultipleStatesWithSocket } = useSyncStateWithSocket();
  return (
    <TourWrapper>
      <MenuButton onMenuClicked={()=>{
        syncMultipleStatesWithSocket({
          menuOpen: true,
          menuIndex: null
        });
      }} />
      <TourController 
        tourIndex={tourIndex}
        palette={palette}
        onPaletteChanged={(tourIndex) => {
          syncMultipleStatesWithSocket({
            tourIndex
          });
        }}
        onPaletteClicked={(paletteIndex) => {
          syncMultipleStatesWithSocket({
            palette: paletteIndex
          });
        }}
        onTourIndexClicked={(tourIndex) => {
          syncMultipleStatesWithSocket({
            tourIndex
          });
        }}
      />
    </TourWrapper>
  );
}

export default Tour;

const TourWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
