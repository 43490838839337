import Content from './Content';
import ControlsCatcher from 'common/src/web_components/ControlsCatcher';
import MainCanvas from 'common/src/web_components/MainCanvas';
import Menu from '@pages/Menu';
import { useStore } from '@state/store';
import { useSocketIO } from 'common/src/providers/sockets/SocketIOProvider';
import { amenitiesData } from 'common/src/data/amenitiesData';
import { useSyncStateWithSocket } from '@hooks/socket/useSyncStateWithSocket';

function DefaultLayout() {
  const {
    menuOpen,
    controlsCatcher,
    rexallSection,
    amenityIndex,
    amenityRenderOpen,
    unitCardOpen,
    unitCardSection,
    palette,
    tourIndex,
    selectedUnit,
    syncStates,
  } = useStore((s) => ({
    menuOpen: s.menuOpen,
    controlsCatcher: s.controlsCatcher,
    rexallSection: s.rexallSection,
    amenityIndex: s.amenityIndex,
    amenityRenderOpen: s.amenityRenderOpen,
    unitCardOpen: s.unitCardOpen,
    palette: s.palette,
    unitCardSection: s.unitCardSection,
    tourIndex: s.tourIndex,
    selectedUnit: s.selectedUnit,
    syncStates: s.syncStates,
  }));
  const { room, socket } = useSocketIO();
  const { syncMultipleStatesWithSocket } = useSyncStateWithSocket();
  return (
    <>
      <Content />
      <MainCanvas
        selectedUnit={selectedUnit}
        controlsCatcher={controlsCatcher}
        rexallSection={rexallSection}
        amenityIndex={amenityIndex}
        amenityRenderOpen={amenityRenderOpen}
        unitCardOpen={unitCardOpen}
        unitCardSection={unitCardSection}
        palette={palette}
        tourIndex={tourIndex}
        setTourIndex={(x) => {
          syncMultipleStatesWithSocket({
            tourIndex: x,
          });
        }}
        room={room}
        socketId={socket?.id}
        background={
          selectedUnit
            ? '/assets/images/interiors/living-room.webp'
            : amenityIndex !== null
              ? amenitiesData[amenityIndex]?.src
              : null
        }
      />
      <ControlsCatcher
        onRefChange={(ref) => {
          syncStates({ controlsCatcher: ref });
        }}
        onDestroy={() => {
          syncStates({ controlsCatcher: null });
        }}
      />
       <Menu />
    </>
  );
}

export default DefaultLayout;
