import { useState, useEffect } from 'react';
import { DefaultLoadingManager } from 'three';

const HandleLoadingProgress = ({ totalItems, logassets, onloaded }) => {
  const [mounted, setMounted] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleLoading = (progress) => {
    setProgress(progress);
  };

  DefaultLoadingManager.onError = function (url) {
    console.log('There was an error loading ' + url);
  };

  DefaultLoadingManager.onProgress = function (url, itemsLoaded, totalAssets) {
    logassets && console.log('object', itemsLoaded, totalAssets);
    mounted && handleLoading((itemsLoaded / totalItems) * 100);
  };

  useEffect(() => {
    setMounted(true);

    return () => {
      setMounted(false);
    };
  }, []);

  useEffect(() => {
    if (progress === 100) {
      onloaded && onloaded();
    }
  }, [progress]);

  return null;
};

export default HandleLoadingProgress;
