import TSVGPropTypes from '../SVGPropTypes';

function PlanIcon({
  strokeWidth,
  strokeColor = 'white',
  bgColor = 'black',
  ...props
}: TSVGPropTypes) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 61 61"
      width="100%"
      height="100%"
      {...props}
    >
      <path
        d="M0.5 30C0.5 13.7076 13.7076 0.5 30 0.5C46.2924 0.5 59.5 13.7076 59.5 30C59.5 46.2924 46.2924 59.5 30 59.5C13.7076 59.5 0.5 46.2924 0.5 30Z"
        fill={bgColor}
				/>
      <path
        d="M21 22V39H39V32.3214"
        fill="none"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
				/>
      <path
        d="M25 21H39V26"
        fill="none"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
				/>
      <path
        d="M21 31H32V26"
        fill="none"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
				/>
      <path
        d="M30 35V39"
        fill="none"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PlanIcon;
