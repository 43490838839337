import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { navigations } from 'common/src/data/navigationData';
import gsap from 'gsap';
import globalStyles from 'common/src/data/globalStyles';

interface INavigationProps {
  navDurationSec: number;
  handleNavigation: (toIndex: number) => void;
  onNavigationComplete: (toIndex: number) => void;
}

const Navigation = ({navDurationSec, handleNavigation, onNavigationComplete}: INavigationProps) => {
  const navigate = useNavigate();
  const handleNav = async (index: number) => {
    handleNavigation(index)

    gsap.to("#nav-group",{
      duration: navDurationSec,
      opacity: (i) => i === index ? 1 : 0.5,
      scale: (i) => i === index ? 1.1 : .9,
      onComplete: () => {
          onNavigationComplete(index);
          navigate(navigations[index].path);
       }
    })
  };

  const timeline = gsap.timeline()

  useEffect(() => {
    timeline.to("#nav-group",{
      opacity: 1,
      duration: 1.5,
      stagger: 0.15,
      ease: "power1.out"
    },0)
    timeline.to("#nav-group",{
      duration: 0.8,
      stagger: 0.15,
      ease: "power4.inOut"
    }, "<+=.5")
  }, [])

  return (
    <ButtonWrapper>
      {navigations.map((nav, i) => (
        <NavWrapper key={i} onClick={() => handleNav(i)} id="nav-group">
          <div style={{
            display: "flex",
            justifyContent: "center"
          }}>
            <img style={{objectFit: "cover", width: "90%", height: "90%", margin: "auto", aspectRatio: "1/1"}} src={nav.imgSrc} />
          </div>
          <NavButton> {nav.name} </NavButton>
        </NavWrapper>
      ))}
    </ButtonWrapper>
  );
};
const ButtonWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  top: 35%;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  gap: 2rem;
  padding: 2rem 2rem;
  pointer-events: all;
  cursor: pointer;
`;

const NavWrapper = styled.div`
  opacity: 0;
  width: 15%;
`;
const NavButton = styled.div`
  text-align: center;
  color: ${globalStyles.colors.second};
  font-family: main;
  font-size: 13.382px;
  font-weight: 500;
  line-height: normal;
  margin: 2rem;
`;

export default Navigation;
