export type TUnit = {
  unit: string;
  level: number;
  type: string;
  extArea: string;
  intArea: string;
  area: number;
  bedrooms: string;
  beds: number;
  bathrooms: string;
  baths: number;
  aspect: ('N' | 'E' | 'S' | 'W')[];
  price: string;
  availability: string;
  status: boolean;
  floorplanCode: string;
};

export type TSheetUnit = {
  'Unit number': string;
  Level: string;
  'Unit type': string;
  'Ext Sqft': string;
  'Int sqft': string;
  Bedrooms: string;
  Bathrooms: string;
  Orientation: string;
  'Asking Price': string;
};

export function formatData(obj: TSheetUnit): TUnit {
  // const trimmed = arr.map((obj) => {
  return {
    unit: String(obj['Unit number']),
    level: Number(obj['Level']),
    type: String(obj['Unit type']),
    extArea: String(obj['Ext Sqft']),
    intArea: String(obj['Int sqft']),
    floorplanCode: String(obj['Unit type']).split('-')[0],
    area: Number(obj['Int sqft']),
    bedrooms: String(obj['Bedrooms']),
    beds: Number(String(obj['Bedrooms']).charAt(0)),
    bathrooms: String(obj['Bathrooms']),
    baths: Number(obj['Bathrooms']),
    aspect: String(obj['Orientation']).split('') as ('N' | 'E' | 'S' | 'W')[],
    price: String(obj['Asking Price']),
    availability: String(obj['Availability']),
    status: obj['Availability'] === 'Available' ? true : false,
  };
  // });

  // return trimmed;
}
