import styled from 'styled-components';
import { QRCodeSVG } from 'qrcode.react';

import globalStyles from '../../data/globalStyles';

type TShareQRProps = {
  url: string;
};

const ShareQR = ({url}: TShareQRProps) => {
  return (
    <QRWrapper>
      <QR
        value={url}
        size={350}
        bgColor={`${globalStyles.colors.black}CC`}
        fgColor={globalStyles.colors.second}
        level="Q"
        includeMargin
        id="qr"
      />
    </QRWrapper>
  );
}

export default ShareQR;

const QRWrapper = styled.div`
  width: 100%;
  height: 40%;
  border-radius: 3rem;
  aspect-ratio: 1/1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2%;
`;

const QR = styled(QRCodeSVG)`
  width: fit-content;
  aspect-ratio: 1/1;
`;
