// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';

import {
  getRemoteConfig,
  fetchAndActivate,
  getValue,
} from 'firebase/remote-config';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// https://console.firebase.google.com/project/luna-auth-eed78/config/env/firebase
// 192.168.0.39

// Your web app's Firebase configuration
// TODO - move to .env file
const firebaseConfig = {
  apiKey: import.meta.env.VITE_FB_API_KEY,
  authDomain: import.meta.env.VITE_FB_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FB_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FB_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FB_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FB_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 1 * 60 * 1000;

export async function fetchRemoteConfig(config_key: string) {
  try {
    await fetchAndActivate(remoteConfig);
    // Configurations are ready to use
    const value = getValue(remoteConfig, config_key);
    console.log('VALUE:', value.asString());
    return value.asString();
  } catch (err) {
    console.error('Failed to fetch or activate config:', err);
    return import.meta.env.VITE_SOCKET_IP;
  }
}
