/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { sortBy } from 'lodash-es';

import Heart from '../SVGIcons/Heart';

// import { toggleArrayItem } from '../../utils/functions';
import { TUnit } from '../../lib/formatData';

type TTableProps = {
  units: TUnit[];
  activeApartment?: unknown;
  rowOnClick?: (unit: TUnit) => void;
  activeClassname?: string | undefined;
  favorites: (string | number)[];
  favoriteClick?: (v: any) => void;
  [x: string]: unknown;
};

function TableComponent(props: TTableProps) {
  const { units, activeApartment, rowOnClick, activeClassname, favorites, favoriteClick, ...rest } = props;
  const [orderState, setOrderState] = useState('APT');
  const [orderDir, setOrderDir] = useState(true);

  const filteredUnits = units;
  const currentApt = activeApartment;

  // const favourites = useStore((s) => s.favorites);
  // const setFavorites = useStore((s) => s.setFavorites);

  const isFave = (apartment) => favorites ? favorites.some((e) => e === apartment['unit']) : false
  const isActive = (apartment) => apartment === currentApt;

  function trim(arr) {
    // return arr.map((obj, i) => {
    return arr.map((obj) => {
      return [
        obj['unit'],
        obj['level'],
        obj['bedrooms'],
        obj['bathrooms'],
        obj['intArea'],
        obj['extArea'],
        <Heart
          strokeColor={"black"}
          strokeWidth={1}
          bgColor={isFave(obj)
            ? "black"
            : "transparent"}
          className="favIcon"
          onClick={(e) => {
            e.stopPropagation()
            favoriteClick && favoriteClick(obj)
          }}
        />,
        obj,
      ];
    });
  }

  const orderByApartment = sortBy(filteredUnits, [
    function (o) {
      return parseInt(o['unit']);
    },
    function (o) {
      return parseInt(o['bedrooms']);
    },
    function (o) {
      return parseInt(o['level']);
    },
  ]);

  const orderByFloor = sortBy(filteredUnits, [
    function (o) {
      return parseInt(o['level']);
    },
    function (o) {
      return parseInt(o['bedrooms']);
    },
  ]);

  const orderByBeds = sortBy(filteredUnits, [
    function (o) {
      return parseInt(o['bedrooms']);
    },
    function (o) {
      return parseInt(o['level']);
    },
  ]);

  const orderByBaths = sortBy(filteredUnits, [
    function (o) {
      return parseInt(o['bathrooms']);
    },
    function (o) {
      return parseInt(o['level']);
    },
  ]);

  const orderByIntArea = sortBy(filteredUnits, [
    function (o) {
      return parseInt(o['intArea']);
    },
    function (o) {
      return parseInt(o['extArea']);
    },
  ]);

  const orderByExtArea = sortBy(filteredUnits, [
    function (o) {
      return parseInt(o['extArea']);
    },
    function (o) {
      return parseInt(o['intArea']);
    },
  ]);

  const orderTypes = {
    APT: orderDir ? trim(orderByApartment) : trim(orderByApartment).reverse(),
    FLOOR: orderDir ? trim(orderByFloor) : trim(orderByFloor).reverse(),
    BEDS: orderDir ? trim(orderByBeds) : trim(orderByBeds).reverse(),
    BATHS: orderDir ? trim(orderByBaths) : trim(orderByBaths).reverse(),
    INT: orderDir ? trim(orderByIntArea) : trim(orderByIntArea).reverse(),
    EXT: orderDir ? trim(orderByExtArea) : trim(orderByExtArea).reverse(),
  };

  const arrow = orderDir ? `\u2191` : `\u2193`;

  const headers = [
    {
      label: (
        <p>
          UNIT <span>{arrow}</span>
        </p>
      ),
      state: 'APT',
    },
    {
      label: (
        <p>
          FLOOR <span>{arrow}</span>
        </p>
      ),
      state: 'FLOOR',
    },
    {
      label: (
        <p>
          BEDS <span>{arrow}</span>
        </p>
      ),
      state: 'BEDS',
    },
    {
      label: (
        <p>
          BATHS <span>{arrow}</span>
        </p>
      ),
      state: 'BATHS',
    },
    {
      label: (
        <p>
          INT AREA <span>{arrow}</span>
        </p>
      ),
      state: 'INT',
    },
    {
      label: (
        <p>
          EXT AREA <span>{arrow}</span>
        </p>
      ),
      state: 'EXT',
    },
    {
      label: (
        <p>
          FAVORITES
        </p>
      ),
      state: null,
    }
  ];

  useEffect(() => {
    const row = document.getElementsByClassName('active')[0];
    if (row) {
      row.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [currentApt]);

  return (
    <TableWrapper {...rest}>
      <TableContainer>
        <Table>
          <TableHeader>
            <tr>
              {headers.map((obj, i) => {
                return (
                  <th
                    key={`header${i}`}
                    className={orderState === obj.state ? 'filteredBy' : ''}
                    onClick={() => {
                      if(!obj.state) return
                      if (orderState === obj.state) {
                        obj.state && setOrderDir(!orderDir);
                      } else {
                        obj.state && setOrderState(obj.state);
                      }
                    }}
                  >
                    {obj.label}
                  </th>
                );
              })}
            </tr>
          </TableHeader>
          <TableBody>
            {orderTypes[orderState].map((apt, i) => {
              return (
                <tr
                  key={i}
                  onClick={() => {
                    if (rowOnClick) {
                      rowOnClick(apt[apt.length - 1]);
                    }
                  }}
                  className={isActive(apt[apt.length - 1]) ? activeClassname : ''}
                >
                  {headers.map((txt, i) => {
                    return <td key={`column${i}`}>{apt[i]}</td>;
                  })}
                </tr>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </TableWrapper>
  );
}

export default TableComponent;

const TableWrapper = styled.div`
  position: absolute;
  max-height: 100%;
  width: 100%;
  height: 100%;
  animation: fadeIn 0.6s ease-in-out;

  .favIcon{
    width: 15%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const TableContainer = styled.div`
  overflow-y: scroll;
  touch-action: pan-y;
  height: 100%;
  width: 100%;

  & * {
    touch-action: pan-y;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;

const Table = styled.table`
  position: relative;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  transition: opacity 0.5s ease-out;

  .filteredBy {
    & span {
      opacity: 1;
    }
  }
`;

const TableHeader = styled.thead`
  top: 0%;
  position: sticky;
  z-index: 2;

  & th {
    font-weight: 100;
    cursor: pointer;
    pointer-events: all;
    position: relative;
    transform: translateY(-2%);

    & span {
      opacity: 0.3;
      transition: opacity 0.5s ease-in;
    }

    & div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

const TableBody = styled.tbody`
  text-align: center;
  font-weight: 100;
  position: relative;

  tr {
    & td {
      position: relative;
      pointer-events: all;
      cursor: pointer;

      & div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
`;
