export const navigations = [
  {
    name: "HOME",
    path: "/",
    imgSrc: "/assets/images/nav/origin.webp"
  },
  {
    name: "ORIGIN",
    path: "/rexall",
    imgSrc: "/assets/images/nav/residences.webp"
  },
  {
    name: "MAP",
    path: "/maps",
    imgSrc: "/assets/images/nav/maps.webp"
  },
  {
    name: "SPECIFICATIONS",
    path: "/specs",
    imgSrc: "/assets/images/nav/detail.webp"
  },
  {
    name: "360 TOUR",
    path: "/tour",
    imgSrc: "/assets/images/nav/tour.webp"
  },
  {
    name: "GALLERY",
    path: "/gallery",
    imgSrc: "/assets/images/nav/gallery.webp"
  }
]