import { useEffect } from 'react';

function AerialController({onInit}: {onInit: () => void}){

  useEffect(() => {
    setTimeout(() => {
      onInit()
    }, 1000)
  }, [])
  return null
}

export default AerialController;
