import styled from "styled-components"
import logo from '../assets/circle_logo.svg'

const Logo = ({opacity}: {opacity?: string}) => {
  return <OriginLogo $opacity={opacity} src={logo} className="logo" alt="Origin Logo" />
}

const OriginLogo = styled.img<{ $opacity?: string; }>`
  width: 100%;
  height: 100%;
  opacity: ${props => props.$opacity || '0.5'};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: -1;
`

export default Logo