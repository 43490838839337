precision mediump float;

uniform sampler2D uCurrentTexture;
uniform sampler2D uNextTexture;
uniform float uOpacity;
uniform float uBlend;

varying vec2 vUv;

void main(){
   vec4 currentColor = texture2D(uCurrentTexture, vUv);
   vec4 nextColor = texture2D(uNextTexture, vUv);
   vec4 finalColor = mix(currentColor, nextColor, uBlend);
   finalColor.a *= clamp(uOpacity, 0.0, 1.0);
   gl_FragColor = finalColor;
}