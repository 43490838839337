import { useEffect, useState, Fragment } from 'react';
import styled from 'styled-components';

import ListButtonIcon from '../../components/SVGIcons/ListButtonIcon';

import { amenitiesData } from '../../data/amenitiesData';
import globalStyles from '../../data/globalStyles';

type AmenitiesControllerProps = {
  amenityIndex: number;
  amenityRenderOpen: boolean;
  onInit: () => void;
  onLabelClick: (index: number) => void;
  onExplore: (amenityIndex: number) => void;
  onListOpenChange?: (open: boolean) => void;
};

function AmenitiesController({amenityIndex, amenityRenderOpen, onInit, onLabelClick, onExplore, onListOpenChange}: AmenitiesControllerProps) {
  const [listOpen, setListOpen] = useState(amenityIndex !== null);

  useEffect(() => {
    if (amenityIndex !== null) {
      setListOpen(true);
    }

  }, [amenityIndex]);

  useEffect(() => {
    return () => {
      onInit()
      setListOpen(false)
    };
  }, []);

  return (
    <Fragment>
      <Buttons
        style={{
          opacity: amenityRenderOpen ? 0 : 1,
        }}
      >
        <ListButtonIcon
          strokeColor={globalStyles.colors.white}
          bgColor={globalStyles.colors.black}
          strokeWidth={1}
          className={'amenityIcon'}
          onClick={() => {
            setListOpen(!listOpen);
            onListOpenChange && onListOpenChange(!listOpen);
          }}
        />
      </Buttons>
      <List
        style={{
          zIndex: 1000,
          opacity: listOpen ? 1 : 1,
          pointerEvents: listOpen ? 'all' : 'all',
        }}
      >
        <h1>AMENITIES</h1>
        {amenitiesData.map((ctg, i) => {
          return (
            <AmenityBtnGroup key={`amenityBtn${i}`}>
              <button
                onClick={() => {
                  onLabelClick(ctg.index)
                }}
                style={{
                  borderColor: amenityIndex === ctg.index ? 'black' : 'white',
                  transform: `translateX(${amenityIndex === ctg.index ? '10%' : '0%'})`,
                  cursor: amenityIndex !== ctg.index ? 'pointer' : 'default',
                }}
              >
                {ctg.label}
              </button>
              <p
                style={{
                  opacity: amenityIndex === ctg.index ? 1 : 0,
                  transform: `translate(${amenityIndex === ctg.index ? '35%' : '60%'}, -50%)`,
                }}
                onClick={() => {
                  if (amenityIndex === null) return;
                  onExplore(amenityIndex)
                  setListOpen(false)
                }}
              >
                EXPLORE
              </p>
            </AmenityBtnGroup>
          );
        })}
      </List>
    </Fragment>
  );
}

export default AmenitiesController;

const Buttons = styled.div`
  position: absolute;
  width: 10%;
  height: clamp(30px, 4rem, 50px);
  top: ${globalStyles.margin};
  left: ${globalStyles.margin};
  z-index: 1;
  display: flex;
  align-items: flex-start;
  gap: ${globalStyles.margin};

  .amenityIcon {
    pointer-events: all;
    cursor: pointer;
    height: 100%;
    width: auto;
  }
`;

const List = styled.div`
  position: absolute;
  width: clamp(300px, 10%, 500px);
  height: fit-content;
  left: ${globalStyles.margin};
  bottom: 10%;
  padding: ${globalStyles.margin};
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: ${globalStyles.borderRadius};
  transition: opacity 0.5s ease-in-out;

  & h1 {
    transform: translateX(1rem);
  }

  & button {
    min-width: 8rem;
    width: fit-content;
    font-family: main; 
    text-transform: capitalize;
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
    border: 2px solid black;
    border-radius: 100px;
    transition: all 0.3s ease-in-out;
    text-align: left;
    background-color: ${globalStyles.colors.white};
    color: ${globalStyles.colors.black};
    cursor: pointer;
  }
  
  & p {
    border-radius: 100px;
    padding: 0.8rem;
    cursor: pointer;
    background-color: ${globalStyles.colors.black};
    border: 2px solid ${globalStyles.colors.white};
    color: ${globalStyles.colors.white};
  }
`;

const AmenityBtnGroup = styled.div`
  position: relative;
  width: 100%;
  height: auto;

  & p {
    position: absolute;
    top: 50%;
    right: 10%;
    font-family: subTitle;
    font-size: 1rem;
    transition: all 0.5s ease-in-out;
  }
`;
