import { useEffect } from 'react'
import styled from 'styled-components'
import gsap from 'gsap'

import globalStyles from '../../data/globalStyles'

function FinishesComposition({finishState, ...props}) {  
  const paths = {
    full: [
      "/assets/images/specs/compositions/light.webp",
      "/assets/images/specs/compositions/medium.webp",
      "/assets/images/specs/compositions/dark.webp",
    ],
    thumbs: [
      "/assets/images/specs/compositions/thumb/light.webp",
      "/assets/images/specs/compositions/thumb/medium.webp",
      "/assets/images/specs/compositions/thumb/dark.webp",
    ],
  }

  useEffect(() => {
    const images = gsap.utils.toArray<HTMLImageElement>(".compositionRender")
    gsap.to(".compositionRender", {
      opacity: (i) => i === finishState ? 1 : 0,
      onStart: () => {
        images.forEach((img, i) => {
          img.src = paths.thumbs[i]
        })
      }, 
      onComplete: () => {
        images.forEach((img, i) => {
          if(i === finishState){
            img.src = paths.full[i]
          }
        })
      }, 
    })
  }, [finishState])
  return (
    <CompositionWrapper {...props}>
      {
        paths.thumbs.map((path, i) => {
           return (
            <img key={`compositionRender${i}`} src={path} className='compositionRender'/>
           )
        })
      }
    </CompositionWrapper>
  )
}

export default FinishesComposition

const CompositionWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;

  .compositionRender {
    position: absolute;
    width: auto;
    height: 100%;
    opacity: 0;
    border-radius: ${globalStyles.borderRadius};
    background-size: contain;
  }
`