type TLogoProps = {
  bgColor?: string;
  textColor?: string;
  [x: string]: unknown;
};

function SVGOriginLogo({ bgColor, textColor, ...props }: TLogoProps) {
  return (
    <svg
      x="0"
      y="0"
      width="100%"
      height="100%"
      viewBox="0 0 1000 1000"
      {...props}
    >
      <path
        d="m500,989.67c270.44,0,489.67-219.23,489.67-489.67S770.44,10.33,500,10.33,10.33,229.56,10.33,500s219.23,489.67,489.67,489.67"
        fill={bgColor ?? '#9f5a42'}
      />
      <g>
        <path
          d="m172.02,594.54c-51.66-.15-95.42-40.69-95.52-95.58-.09-54.07,43.88-95.51,95.64-95.55,52.88-.06,95.78,43.01,95.58,95.8-.19,53.11-42.67,95.41-95.7,95.33m76.1-95.3c-.03-40.82-32.6-76.05-75.83-76.18-41.68-.13-75.71,33.28-76.07,75.46-.34,42.61,34.33,75.9,75.45,76.29,39.68.37,76.1-31.76,76.45-75.56"
          fill={textColor ?? '#FFFFFF'}
        />
        <path
          d="m670.06,415.9c-3.49,5.54-6.91,10.97-10.19,16.18-5.74-2.13-11.19-4.57-16.88-6.18-17.46-4.95-34.61-3.52-51.1,3.93-20.49,9.26-34.03,24.96-40.91,46.23-5,15.47-4.81,31.12.25,46.56,7.05,21.59,21.26,37.02,42.03,46.04,16.72,7.27,34.05,7.98,51.67,3.19,12.69-3.46,24.07-9.28,34.02-17.83,5.62-4.83,10.29-10.47,12.32-17.82.52-1.85.72-3.86.69-5.8-.07-4.32-2.44-7.01-6.73-7.85-1.46-.28-2.99-.41-4.49-.41-11.12-.03-22.22-.01-33.34-.01h-2.63v-19.46h74.8c-.46,14.43-4.04,27.99-10.54,40.79-9.95,19.62-25.09,33.96-45.21,42.67-42.93,18.57-90.87,5.85-117.92-31.48-27.82-38.38-22.78-89.8,9.58-122.59,31.83-32.26,79.5-36.75,114.58-16.16"
          fill={textColor ?? '#FFFFFF'}
        />
        <path
          d="m374.73,505.48c17.68,29.45,35.22,58.66,52.99,88.25-6.64,0-12.83-.31-18.97.1-3.98.27-5.91-1.21-7.85-4.54-14.18-24.34-28.59-48.51-42.88-72.77-3.24-5.5-7.6-8.93-14.27-8.62-8.71.4-14.46,6.7-14.46,15.92-.03,22.21-.01,44.4-.01,66.59v3.12h-19.32v-188.65c.75-.06,1.48-.15,2.2-.15h43.3c10.25,0,19.93,2.11,28.77,7.54,9.68,5.94,16.16,14.49,19.72,25.12,5.72,17.06,3.96,33.4-5.96,48.52-5.48,8.35-12.83,14.8-22.06,18.94-.32.15-.65.34-1.21.62m-45.45-48.55c0,6.56.25,13.14-.07,19.69-.31,6.56,4.45,11.15,9.55,11.47,6.59.43,13.26.44,19.87.16,5.2-.22,10.07-2.23,14.36-5.13,10.81-7.29,15.75-17.7,14.15-30.52-1.61-12.97-9.18-21.67-21.51-26.14-8.12-2.93-16.56-2.41-24.9-1.94-7.51.41-11.4,4.97-11.44,12.46-.03,6.65,0,13.29,0,19.93"
          fill={textColor ?? '#FFFFFF'}
        />
        <path
          d="m904.1,404.84h19.5v190.96c-.56-.9-.96-1.48-1.32-2.1-19.28-34.54-38.54-69.09-57.84-103.63-.65-1.18-1.24-2.48-2.17-3.41-.64-.64-1.91-1.12-2.74-.95-.67.15-1.3,1.32-1.58,2.16-.3.9-.19,1.94-.19,2.91-.01,33.16-.01,66.31-.01,99.46v3.31h-19.25v-190.2c.12-.06.22-.13.34-.21.41.71.87,1.41,1.27,2.13,19.16,34.34,38.34,68.67,57.5,103.02.25.47.55.92.78,1.39.78,1.54,1.73,3.08,3.74,2.56,2.09-.52,1.95-2.44,1.95-4.1.02-31.55.02-63.12.02-94.68v-8.62Z"
          fill={textColor ?? '#FFFFFF'}
        />
        <rect
          x="464.87"
          y="404.86"
          width="19.07"
          height="188.68"
          fill={textColor ?? '#FFFFFF'}
        />
        <rect
          x="762.18"
          y="404.91"
          width="19.1"
          height="188.74"
          fill={textColor ?? '#FFFFFF'}
        />
      </g>
    </svg>
  );
}

export default SVGOriginLogo;
