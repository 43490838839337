import ContentLayout from "common/src/web_components/Layout/ContentLayout";
import { useSocketIO } from "common/src/providers/sockets/SocketIOProvider";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

function Trigger() {
  const { id } = useParams<any>();
  const { emitSocketEvent } = useSocketIO();
  useEffect(() => {
    emitSocketEvent('trigger', id)
  }, []);
  return (
    <ContentLayout>
      <h1>Trigger sent</h1>
    </ContentLayout>
  )
}

export default Trigger;