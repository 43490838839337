import styled from 'styled-components';

import FilterButtonIcon from '../../components/SVGIcons/FilterButtonIcon';
import ListButtonIcon from '../../components/SVGIcons/ListButtonIcon';
import HeartIcon from '../../components/SVGIcons/HeartIcon';

import globalStyles from '../../data/globalStyles';

export interface IResidencesControllerProps {
  onFilterClick: () => void;
  onListClick: () => void;
  onHeartClick: () => void;
}
function ResidencesController({ onFilterClick, onListClick, onHeartClick }: IResidencesControllerProps) {
  return (
    <Buttons>
      <FilterButtonIcon
        strokeColor={globalStyles.colors.white}
        bgColor={globalStyles.colors.black}
        strokeWidth={1}
        className={"residenceIcon"}
        onClick={() => {
          onFilterClick();
        }}
      />
      <ListButtonIcon
        strokeColor={globalStyles.colors.white}
        bgColor={globalStyles.colors.black}
        strokeWidth={1}
        className={"residenceIcon"}
        onClick={() => {
          onListClick();
        }}
      />
      <HeartIcon
        strokeColor={globalStyles.colors.white}
        bgColor={globalStyles.colors.black}
        heartColor={globalStyles.colors.black}
        strokeWidth={1}
        className={"residenceIcon"}
        onClick={() => {
          onHeartClick();
        }}
      />
    </Buttons>
  );
}

export default ResidencesController;

const Buttons = styled.div`
  position: absolute;
  width: 15%;
  height: clamp(30px, 4rem, 50px);
  top: ${globalStyles.margin};
  left: ${globalStyles.margin};
  z-index: 1;
  display: flex;
  align-items: flex-start;
  gap: ${globalStyles.margin};

  .residenceIcon {
    cursor: pointer;
    pointer-events: all;
    height: 100%;
    width: auto;
  }
`;