import styled from "styled-components"
import origin from '../assets/origin.svg'

const Origin = () => {
  return <OriginWrapper>
    <OriginImg src={origin} className="origin" alt="Origin font" />
  </OriginWrapper>
}

const OriginImg = styled.img`
  width: 90%;
  height: 90%;
  box-sizing: border-box;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
`

const OriginWrapper = styled.div`
  position: absolute;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default Origin