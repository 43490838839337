/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState, forwardRef } from 'react';
import styled from 'styled-components';

import VideoOrbit from '../../components/VideoOrbit';
// import MapSVGIconGroup from '../../components/MapSVGIconGroup';

import { residenceData } from '../../data/residencesData';
import globalStyles from '../../data/globalStyles';
import { TInitialState } from '../../data/initialState';
import { TUnit } from '../../lib/formatData';
import gsap from 'gsap';
// import backupData from '../../data/backupData';

interface IOrbitProps
  extends Pick<
    TInitialState,
    | 'orbitIndex'
    | 'orbitDir'
    | 'orbitRunning'
    | 'unitCardOpen'
    | 'amenityRenderOpen'
    | 'rexallSection'
    | 'fullUnits'
    | 'selectedUnit'
    | 'filteredUnits'
  > {
  onDestroy: () => void;
  onOrbitIndexChange: (index: number) => void;
  onOrbitRunningChange: (running: boolean) => void;
  udpateAmenityIndex?: (index: number) => void;
  onUnitClick: (unit: TUnit) => void;
}

const ResidencesOrbit = forwardRef(function Orbit(
  {
    orbitIndex,
    orbitDir,
    selectedUnit,
    orbitRunning,
    unitCardOpen,
    amenityRenderOpen,
    rexallSection,
    fullUnits,
    onDestroy,
    onOrbitIndexChange,
    onOrbitRunningChange,
    onUnitClick,
    filteredUnits,
  }: IOrbitProps,
  ref,
) {
  const [data, setData] = useState(residenceData.tablet[0]);

  useEffect(() => {
    return () => {
      onDestroy();
    };
  }, []);

  // const uniqueUnitTypes = new Set(backupData.map(item => item["Unit type"]));
  // console.log(filteredUnits)
  const filteredUnitIds = filteredUnits?.map((unit) => unit.unit);
  const filteredData = data.filter((dataItem) =>
    filteredUnitIds?.includes(dataItem.id),
  );

  const isTouch =
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    //@ts-expect-error n\a
    navigator.msMaxTouchPoints > 0;

  useEffect(() => {
    setData(residenceData.tablet[orbitIndex]);
  }, [orbitIndex]);

  useEffect(() => {
    // @ts-ignore
    gsap.to(`${data.id}`, {});
  }, [selectedUnit]);

  return (
    <OrbitWrapper
      className="centerABS"
      id="orbitWrapper"
      style={{
        opacity:
          rexallSection !== 'residences' || unitCardOpen || amenityRenderOpen
            ? 0
            : 1,
      }}
    >
      <VideoOrbit
        direction={orbitDir}
        fwdVideos={[
          '/assets/video/0.mp4',
          '/assets/video/1.mp4',
          '/assets/video/2.mp4',
          '/assets/video/3.mp4',
        ]}
        bwdVideos={[
          '/assets/video/3-reversed.mp4',
          '/assets/video/0-reversed.mp4',
          '/assets/video/1-reversed.mp4',
          '/assets/video/2-reversed.mp4',
        ]}
        wrapperProps={{
          className: 'videoWrapper centerABS show',
        }}
        videoProps={{
          playsInline: true,
        }}
        indexState={orbitIndex}
        indexSet={
          rexallSection === 'aerial view'
          ? undefined
            : (n) => {
                onOrbitIndexChange(n);
              }
        }
        playState={orbitRunning && rexallSection === 'residences'}
        playSet={(v) => {
          onOrbitRunningChange(v);
        }}
        lockWhilePLaying={true}
        delayMS={200}
      />
      <MarkersWrapper
        $istouch={isTouch}
        $unitCardOpen={unitCardOpen}
        className={orbitRunning ? 'hide' : 'show'}
      >
        <svg
          x="0"
          y="0"
          width="100%"
          height="100%"
          viewBox="0 0 2500 1000"
          className="markers"
          style={{
            pointerEvents: rexallSection === 'residences' && !unitCardOpen ? 'all' : 'none',
          }}
        >
          {filteredData.map((data, i) => {
            return (
              <polygon
                key={`shape${i}`}
                points={data.coords}
                {...data.attr}
                fill={'#12406a'}
                stroke={'white'}
                strokeWidth="2"
                id={`${data.id}`}
                className="svg-overlay"
                opacity={
                  !selectedUnit
                    ? 0.15
                    : selectedUnit?.unit === `${data.id}`
                      ? 0.6
                      : 0.15
                }
                onClick={() => {
                  const unit = fullUnits?.find((u) => {
                    return u.unit === `${data.id}`;
                  });
                  unit && onUnitClick(unit);
                }}
              />
            );
          })}
        </svg>
      </MarkersWrapper>
    </OrbitWrapper>
  );
});
export default ResidencesOrbit;

const OrbitWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transition: opacity 0.8s linear;
  top: 0%;

  .videoWrapper {
    transition: opacity;
    transition-duration: 200ms;
    & video {
      object-fit: cover;
    }
  }

  .show {
    opacity: 1;
  }

  .hide {
    opacity: 0;
  }
`;

const MarkersWrapper = styled.div<{
  $istouch: boolean;
  $unitCardOpen: boolean;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  margin: auto;
  pointer-events: none;
  top: 0;
  left: 0;
  transition: opacity 0.3s linear;

  svg {
    width: auto;
    height: 98%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: all;
    pointer-events: ${({ $unitCardOpen }) => ($unitCardOpen ? 'none' : 'all')};
    z-index: 0;
  }
  .svg-overlay {
    transition: opacity 0.6s;
  }

  ${(p) =>
    !p.$istouch &&
    `
    g.groupAmenity:hover {
    cursor: pointer;
    circle:first-child {
      stroke: ${globalStyles.colors.second};
    }
    .amenityLabel {
      stroke: ${globalStyles.colors.second};
      fill: ${globalStyles.colors.second};
      display: initial;
    }
  }`}
`;
