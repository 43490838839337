import { useEffect, useState } from 'react';
import styled from 'styled-components';

import Button from 'common/src/components/Button';

import globalStyles from 'common/src/data/globalStyles';
import { TInitialState } from '../../data/initialState';
import ListButtonIcon from '../../components/SVGIcons/ListButtonIcon';
import CloseIcon from '../../components/CloseIcon';
interface ITourControllerProps extends Pick<TInitialState, 'tourIndex' | 'palette'> {
  onPaletteChanged: (tourIndex: number) => void;
  onTourIndexClicked: (tourIndex: number) => void;
  onPaletteClicked: (paletteIndex: number) => void;
}

function TourController({ tourIndex, palette, onPaletteChanged, onTourIndexClicked, onPaletteClicked }: ITourControllerProps) {
  const labels = [
    'Main Bedroom',
    'Main Bedroom Two',
    'Second Bedroom *',
    'Second Bedroom Two *',
    'Hallway',
    'Living Room',
    'Living Room Two',
  ];

  const finishes = ['light', 'medium', 'dark'];
  const [listOpen, setListOpen] = useState<boolean>(true)

  useEffect(() => {
    let index;
    for (const key in indexMap) {
      if (indexMap[key].indexOf(tourIndex) !== -1) {
        index = indexMap[key].indexOf(tourIndex);
        onPaletteChanged(indexMap[palette][index])
      }
    }
  }, [palette]);
  return (
    <Buttons>
      <ListButtonWrapper>
        <ListButtonIcon
          strokeColor={globalStyles.colors.white}
          bgColor={globalStyles.colors.black}
          strokeWidth={1}
          className={'amenityIcon'}
          onClick={() => {
            setListOpen(!listOpen);
            // onListOpenChange && onListOpenChange(!listOpen);
          }}
        />
      </ListButtonWrapper>
      {listOpen && <>
        <List>
          <CloseIcon
            className="closeIcon"
            strokeColor={globalStyles.colors.black}
            bgColor={globalStyles.colors.white}
            strokeWidth={2}
            onClick={() => {
              setListOpen(false)
            }}
          />
          <h1>CAMERAS</h1>
          {labels.map((label, i) => {
            const index = indexMap[palette][i];
            return (
              <button
                key={`tourBtn${i}`}
                onClick={() => {
                  onTourIndexClicked(indexMap[palette][i])
                }}
                style={{
                  opacity: tourIndex === index ? 1 : 0.2,
                  transform: `translateX(${tourIndex === index ? '10%' : '0%'})`,
                }}
              >
                {label}
              </button>
            );
            })}
          <span> * On selected units only.</span>
        </List>
      </>}
      <Right>
        {finishes.map((label, i) => {
          return (
            <Button
              label={label}
              className="groupBtn"
              onClick={() => {
                onPaletteClicked(i)
              }}
              key={i}
              style={{
                color:
                  palette === i
                    ? globalStyles.colors.white
                    : globalStyles.colors.black,
                backgroundColor:
                  palette === i
                    ? globalStyles.colors.black
                    : globalStyles.colors.white,
              }}
            />
          );
        })}
      </Right>
    </Buttons>
  );
}

export default TourController;

const Buttons = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: ${globalStyles.margin};
  z-index: 1;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  transition: opacity 0.8s ease-in-out;

  .closeIcon {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: 4rem;
    height: 4rem;
    pointer-events: all;
    cursor: pointer;
    z-index: 5;
  }
`;

const ListButtonWrapper = styled.div`
    position: absolute;
    width: 10%;
    height: clamp(30px, 4rem, 50px);
    top: calc(${globalStyles.margin} + 1rem);
    left: ${globalStyles.margin};
    z-index: 1;
    display: flex;
    align-items: flex-start;
    gap: ${globalStyles.margin};

    .amenityIcon {
      pointer-events: all;
      height: 100%;
      width: auto;
      cursor: pointer;
    }
`;

const Right = styled.div`
  position: absolute;
  width: 50%;
  right: ${globalStyles.margin};
  bottom: ${globalStyles.margin};
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: flex-end;
  gap: 2%;
`;

const List = styled.div`
  position: absolute;
  z-index: 2;
  left: ${globalStyles.margin};
  bottom: ${globalStyles.margin};
  padding: 2rem 4rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: ${globalStyles.borderRadius};
  transition: opacity 0.5s ease-in-out;

  & h1 {
    transform: translateX(1rem);
    margin-bottom: 1.5rem;
  }

  & button {
    min-width: 8rem;
    width: fit-content;
    font-family: main;
    text-transform: capitalize;
    font-size: 1.2rem;
    padding: 0.5rem;
    border: none;
    border-radius: 100px;
    transition: all 0.4s ease-in-out;
    text-align: left;
    background-color: ${globalStyles.colors.white};
    pointer-events: all;
    cursor: pointer;
    color: ${globalStyles.colors.black};
  }
`;

const indexMap = {
  0: [0, 1, 2, 3, 4, 5, 6],
  1: [7, 8, 9, 10, 11, 12, 13],
  2: [14, 15, 16, 17, 18, 19, 20],
};
