import styled from 'styled-components';

import Button from 'common/src/components/Button';

import { useStore } from '@state/store';
import globalStyles from 'common/src/data/globalStyles';

function BedsRange(props) {
  const { beds, syncStates } = useStore((s) => ({
    beds: s.beds,
    syncStates: s.syncStates,
  }));
  const rooms = [1, 2, 3];

  const selectedFontColor = globalStyles.colors.white;
  const unselectedFontColor = globalStyles.colors.black;
  const selectedColor = globalStyles.colors.second;
  const unselectedColor = 'transparent';

  function handleClick(s) {
    syncStates({
      beds: beds.includes(s) ? beds.filter((b) => b !== s) : [...beds, s],
    });
  }

  return (
    <Range {...props}>
      <Buttons>
        <Button
          onClick={() => {
            syncStates({
              beds: []
            })
          }}
          active={beds.length !== 0}
          label={"Any"}
          style={{
            width: '20%',
            textTransform: "capitalize"
          }}
        />
        {rooms.map((n, i) => {
          return (
            <Button
              key={`button${i}`}
              onClick={() => {
                handleClick(n);
              }}
              active={!beds.includes(n)}
              label={String(n)}
              style={{
                width: '20%',
              }}
            />
          );
        })}
      </Buttons>
    </Range>
  );
}

export default BedsRange;

const Range = styled.div`
  position: relative;
  width: 100%;
  min-width: 250px;
  height: 100%;
  font-size: .8rem;
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2%;
`;
