/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, forwardRef } from 'react';
import styled from 'styled-components';
import VideoOrbit from '../../components/VideoOrbit';
import AerialMap from '../../components/AerialMap';

type AerialOrbitProps = {
  onDirectionSet: (v: boolean) => void,
  onPlaySet: (v: boolean) => void,
  onInit: () => void,
  onComplete: () => void,
  rexallSection: string,
  aerialDir: boolean,
  orbitIndex: number,
  aerialRunning: boolean,
  aerialUp: boolean
}

const AerialOrbit = forwardRef<unknown, AerialOrbitProps>(function AerialOrbit({ 
  onDirectionSet,
  onPlaySet,
  onInit,
  onComplete,
  rexallSection,
  aerialDir,
  orbitIndex,
  aerialRunning,
  aerialUp
}, ref) {

  useEffect(() => {
    return () => {
      onInit()
    };
  }, []);
  return (
    <OrbitWrapper
      className="centerABS"
      style={{
        opacity: rexallSection !== 'aerial view' ? 0 : 1,
      }}
    >
      <AerialMap
        bgImage=""
        aerialRunning={aerialRunning}
        aerialUp={aerialUp}
        className={
          aerialRunning || !aerialUp
            ? 'aerialMap centerABS hide'
            : 'aerialMap centerABS show'
        }
      />
      <img src="/assets/images/exteriors/terrace.webp" style={{opacity: aerialUp ? 1 : 0, transition: "0.6s", position: "absolute", width: "100vw", height: "100vh", zIndex: 1}} alt="" />
      <VideoOrbit
        direction={aerialDir}
        directionSet={onDirectionSet}
        fwdVideos={[
          '/assets/video/aerial/0-aerial.mp4',
          '/assets/video/aerial/1-aerial.mp4',
          '/assets/video/aerial/2-aerial.mp4',
          '/assets/video/aerial/3-aerial.mp4',
        ]}
        bwdVideos={[
          '/assets/video/aerial/0-aerial-reversed.mp4',
          '/assets/video/aerial/1-aerial-reversed.mp4',
          '/assets/video/aerial/2-aerial-reversed.mp4',
          '/assets/video/aerial/3-aerial-reversed.mp4',
        ]}
        wrapperProps={{
          className: 'videoWrapper centerABS show',
        }}
        videoProps={{
          playsInline: true,
        }}
        indexState={orbitIndex}
        playState={aerialRunning && rexallSection === 'aerial view'}
        playSet={onPlaySet}
        lockWhilePLaying={true}
        onComplete={onComplete}
        delayMS={0}
      />
    </OrbitWrapper>
  );
});
export default AerialOrbit;

const OrbitWrapper = styled.div`
  position: absolute;
  top: 0%;
  width: 100%;
  height: 100%;
  transition: opacity 0.8s ease-in-out;

  .videoWrapper {
    transition: opacity;
    transition-duration: 200ms;
    & video {
      object-fit: cover;
    }
  }

  .show {
    opacity: 1;
    z-index: 100;
    svg {
      scale: 2.7;
    }
  }

  .hide {
    opacity: 0;
  }
`;
