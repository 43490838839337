import TSVGPropTypes from '../SVGPropTypes';

function HeartIcon({
  strokeWidth,
  strokeColor = 'white',
  bgColor = 'black',
  heartColor = 'red',
  ...props
}: TSVGPropTypes) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 61 61"
      width="100%"
      height="100%"
      cursor="pointer"
      {...props}
    >
      <path
        d="M0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30Z"
        fill={bgColor}
      />

      <path
        d="M30.001 39.625C30.001 39.625 19.0635 33.5 19.0635 26.0625C19.0637 24.748 19.5192 23.4741 20.3525 22.4574C21.1858 21.4408 22.3455 20.7441 23.6345 20.4859C24.9234 20.2276 26.262 20.4238 27.4226 21.0409C28.5833 21.6581 29.4944 22.6582 30.001 23.8712L30.001 23.8712C30.5076 22.6582 31.4187 21.6581 32.5793 21.0409C33.74 20.4238 35.0786 20.2276 36.3675 20.4859C37.6564 20.7441 38.8161 21.4408 39.6494 22.4574C40.4828 23.4741 40.9383 24.748 40.9385 26.0625C40.9385 33.5 30.001 39.625 30.001 39.625Z"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        fill={heartColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default HeartIcon;
