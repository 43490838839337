import styled from 'styled-components';

import Form from '../../components/Form';
import ShareQR from './ShareQR';

type TSharePromptProps = {
  encodedFav: string;
};

const SharePrompt = ({encodedFav}: TSharePromptProps) =>{
  const url = `https://rexall-app-shared.vercel.app?${encodedFav}`;

  return (
    <SharePromptWrapper>
      <FAVS>
        <ShareQR url={url}></ShareQR>
        <FormWrapper>
          <Form encodedFav={encodedFav} />
        </FormWrapper>
      </FAVS>
    </SharePromptWrapper>
  );
}

export default SharePrompt;

const SharePromptWrapper = styled.div`
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-color: black;
`;

const FAVS = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 80%;
`;

const FormWrapper = styled.div`
  width: 100%;
  height: 60%;
`;
