import styled from "styled-components"
import globalStyles from "../../data/globalStyles"
import RotatePhone from "./RotatePhone"
function LandScapePrompt() {
  return (
    <Wrapper>
      PLEASE USE ON <br />
      LANDSCAPE MODE
      <br/>
      <ScreenWrapper>
        <RotatePhone />
      </ScreenWrapper>
    </Wrapper>
  )
}
export default LandScapePrompt
const Wrapper = styled.div`
  position: absolute;
  z-index: 9999;
  top: 0%;
  width: 100%;
  height: 100%;
  background-color: ${globalStyles.colors.main};
  color: ${globalStyles.colors.second};
  text-align: center;
  font-size: 8rem;
  font-family: title;
  display: none;
  @media only screen and (orientation: portrait) {
    display: grid;
    place-content: center;
  }
`

const ScreenWrapper = styled.div`
  margin-top: 2.5rem;
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
`