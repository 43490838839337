import { useEffect, useState, ReactNode, Fragment } from 'react';
import styled from 'styled-components';
import './styles.css';

type TProps = {
  childrenArr: ReactNode[];
  triggerIndex: number;
  durationMS: number;
  delayMS?: number;
  [x: string]: unknown;
};

function TransitionDiv(props: TProps) {
  const [visibleIndex, setVisibleIndex] = useState(0);
  const { triggerIndex, childrenArr, durationMS, delayMS } = props;
  const duration = durationMS ?? 800;
  const delay = delayMS ?? 0;
  useEffect(() => {
    const root = document.getElementById('root') as HTMLElement;
    if (visibleIndex === null) {
      setVisibleIndex(triggerIndex);
    } else {
      root.style.pointerEvents = 'none';
      setTimeout(() => {
        setVisibleIndex(triggerIndex);
        root.style.pointerEvents = 'auto';
      }, duration + 100);
    }
  }, [triggerIndex]);

  return (
    <Fragment>
      {childrenArr.map((node, i) => {
        return (
          (visibleIndex === i || triggerIndex === i) && (
            <Wrapper
              key={`children${i}`}
              $active={triggerIndex === i}
              $duration={duration}
              $delay={delay}
              {...props}
            >
              {node}
            </Wrapper>
          )
        );
      })}
    </Fragment>
  );
}

export default TransitionDiv;

type TWrapperProps = {
  $active: boolean;
  $delay: number;
  $duration: number;
};

const Wrapper = styled.div<TWrapperProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  animation-name: ${(p) => (p.$active ? 'fadeIn' : 'fadeOut')};
  animation-duration: 1000ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  -webkit-user-drag: none;
`;
