import { useState, useEffect, useRef, Fragment } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import { useMediaQuery } from "react-responsive"
import Heart from '../SVGIcons/Heart';
import Button from 'common/src/components/Button';
import CloseIcon from '../CloseIcon';

import { TUnit } from '../../lib/formatData';
import globalStyles from '../../data/globalStyles';
import { useStore } from '../../../../rexall-controller/src/state/store';

function UnitDetails({
  unitData,
  onClick = undefined,
  favorites = [] as string[],
  favoriteClick = () => void 0,
  closeClick = () => void 0,
  closeButton = true,
  listOpen = undefined,
  ...props
}) {
  const [data, setData] = useState<TUnit | null>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { orbitIndex, unitCardSection, unitCardOpen  } = useStore((s) => ({
    orbitIndex: s.orbitIndex,
    unitCardSection: s.unitCardSection,
    unitCardOpen: s.unitCardOpen,
  }));
  const isTablet = useMediaQuery({ maxWidth: 1366, maxHeight: 1024 })
  const duration = 0.3;
  console.log("list open",listOpen)
  const isFave = () =>
    favorites ? favorites.some((e) => e === data?.unit) : false;
  useEffect(() => {
    if (wrapperRef.current) {
      if (unitData && data === null) {
        gsap.to(wrapperRef.current, {
          opacity: 1,
          duration,
          onStart: () => {
            setData(unitData);
          },
        });
        return;
      }

      if (unitData && data) {
        gsap.to(wrapperRef.current, {
          opacity: 0,
          duration: duration / 2,
          onComplete: () => {
            setData(unitData);
            gsap.to(wrapperRef.current, {
              opacity: 1,
              duration: duration / 2,
            });
          },
        });
        return;
      }

      if (unitData === null && data) {
        gsap.to(wrapperRef.current, {
          opacity: 0,
          duration,
          onComplete: () => {
            setData(unitData);
          },
        });
        return;
      }
    }
  }, [unitData]);

  return (
    <DetailsWrapper ref={wrapperRef} {...props} orbitIndex={orbitIndex} listOpen={listOpen} unitCardOpen={unitCardOpen}>
      <h1>Residence {data?.unit}</h1>
      <div className="detailsCopy">
        <h3>
          BEDROOMS:<span> {data && BEDS[data?.beds]}</span>
        </h3>
        <h3>
          FLOOR:<span> {[data?.level]}</span>
        </h3>
        <h3>
          EXT. AREA:<span> {[data?.extArea]} sqft</span>
        </h3>
        <h3>
          INT. AREA:<span> {[data?.intArea]} sqft</span>
        </h3>
        <h3>
          ORIENTATION:<span> {[data?.aspect]}</span>
        </h3>
      </div>
      <div className="detailsButtons">
        {onClick && (
          <Fragment>
            <Button
              label="View Details"
              active={true}
              style={{
                width: '80%',
                minWidth: '70%',
              }}
              onClick={onClick}
            />
            <div
              className="favIcon"
              onClick={() => {
                favoriteClick && favoriteClick();
              }}
            >
              <Heart
                strokeColor={'black'}
                strokeWidth={1}
                bgColor={isFave() ? 'black' : 'white'}
              />
            </div>
          </Fragment>
        )}
      </div>
      {closeButton && (
        <CloseIcon
          className="closeIcon"
          bgColor={unitCardOpen ? "transparent" : globalStyles.colors.white}
          strokeColor={((unitCardSection === 1 || unitCardSection === 2 || unitCardSection === 0) && unitCardOpen) ? "white" : "#000"}
          strokeWidth={4}
          onClick={() => {
            closeClick && closeClick();
          }}
        />
      )}
    </DetailsWrapper>
  );
}

export default UnitDetails;

const DetailsWrapper = styled.div<{orbitIndex?: any, unitCardOpen?: any, listOpen: any}>`
  position: absolute;
  z-index: 2;
  bottom: calc(8% + ${globalStyles.margin});
  right: ${({listOpen}) => listOpen ? "1rem" : "none"};
  left: ${({listOpen}) => !listOpen ? "2rem" : "none"};
  color: ${globalStyles.colors.white};
  opacity: 0;
  padding: 2rem 1.5rem;
  border: 2px solid ${globalStyles.colors.white};
  border-radius: ${globalStyles.borderRadius};
  background-color: ${globalStyles.colors.white};
  color: ${({unitCardOpen}) => (unitCardOpen) ? "white" : "#000"};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.25rem;
  transform: scale(0.95) !important;
  transform-origin: bottom left;
  transition: 0.3s;

  & h1 {
    font-size: 2rem;
    font-family: title;
    font-weight: 100;
  }

  .detailsCopy {
    width: 100%;

    & h3 {
      position: relative;
      font-size: 1rem;
      font-family: title;
      font-weight: 100;
      letter-spacing: 0.1rem;
      width: 100%;
      height: 2rem;
    }

    & h3 span {
      width: 50%;
      display: inline-block;
      position: absolute;
      right: 0%;
      color: ${({unitCardOpen}) => (unitCardOpen) ? "white" : "#000"};
      font-size: .8rem;
      font-family: main;
      font-weight: 600;
      letter-spacing: unset;
      text-align: left;
      text-transform: capitalize;
    }
  }

  .detailsButtons {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .favIcon {
    border: 1px solid ${globalStyles.colors.black};
    border-radius: 100%;
    width: 3.75rem;
    height: 3.75rem;
    margin-left: 10%;
    pointer-events: all;
    cursor: pointer;

    & svg {
      position: relative;
      width: 100%;
      padding: 8px;
    }
  }

  .closeIcon {
    position: absolute;
    width: 2.75rem;
    height: 2.75rem;
    top: 0;
    right: 0;
    margin: 25px;
    transform: translate(30%, -30%);
    pointer-events: all;
    transition: 0.3s;
    cursor: pointer;
  }
`;

const BEDS = {
  1: 'One',
  2: 'Two',
  3: 'Three',
};
