import styled from "styled-components";
import globalStyles from "../../data/globalStyles";

const Pland2DWrapper = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background: ${globalStyles.colors.black};
`;

export default Pland2DWrapper;