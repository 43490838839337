import { useStore } from '@state/store';
import { useSocketIO } from 'common/src/providers/sockets/SocketIOProvider';
import { useState } from 'react';

export const useSyncStateWithSocket = () => {
  const syncStates =  useStore(s => s.syncStates);
  const [resyncAllInProgress, setResyncAllInProgress] = useState<boolean>(false)
  const { emitSyncStateEvent } = useSocketIO();

  const syncStateWithSocket = (stateToUpdate: string, payload: any) => {
    syncStates({[stateToUpdate]: payload});
    emitSyncStateEvent({
      stateToUpdate,
      payload
    })
  };

  const syncMultipleStatesWithSocket = (statesToUpdate: { [key: string]: any }) => {
    syncStates(statesToUpdate);
    Object.entries(statesToUpdate).forEach(([stateToUpdate, payload]) => {
      emitSyncStateEvent({
        stateToUpdate,
        payload
      });
    });
  }

  const resyncAllStatesWithSocket = () => {
    //  Get all states from current state and emit them to the server via emitSyncStateEvent
    setResyncAllInProgress(true);
    const {
      syncStates,
      controlsCatcher,
      orbitLoaded,
      ...statesToUpdate
    } = useStore.getState();
    const maxDepth = Object.keys(statesToUpdate).length - 1;
    // iterate through all states and emit them to the server, on complete set resyncAllWorkInProgress to false
    Object.entries(statesToUpdate).forEach(([stateToUpdate, payload], index) => {
      console.log('individual stateToUpdate -->>', stateToUpdate);
      emitSyncStateEvent({
        stateToUpdate,
        payload
      }, true);
      if(index === maxDepth) {
        setResyncAllInProgress(false);
      }
    })
  }

  return { resyncAllInProgress, syncStateWithSocket, syncMultipleStatesWithSocket, resyncAllStatesWithSocket };
};
