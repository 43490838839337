function EmailTemplate({ link }) {
	const thStyle = {
		position: "relative",
		width: "100%"
	}

	const resetStyle = {
		margin: 0,
		padding: 0,
		border: 0,
		verticalAlign: "baseline"
	}

	return (
		<html
			style={{
				width: "1269px",
				maxWidth: "100vw",
				backgroundColor: "white"
			}}>
			<head></head>
			<body
				style={{
					width: "1269px",
					maxWidth: "100vw",
					backgroundColor: "white"
				}}>
				<table
					cellSpacing={0}
					style={{
						width: "1269px",
						maxWidth: "100vw"
					}}>
					<tbody>
						<tr>
							<th
								//@ts-expect-error nnn
								style={{
									...resetStyle,
									...thStyle,
									height: "714px"
								}}>
								<a
									href={link}
									target="_blank"
									style={{
										...resetStyle,
										display: "block",
										position: "relative",
										width: "100%",
										height: "550px"
									}}>
									<img
										src="https://rexall-app-shared.vercel.app/images/email/emailHeader.png"
										alt=""
										style={{
											...resetStyle,
											display: "block",
											position: "relative",
											width: "100%",
											height: "714px",
											objectFit: "contain"
										}}
									/>
								</a>
							</th>
						</tr>
						<tr>
							<th
									//@ts-expect-error nnn
								style={{
									...resetStyle,
									...thStyle,
									height: "232px"
								}}>
								<a
									href={"https://marlinspring.com/about/"}
									target="_blank"
									style={{
										...resetStyle,
										display: "block",
										position: "relative",
										width: "100%",
										height: "232px"
									}}>
									<img
										src="https://rexall-app-shared.vercel.app/images/email/emailFooter.png"
										alt=""
										style={{
											...resetStyle,
											display: "block",
											position: "relative",
											width: "100%",
											height: "232px",
											objectFit: "contain"
										}}
									/>
								</a>
							</th>
						</tr>
					</tbody>
				</table>
			</body>
		</html>
	)
}

export default EmailTemplate
