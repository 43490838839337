import { useEffect, useState } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import globalStyles from '../../data/globalStyles';

type TGridGalleryProps = {
  indexState?: number | null;
  paths: string[];
  onSelectedImage?: (n: number | null) => void;
  selectedScale?: number;
  stagger?: boolean;
  [x: string]: unknown;
};

function GridGallery(props: TGridGalleryProps) {
  const [activeIndex, setActiveIndex] = useState<null | number>(null);
  const { indexState, paths, selectedScale = 1, onSelectedImage, stagger, ...rest } = props;

  useEffect(() => {
    gsap.to('.gridGalleryImage', {
      opacity: (i) => (activeIndex === null ? 1 : activeIndex === i ? 1 : 0.2),
      scale: (i) => (activeIndex === null ? 1 : activeIndex === i ? selectedScale : 0.9),
      stagger: stagger ? 0.05 : 0,
      ease: "power1.inOut",
    });
    onSelectedImage && onSelectedImage(activeIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);
  
  useEffect(() => {
    if (indexState === undefined) return;
    setActiveIndex(indexState);
  }, [indexState]);

  return (
    <GalleryWrapper {...rest}>
      <Images>
        {paths.map((path, i) => {
          return (
            <ImageWrapper
              key={`gridGalleryImage${i}`}
              className={'gridGalleryImage' + (activeIndex === i ? ' imageGalleryActive' : '') }
              onClick={() => {
                setActiveIndex(activeIndex === i ? null : i)
              }}
            >
              <img src={activeIndex === i ? path.replace('/thumb', '') : path}/>
            </ImageWrapper>
          );
        })}
      </Images>
    </GalleryWrapper>
  );
}

export default GridGallery;

const GalleryWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Images = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 100%;
  z-index: 1;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  align-items: center;
  justify-content: center;
  gap: 2.333%;
  padding: ${globalStyles.margin};
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1/1;
  z-index: 1;
  opacity: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transform-origin: 50% 50%;
  cursor: pointer;
  
  & img {
    border-radius: ${globalStyles.borderRadius};
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
