import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';

import Table from '../../components/Table';
import CloseIcon from '../../components/CloseIcon';

import { toggleArrayItem } from '../../utils/functions';

import globalStyles from '../../data/globalStyles';
import { TUnit } from '../../lib/formatData';
import { TInitialState } from '../../data/initialState';
import { useStore } from '../../../../rexall-controller/src/state/store';
import { useSyncStateWithSocket } from '../../../../rexall-controller/src/hooks/socket/useSyncStateWithSocket';

export interface IListProps extends Pick<TInitialState, 'selectedUnit' | 'listOpen' | 'favorites'>{
  filteredUnits: TUnit[];
  onClose: () => void;
  onDestroy: () => void;
  toggleUnit: (unit: TUnit | null) => void;
  toggleFavorites: (favorites: string[]) => void;
}

function List({
  filteredUnits,
  selectedUnit,
  listOpen,
  favorites,
  onClose,
  onDestroy,
  toggleUnit,
  toggleFavorites,
  ...props
}: IListProps & React.HTMLAttributes<HTMLDivElement>) {
  const listRef = useRef<HTMLDivElement>(null);

  const {
    rowClicked,
  } = useStore((s) => ({
    rowClicked: s.rowClicked,
  }));

  const { syncMultipleStatesWithSocket } = useSyncStateWithSocket();

  useEffect(() => {
    if (listRef.current) {
      gsap.to(listRef.current, {
        opacity: listOpen ? 1 : 0,
        onStart: () => {
          listOpen &&
            gsap.set(listRef.current, {
              display: 'initial',
            });
        },
        onComplete: () => {
          !listOpen &&
            gsap.set(listRef.current, {
              display: 'none',
            });
        },
      });
    }
  }, [listOpen]);

  useEffect(() => {
    return () => {
      onDestroy();
    };
  }, []);

  return (
    <ListWrapper {...props} ref={listRef}>
      <Table
        favorites={favorites}
        units={filteredUnits}
        activeApartment={selectedUnit}
        activeClassname="activeRow"
        className="table"
        rowOnClick={(unit) => {
          if (selectedUnit === null) {
            toggleUnit(unit);
          } else {
            const newUnit = selectedUnit.unit === unit.unit ? null : unit;
            toggleUnit(newUnit);
          }
          syncMultipleStatesWithSocket({rowClicked: !rowClicked})
        }}
        favoriteClick={(unitObj: TUnit) => {
          const toggled = toggleArrayItem(favorites, unitObj.unit);
          toggleFavorites(toggled);
        }}
      />
      <CloseIcon
        className="closeIcon"
        bgColor={globalStyles.colors.black}
        strokeColor={globalStyles.colors.white}
        strokeWidth={2}
        onClick={() => {
          onClose();
        }}
      />
    </ListWrapper>
  );
}

export default List;

const ListWrapper = styled.div`
  position: absolute;
  z-index: 5;
  width: clamp(500px,40%,700px);
  height: 70%;
  background-color: ${globalStyles.colors.white};
  opacity: 0;
  pointer-events: none;
  left: ${globalStyles.margin};
  bottom: calc(8% + ${globalStyles.margin});
  border-radius: ${globalStyles.borderRadius};

  .table {
    pointer-events: all;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 93%;
    height: 93%;
    border-spacing: 0;
    border-radius: ${globalStyles.borderRadius};
    overflow: clip;

    & thead {
      line-height: 3rem;
      background-color: ${globalStyles.colors.black};
      & th {
        font-weight: 600;
        font-size: 0.6rem;
        color: ${globalStyles.colors.white};
      }
    }

    & tbody {
      & tr {
        font-size: .8rem;
        line-height: 3rem;
        transition-property: background-color, color;
        transition-duration: 0.8s;
        transition-timing-function: ease-in-out;
        background-color: ${globalStyles.colors.white};
        text-transform: lowercase;
        :nth-of-type(odd) {
          background-color: ${globalStyles.colors.second}1a;
        }
      }
    }
  }

  .activeRow {
    color: ${globalStyles.colors.white};
    background-color: ${globalStyles.colors.second}CC !important;
  }

  .closeIcon{
    position: absolute;
    width: 4rem;
    height: 4rem;
    top: 0;
    right: 0;
    transform: translate(30%, -30%);
    pointer-events: all;
    cursor: pointer;
  }
`;
