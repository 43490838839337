import { TUnit } from '../lib/formatData';

export enum Palette {
  Light = 0,
  Medium = 1,
  Dark = 2,
}

export interface IFiltersInitialState {
  fullUnits: TUnit[] | null;
  filterOpen: boolean;
  beds: number[];
  baths: number[];
  levelRange: (null | number)[];
  minLevel: null | number,
  maxLevel: null | number,
  areaRange: (null | number)[],
  minArea: null | number,
  maxArea: null | number,
  faces: ("N" | "E" | "S" | "W")[]
  filteredUnits: TUnit[] | null
}

export interface TInitialState extends IFiltersInitialState {
  controlsCatcher: unknown
  navigating: boolean
  navDurationSec: number
  orbitLoaded: boolean
  orbitIndex: number
  playSoundOn: boolean
  orbitFrame: number
  orbitRunning: boolean
  orbitDir: boolean
  specDisplays: "image" | "copy" | "clear"
  specOpen: boolean
  specIndex: number | null
  palette: Palette
  selectedUnit: TUnit | null
  viewForResidency: string | null
  amenityIndex: null | number
  amenityRenderOpen: boolean
  activeCategory: null | string
  activePOI: null | number
  mapGalleryOpen: boolean
  mapGalleryIndex: number 
  unitCardOpen: boolean
  unitCardSection: number
  activeVignette: null | number
  rexallSection: "amenities" | "residences" | "aerial view" 
  listOpen: boolean
  aerialUp: boolean
  aerialDir: boolean
  aerialRunning: boolean
  favorites: string[]
  compareFavorites: string[]
  activeFavorite: null | number
  sharePromptOpen: boolean
  tourIndex: number
  gallerySection: "interiors" | "rexall" | "local area"
  galleryIndex: number | null 
  menuOpen: boolean
  menuIndex: number | null
  playFullIntroVideo: boolean
  rowClicked: boolean
}

export const initialState: TInitialState = {
  controlsCatcher: null,
  navigating: true,
  navDurationSec: 2,
  playSoundOn: false,
  orbitLoaded: false,
  orbitIndex: 0,
  orbitFrame: 0,
  orbitRunning: false,
  orbitDir: true,
  specDisplays: import.meta.env.VITE_IS_WEB_BUILD ? 'copy' : 'clear',
  specOpen: false,
  specIndex: null,
  palette: Palette.Medium,
  selectedUnit: null,
  viewForResidency: null,
  baths: [],
  beds: [],
  faces: [],
  levelRange: [null, null],
  minLevel: null,
  maxLevel: null,
  areaRange: [null, null],
  minArea: null,
  maxArea: null,
  amenityIndex: null,
  amenityRenderOpen: false,
  activeCategory: null,
  activePOI: null,
  mapGalleryOpen: false,
  mapGalleryIndex: 0,
  filterOpen: false,
  unitCardOpen: false,
  unitCardSection: 0,
  activeVignette: null,
  rexallSection: 'residences',
  filteredUnits: null,
  fullUnits: null,
  listOpen: false,
  aerialRunning: false,
  aerialUp: false,
  aerialDir: true,
  favorites: [],
  compareFavorites: [],
  activeFavorite: null,
  sharePromptOpen: false,
  tourIndex: 0,
  gallerySection: 'interiors',
  galleryIndex: null,
  menuOpen: false,
  menuIndex: null,
  playFullIntroVideo: false,
  rowClicked: true
};
