import { useEffect, useState, Fragment, useRef } from 'react';
import styled from 'styled-components';
import * as THREE from 'three';
import { Canvas } from '@react-three/fiber';

import NoFavoritesPrompt from 'common/src/components/Favorites/NoFavoritesPrompt';
import FavoritedCard from 'common/src/components/FavoriteCard';
import ShareIcon from 'common/src/components/SVGIcons/ShareIcon';
import Popup from 'common/src/components/Popup';
import SharePrompt from 'common/src/components/SharePrompt/SharePrompt';

import MenuButton from 'common/src/web_components/MenuButton';
import UnitDetails from 'common/src/components/UnitDetails';
import ApartmentModel from 'common/src/canvas_components/ApartmentModel';
import SocketsOrbitControls from 'common/src/canvas_components/SocketsOrbitControls';

import globalStyles from 'common/src/data/globalStyles';
import { useStore } from '@state/store';
import { useSocketIO } from 'common/src/providers/sockets/SocketIOProvider';
import { useSyncStateWithSocket } from '@hooks/socket/useSyncStateWithSocket';
import { toggleArrayItem } from '@utils/functions';

function Favorites() {
  const { socket, room } = useSocketIO();
  const { syncMultipleStatesWithSocket } = useSyncStateWithSocket();
  const [favData, setFavData] = useState([]);
  const { favorites, fullUnits, sharePromptOpen, activeFavorite, selectedUnit, syncStates } = useStore(
    (s) => ({
      selectedUnit: s.selectedUnit,
      fullUnits: s.fullUnits,
      favorites: s.favorites,
      sharePromptOpen: s.sharePromptOpen,
      activeFavorite: s.activeFavorite,
      syncStates: s.syncStates,
    }),
  );
  const controlsRef = useRef()

  function handleExploreClick(i) {
    syncMultipleStatesWithSocket({
      activeFavorite: i === activeFavorite ? null : i,
      selectedUnit: i
    });
  }

  useEffect(() => {
    if (favorites.length && fullUnits !== null) {
      setFavData(fullUnits.filter((unit) => favorites.includes(unit.unit)));
    }
  }, [favorites, fullUnits]);

  return (
    <FavoritesWrapper>
      <MenuButton onMenuClicked={()=>{
        syncMultipleStatesWithSocket({
          menuOpen: true,
          menuIndex: null
        });
      }} />

      {favorites.length ? (
        <Fragment>
          <FAVS>
            {favData.map((fav, i) => {
              return (
                <FavoritedCard
                  key={i}
                  data={fav}
                  exploreClick={() => {
                    handleExploreClick(fav);
                  }}
                  closeClick={() => {
                    const toggled = toggleArrayItem(favorites, fav.unit);
                    syncMultipleStatesWithSocket({
                      favorites: toggled,
                    });
                  }}
                />
              );
            })}
          </FAVS>
          <ShareIconWrapper
            onClick={() => {
              syncStates({
                sharePromptOpen: true,
              });
            }}
          >
            <ShareIcon
              strokeWidth={5}
              strokeColor={globalStyles.colors.second}
              bgColor={globalStyles.colors.second}
            />
          </ShareIconWrapper>
        </Fragment>
      ) : (
        <NoFavoritesPrompt></NoFavoritesPrompt>
      )}
      <Popup
        durationMS={1000}
        openState={sharePromptOpen}
        closeColor="white"
        setOpenState={(v) => {
          syncStates({
            sharePromptOpen: v,
          });
        }}
      >
        <SharePrompt encodedFav={btoa(`${favorites?.join(',')}`)}/>
      </Popup>
      <Popup
        durationMS={1000}
        openState={activeFavorite !== null}
        closeColor="transparent"
        setOpenState={(v) => null}
      >
        <UnitDetails
          unitData={activeFavorite}
          className="unitDetails"
          closeClick={() => {
            syncMultipleStatesWithSocket({
              activeFavorite: null,
            })
          }}
        />
        <Canvas
          camera={{
            fov: 70,
            near: 0.1,
            position: new THREE.Vector3(127.8, 112.7, 106.23),
          }}
          style={{ background: globalStyles.colors.black }}
        >
          <ApartmentModel
            selectedUnit={selectedUnit}
            model={`/models/L.glb`}
            textures={[
              `/textures/light/L.jpg`,
              `/textures/medium/L.jpg`,
              `/textures/dark/L.jpg`,
            ]}
            animateCamera={false}
            textureID={1}
            show={selectedUnit !== null}
          />
          <SocketsOrbitControls
            controlProps={{
              makeDefault: true,
              enablePan: false,
              minAzimuthAngle: 0.2,
              maxPolarAngle: 1.3,
              enableZoom: true,
              rotateSpeed: 0.5,
            }}
            role="emitter"
            room={room}
            id={socket?.id}
            ref={controlsRef}
          />
        </Canvas>
      </Popup>
    </FavoritesWrapper>
  );
}

export default Favorites;

const FavoritesWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: black;
  touch-action: pan-x;

  .unitDetails{
    left: unset;
    right: ${globalStyles.margin};
    top: ${globalStyles.margin};
  }
`;

const FAVS = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  overflow-y: hidden;
  overflow-x: scroll;
  display: flex;
  gap: 5%;
  padding: 5% 0%;
  touch-action: pan-x;

  > * {
    touch-action: pan-x;
  }

  ::-webkit-scrollbar {
    height: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${globalStyles.colors.second};
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track {
    background-color: ${globalStyles.colors.black}CC;
  }
`;

const ShareIconWrapper = styled.div`
  width: 3rem;
  height: 3rem;
  right: ${globalStyles.margin};
  bottom: ${globalStyles.margin};
  position: absolute;
`;
