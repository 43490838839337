import styled from 'styled-components';

function Render({url, condition, ...props}:{url:string, condition: boolean, [x:string]: unknown}) {
  return (
    <RenderWrapper
      style={{
        opacity: condition ? 1 : 0,
      }}
      {...props}
    >
      <img
        src={url}
      />
    </RenderWrapper>
  );
}

export default Render;

const RenderWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  transition: opacity 0.8s ease-in-out;

  & img {
    position: absolute;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 100% 50%;
  }
`;
