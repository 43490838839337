/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';

import LevelRange from './components/Ranges/LevelRange';
import AreaRange from './components/Ranges/AreaRange';
import BedsRange from './components/Ranges/BedsRange';
import BathsRange from './components/Ranges/BathsRange';
import CloseIcon from '../../components/CloseIcon';
import Button from '../../components/Button';

import globalStyles from '../../data/globalStyles';
import { IFiltersInitialState } from '../../data/initialState';
import { TUnit } from '../../lib/formatData';

interface IFilterProps extends Omit<IFiltersInitialState, 'minLevel' | 'maxLevel' | 'minArea' | 'maxArea' | 'filteredUnits'> {
  orbitIndex: number
  onFilteredChangedEffect: (filtered: TUnit[]) => void
  onResetClicked: () => void
  onCloseClicked: () => void
  onDestroy: () => void
}

// add react element props as type with IFilterProps 
function Filter({ 
    fullUnits,
    filterOpen,
    beds,
    baths,
    levelRange,
    areaRange,
    faces,
    orbitIndex,
    onFilteredChangedEffect,
    onResetClicked,
    onCloseClicked,
    onDestroy,
    ...props
} : IFilterProps & React.HTMLAttributes<HTMLDivElement>) {
  const filterRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (filterRef.current) {
      gsap.to(filterRef.current, {
        opacity: filterOpen ? 1 : 0,
        onStart: () => {
          filterOpen &&
            gsap.set(filterRef.current, {
              display: 'initial',
            });
        },
        onComplete: () => {
          !filterOpen &&
            gsap.set(filterRef.current, {
              display: 'none',
            });
        },
      });
    }
  }, [filterOpen]);

  useEffect(() => {
    if (fullUnits) {
      let filtered: TUnit[] = [];
      filtered = fullUnits.filter((obj) => {
        return (
          obj.level >= (levelRange[0] || 0) &&
          obj.level <= (levelRange[1] || 0) &&
          obj.area >= (areaRange[0] || 0) &&
          obj.area <= (areaRange[1] || 0) &&
          (!faces.length ||
            faces.some((item: any) => obj.aspect.includes(item))) &&
          (!beds.length || beds.includes(obj.beds)) &&
          (!baths.length || baths.includes(obj.baths))
        );
      });
      onFilteredChangedEffect(filtered);
    }
  }, [fullUnits, beds, baths, levelRange, areaRange, faces, orbitIndex]);

  useEffect(() => {
    return () => {
      onDestroy();
    };
  }, []);

  return (
    <FilterWrapper {...props} ref={filterRef}>
      <FilterColumn>
        <FilterCategory>
          <FilterLabel>BEDS</FilterLabel>
          <BedsRange />
        </FilterCategory>
        <FilterCategory>
          <FilterLabel>BATHS</FilterLabel>
          <BathsRange />
        </FilterCategory>
        <FilterCategory>
          <FilterLabel>FLOOR</FilterLabel>
          <LevelRange />
        </FilterCategory>
        <FilterCategory>
          <FilterLabel>INTERNAL AREA</FilterLabel>
          <AreaRange />
        </FilterCategory>
        <FilterCategory>
          <Button
            label="reset"
            active={true}
            style={{
              width: '100%',
              minWidth: '80%',
              fontSize: "0.8rem"
            }}
            onClick={() => {
              onResetClicked();
            }}
          />
        </FilterCategory>
      </FilterColumn>
      <CloseIcon
        className="closeIcon"
        bgColor={globalStyles.colors.black}
        strokeColor={globalStyles.colors.white}
        strokeWidth={2}
        onClick={() => {
          onCloseClicked();
        }}
      />
    </FilterWrapper>
  );
}

export default Filter;

const FilterWrapper = styled.div`
  position: absolute;
  z-index: 5;
  width: clamp(500px,40%,700px);
  height: 70%;
  background-color: ${globalStyles.colors.white};
  opacity: 0;
  pointer-events: none;
  left: ${globalStyles.margin};
  bottom: calc(8% + ${globalStyles.margin});
  border-radius: ${globalStyles.borderRadius};

  .closeIcon {
    position: absolute;
    width: 4rem;
    height: 4rem;
    top: 0;
    right: 0;
    transform: translate(30%, -30%);
    pointer-events: all;
    cursor: pointer;
  }
`;

const FilterColumn = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
`;

const FilterCategory = styled.div`
  padding: ${globalStyles.margin};
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid ${globalStyles.colors.black}1a;
`;

const FilterLabel = styled.p`
  width: 100%;
  height: 3rem;
  font-size: 0.8rem;
  color: ${globalStyles.colors.black};
`;

const orbitsAspects = {
  0: ['N', 'E'],
  1: ['N', 'W'],
  2: ['E', 'S', 'W'],
  3: ['S', 'E', 'W'],
};
