import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useRanger } from 'react-ranger';

import { useStore } from '@state/store';
import globalStyles from 'common/src/data/globalStyles';

function LevelRange() {
  const { levelRange, minLevel, maxLevel, syncStates } = useStore((s) => ({
    levelRange: s.levelRange,
    minLevel: s.minLevel,
    maxLevel: s.maxLevel,
    syncStates: s.syncStates,
  }));
  const [level, setLevel] = useState(levelRange);
  const { getTrackProps: levelTrack, handles: levelHandles } = useRanger({
    values: level,
    onDrag: setLevel,
    onChange: () => {
      syncStates({
        levelRange: [level[0], level[1]],
      });
    },
    min: minLevel,
    max: maxLevel,
    stepSize: 1,
  },[minLevel, maxLevel]);

  useEffect(() => {
    setLevel(levelRange);
  }, [levelRange]);

  return (
    <Wrapper>
      <Labels>
        <Min>{level[0]}</Min>
        <Max>{level[1]}</Max>
      </Labels>
      <Range>
        <div
          {...levelTrack({
            style: {
              position: 'absolute',
              bottom: '5%',
              height: '0.2rem',
              width: '100%',
              background: globalStyles.colors.black,
              borderRadius: '2px',
            },
          })}
        >
          {levelHandles.map(({ getHandleProps }) => (
            <div
              {...getHandleProps({
                style: {
                  width: '1.8rem',
                  height: '1.8rem',
                  cursor: 'pointer',
                  borderRadius: '100%',
                  position: 'absolute',
                  pointerEvents: 'all',
                },
              })}
            />
          ))}
        </div>
      </Range>
    </Wrapper>
  );
}

export default LevelRange;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Range = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  height: 10%;
  font-size: .8rem;

  & div {
    :nth-of-type(1) {
      background-color: ${globalStyles.colors.black};
      pointer-events: all;
      cursor: pointer;
    }
    :nth-of-type(2) {
      background-color: ${globalStyles.colors.black};
      pointer-events: all;
      cursor: pointer;
    }
  }
`;

const Labels = styled.div`
  flex: 1;
  width: 105%;
  height: 10%;
  display: flex;
	justify-content: space-between;
  align-items: flex-start;
  font-size: .8rem;
`;

const Min = styled.p``;

const Max = styled.p``;
