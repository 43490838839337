import ErrorMessage from "@pages/ErrorMessage"
import Splash from "@pages/Splash"
import Maps from "@pages/Maps"
import Tour from "@pages/Tour"
import Details from "@pages/Details"
import Gallery from "@pages/Gallery"
import Amenities from "@pages/Amenities"
import Trigger from "@pages/Trigger"
import Rexall from "@pages/Rexall"
import Favorites from "@pages/Favorites"

const routes = [
  {
    name: "Splash",
    path: "/",
    exact: true,
    element: Splash,
    sub: null,
  },
  {
    name: "Maps",
    path: "/maps",
    exact: true,
    element: Maps,
    sub: null,
  },
  {
    name: "Rexall",
    path: "/rexall",
    exact: true,
    element: Rexall,
    sub: null,
  },
  {
    name: "Tour",
    path: "/tour",
    exact: true,
    element: Tour,
    sub: null,
  },
  {
    name: "Details",
    path: "/specs",
    exact: true,
    element: Details,
    sub: null
  },
  {
    name: "Gallery",
    path: "/gallery",
    exact: true,
    element: Gallery,
    sub: null
  },
  {
    name: "Amenities",
    path: "/amenities",
    exact: true,
    element: Amenities,
    sub: null
  },
  {
    name: "Favorites",
    path: "/favorites",
    exact: true,
    element: Favorites,
    sub: null
  },
  {
    name: "Trigger",
    path: "/trigger/:id",
    exact: true,
    element: Trigger,
    sub: null
  },
  { path: "/*", name: "Not Found", element: ErrorMessage },
]

export default routes
