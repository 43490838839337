/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRef, useEffect, useState } from 'react';
import { useTexture } from '@react-three/drei';
import * as THREE from 'three';
import gsap from 'gsap';
import { useGLTF } from '../../lib';

const paths = [
  '/models/CHEFSTABLE_SOCIALHUB_KIDSROOM.glb',
  '/models/FITNESS_YOGA.glb',
  '/models/LOBBY.glb',
  '/models/MEDIAROOM.glb',
  '/models/ROOFTERRACE.glb',
];

function AmenitiesModel({
  amenityIndex,
  controls,
  updateCamera = false,
  show,
  ...props
}) {
  const [display, setDisplay] = useState(false);
  const animatedArr = useRef<THREE.Mesh[]>([]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-expect-error
  const models = useGLTF(paths);

  const map = useTexture([
    '/textures/terrace.webp',
    '/textures/gym.webp',
    '/textures/ground.webp',
    '/textures/ground.webp',
    '/textures/garden.webp',
  ]);

  map.forEach((txt) => (txt.flipY = false));

  const index = {
    0: 2,
    1: 1,
    2: 1,
    3: 0,
    4: 0,
    5: 0,
    6: 4,
    7: 3,
  };

  function loopModels(callback) {
    animatedArr.current.forEach((model, i) => {
      if (model) {
        callback(model, i);
      }
    });
  }
  useEffect(() => {
    loopModels((model, i) => {
      gsap.to(model.material, {
        opacity: show ? 1 : 0,
        delay: show ? 0.8 : 0,
        onStart: () => {
          show && setDisplay(true);
        },
        onComplete: () => {
          !show && setDisplay(false);
        },
      });
    });
  }, [show]);

  // useEffect(() => {
  //   if (!controls || !updateCamera) return;
  //   //RESET
  //   if (!display) {
  //     gsap.set(camera.position, {
  //       x: -7.185884198392042,
  //       y: 7.383011000548269,
  //       z: -8.329427302199653,
  //     });
  //     gsap.set(controls.target, {
  //       x: 0,
  //       y: 0,
  //       z: 0,
  //     });
  //   }
  //   //LOBBY
  //   if (amenityIndex === 0) {
  //     gsap.set(camera.position, {
  //       x: 4.803996986721742,
  //       y: -0.5927894819491399,
  //       z: -3.5390891474182142,
  //     });
  //     gsap.set(controls.target, {
  //       x: 4,
  //       y: -2.5,
  //       z: -2,
  //     });
  //   }
  //   //GYM
  //   if (amenityIndex === 1) {
  //     gsap.set(camera.position, {
  //       x: 0.3998478647018162,
  //       y: -1.4547594918388655,
  //       z: -0.8427395575927406,
  //     });
  //     gsap.set(controls.target, {
  //       x: -3,
  //       y: -3,
  //       z: 0.75,
  //     });
  //   }
  //   //YOGA
  //   if (amenityIndex === 2) {
  //     gsap.set(controls.target, {
  //       x: 0.3,
  //       y: -3,
  //       z: 0.9,
  //     });
  //     gsap.set(camera.position, {
  //       x: 1.2400747579196976,
  //       y: -1.9188638525889763,
  //       z: 1.7165930628131147,
  //     });
  //   }
  //   //SOCIAL HUB
  //   if (amenityIndex === 3) {
  //     gsap.set(controls.target, {
  //       x: 4.1,
  //       y: 3,
  //       z: -2.3,
  //     });
  //     gsap.set(camera.position, {
  //       x: 2.814894240452242,
  //       y: 4.073825186619752,
  //       z: -2.785249612778519,
  //     });
  //   }
  //   //CHEF'S TABLE
  //   if (amenityIndex === 4) {
  //     gsap.set(controls.target, {
  //       x: 4.4,
  //       y: 3,
  //       z: -1.4,
  //     });
  //     gsap.set(camera.position, {
  //       x: 5.393749612429755,
  //       y: 4.148334911741925,
  //       z: -0.7241092580896207,
  //     });
  //   }
  //   //KIDS ROOM
  //   if (amenityIndex === 5) {
  //     gsap.set(controls.target, {
  //       x: 4.4,
  //       y: 3,
  //       z: -0.4,
  //     });
  //     gsap.set(camera.position, {
  //       x: 3.3801063410677843,
  //       y: 4.119039362412513,
  //       z: -0.7447126461955895,
  //     });
  //   }
  //   //ROOF TERRACE
  //   if (amenityIndex === 6) {
  //     gsap.set(controls.target, {
  //       x: -2.5,
  //       y: 3,
  //       z: -1.4,
  //     });
  //     gsap.set(camera.position, {
  //       x: -3.678451023766659,
  //       y: 5.883253507689066,
  //       z: -4.2248678587281745,
  //     });
  //   }
  // }, [display, amenityIndex, controls, updateCamera, camera.position]);
  return (
    <group position={[0, 0, 0]} visible={display} {...props}>
      {models.map((obj, i) => {
        const {
          nodes: {
            mesh_0: { geometry: geo },
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = obj as any;
        return (
          <mesh
            visible={i === index[amenityIndex]}
            key={`amenitie${i}`}
            geometry={geo}
            scale={100}
            position-y={i < 2 ? -3 : 3}
            ref={(mesh) => {
              if (mesh) {
                animatedArr.current[i] = mesh;
              }
            }}
          >
            <meshBasicMaterial
              map={map[i]}
              toneMapped={false}
              side={THREE.DoubleSide}
              opacity={0}
              transparent
            />
          </mesh>
        );
      })}
    </group>
  );
}

export default AmenitiesModel;
