/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useEffect, useState } from 'react';

import SVGOriginLogo from 'common/src/components/SVGOriginLogo';
import globalStyles from 'common/src/data/globalStyles';
import styled from 'styled-components';
import MapMarker from 'common/src/components/MapMarker';
import Map from 'common/src/components/Map/Map';
import mapData from 'common/src/data/mapData';
import gsap from 'gsap';
import { normalizeNumber, clamp } from '../../../utils/functions';

export interface IMapControllerProps {
  category: string;
  activeId: number;
}

function MapController({ category, activeId }: IMapControllerProps) {
  const [loaded, setLoaded] = useState(false);
  const mapRef = useRef<any>(null);
  const timeline = gsap.timeline();

  const markerData = mapData.rexall[0];
  const poisArr = [
    ...mapData.schools,
    ...mapData.recreation,
    ...mapData.restaurants,
    ...mapData.shops,
  ];

  const linesData = [...mapData.transport];
  // const linesData = [...mapData.transport].map((obj) => {
  //   delete obj.name;
  //   return obj;
  // });

  function getCategory(n) {
    if (n >= 0 && n <= 5) {
      return 'schools';
    } else if (n >= 6 && n <= 14) {
      return 'recreation';
    } else if (n >= 15 && n <= 23) {
      return 'restaurants';
    } else if (n >= 24 && n <= 37) {
      return 'shops';
    }
  }

  function getColor(category) {
    switch (category) {
      case 'schools':
        return globalStyles.colors.orange;
      case 'recreation':
        return globalStyles.colors.third;
      case 'restaurants':
        return globalStyles.colors.second;
      case 'shops':
        return globalStyles.colors.accent;
      default:
        break;
    }
  }

  const markerArr = poisArr.map<any>((obj) => {
    const category = getCategory(obj.index);
    return {
      component: (
        <MapMarker
          circleradius={20}
          text={obj.poiNumber}
          textcolor={globalStyles.colors.white}
          textsize={19}
          bgcolor={getColor(category)}
          bordercolor={getColor(category)}
          width="50px"
          height="50px"
          className="poiSelector"
          id={`poi-marker-${obj.index}`}
          data-category={category}
        />
      ),
      x: obj.coords[0],
      y: obj.coords[1],
    };
  });

  function getMarkersArr(callback) {
    const markers = Array.from(document.getElementsByClassName('poiSelector'));
    markers.forEach((marker: Element) => {
      callback(marker);
    });
  }

  useEffect(() => {
    if (mapRef.current && loaded) {
      const map = mapRef.current.getRef();
      setTimeout(() => {
        map.flyTo(mapCameraSettings.base);
      }, 1850);
    }
  }, [loaded]);

  useEffect(() => {
    if (!mapRef.current) return;
    const map = mapRef.current.getRef();
    const lineLayers = mapRef.current.getLineLayers();
    const layerIDS = Object.keys(lineLayers);
    if (category === null) {
      map.flyTo(mapCameraSettings.base);
      return;
    } else if (category === 'transport') {
      getMarkersArr((marker) => {
        gsap.to(marker, {
          opacity: 0,
          scale: 1,
        });
      });
      if (layerIDS.length) {
        timeline.to(
          {},
          {
            duration: 2,
            onUpdate: function () {
              const progress = timeline.progress();
              const normalized = normalizeNumber(progress, 0, 1);
              layerIDS.forEach((id, i) => {
                const color = linesData[i].lineColor;
                mapRef.current.updateLineLayerOpacity(id, normalized);
                mapRef.current.updateLineGradient(id, [
                  'interpolate',
                  ['linear'],
                  ['line-progress'],
                  clamp(normalized, 0, 0.999),
                  `${color}`,
                  clamp(normalized + 0.001, 0.001, 1),
                  `transparent`,
                ]);
              });
            },
          },
        );
      }
      map.flyTo(mapCameraSettings.transport);
      return;
    } else {
      map.flyTo(mapCameraSettings[category]);
    }
    getMarkersArr((marker) => {
      const cat = marker.dataset.category;
      gsap.to(marker, {
        opacity: cat === category ? 1 : 0,
        scale: cat === category ? markerScale[category] : 1,
      });
    });
    if (layerIDS.length) {
      if (map.getPaintProperty(layerIDS[0], 'line-opacity') === 0) return;
      timeline.to(
        {},
        {
          duration: 2,
          onUpdate: function () {
            const progress = timeline.progress();
            const normalized = normalizeNumber(progress, 0, 1);
            const opacity = 1 - normalized;
            layerIDS.forEach((id, i) => {
              const color = linesData[i].lineColor;
              mapRef.current.updateLineLayerOpacity(id, opacity);
              mapRef.current.updateLineGradient(id, [
                'interpolate',
                ['linear'],
                ['line-progress'],
                clamp(opacity, 0, 0.999),
                `${color}`,
                clamp(opacity + 0.001, 0.001, 1),
                `transparent`,
              ]);
            });
          },
        },
      );
    }
  }, [category]);

  useEffect(() => {
    if (category === null) return;
    const map = mapRef.current.getRef();
    if (activeId === null) {
      map.flyTo(mapCameraSettings[category]);
      getMarkersArr((marker) => {
        const cat = marker.dataset.category;
        if (category !== cat) return;
        gsap.to(marker, {
          opacity: 1,
          scale: markerScale[category],
        });
      });
      return;
    } else {
      const data =
        category === 'transport'
          ? {
              center: mapCameraSettings.transport.center,
              zoom: mapCameraSettings.transport.zoom,
            }
          : mapData[category].filter((obj) => obj.index === activeId)[0];
      map.flyTo({
        center: data.coords,
        zoom: data.zoom,
        curve: 1.2,
        speed: 0.8,
      });
    }
    getMarkersArr((marker) => {
      const id = marker.id;
      const cat = marker.dataset.category;
      if (category !== cat) return;
      gsap.to(marker, {
        opacity: id === `poi-marker-${activeId}` || activeId === null ? 1 : 0.2,
        scale: id === `poi-marker-${activeId}` ? 2 : markerScale[category],
      });
    });
  }, [activeId, category]);

  return (
    <MapControllerPage style={{ opacity: loaded ? 1 : 0 }}>
      <Map
        token={import.meta.env.VITE_MAPBOX_TOKEN}
        styleURL={import.meta.env.VITE_MAPBOX_STYLE}
        ref={mapRef}
        onloaded={() => {
          setLoaded(true);
        }}
        centralMarker={{
          component: (
            <SVGOriginLogo width="100px" height="100px" id="origin-marker" />
          ),
          x: markerData.coords[0],
          y: markerData.coords[1],
        }}
        poiMarkersArr={markerArr}
        linesArr={linesData as any}
      />
    </MapControllerPage>
  );
}

export default MapController;

const MapControllerPage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: ${globalStyles.colors.main};
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.8s ease-in-out;

  .poiSelector {
    opacity: 0;
    font-family: main;
  }
`;

const mapCameraSettings = {
  base: {
    center: [-79.4775, 43.6704],
    zoom: 15.07,
    curve: 1,
    speed: 1,
  },
  schools: {
    center: [-79.4775, 43.668],
    zoom: 14.32,
    curve: 1,
    speed: 1,
  },
  recreation: {
    center: [-79.478, 43.6646],
    zoom: 13.67,
    curve: 1,
    speed: 1,
  },
  restaurants: {
    center: [-79.4775, 43.6694],
    zoom: 15.07,
    curve: 1,
    speed: 1,
  },
  shops: {
    center: [-79.4775, 43.6685],
    zoom: 14.75,
    curve: 1,
    speed: 1,
  },
  transport: {
    center: [-79.4775, 43.6666],
    zoom: 12.1759,
    curve: 1,
    speed: 1,
  },
};

const markerScale = {
  base: 1,
  schools: 0.5,
  recreation: 0.6,
  restaurants: 0.7,
  shops: 0.5,
};
