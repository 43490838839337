import styled from 'styled-components';

import Button from 'common/src/components/Button';

import { useStore } from '@state/store';
import globalStyles from 'common/src/data/globalStyles';

function BathsRange(props) {
  const { baths, syncStates } = useStore((s) => ({
    baths: s.baths,
    syncStates: s.syncStates,
  }));
  const bathrooms = [1, 2];

  function handleClick(s) {
    syncStates({
      baths: baths.includes(s) ? baths.filter((b) => b !== s) : [...baths, s],
    });
  }

  return (
    <Range {...props}>
      <Buttons>
        <Button
          onClick={() => {
            syncStates({
              baths: []
            })
          }}
          active={baths.length !== 0}
          label={"Any"}
          style={{
            width: '20%',
            textTransform: "capitalize"
          }}
        />
        {bathrooms.map((n, i) => {
          return (
            <Button
              key={`button${i}`}
              onClick={() => {
                handleClick(n);
              }}
              active={!baths.includes(n)}
              label={String(n)}
              style={{
                width: '20%',
                
              }}
            />
          );
        })}
      </Buttons>
    </Range>
  );
}

export default BathsRange;

const Range = styled.div`
  position: relative;
  width: 100%;
  min-width: 250px;
  height: 100%;
  font-size: .8rem;
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2%;
`;
