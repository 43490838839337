import TSVGPropTypes from '../SVGPropTypes';

function ListButtonIcon({
  strokeWidth,
  strokeColor,
  bgColor,
  ...props
}: TSVGPropTypes) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 60 60"
      fill="none"
      cursor="pointer"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30Z"
        fill={bgColor ?? "black"}
      />
      <path
        d="M0.5 30C0.5 13.7076 13.7076 0.5 30 0.5C46.2924 0.5 59.5 13.7076 59.5 30C59.5 46.2924 46.2924 59.5 30 59.5C13.7076 59.5 0.5 46.2924 0.5 30Z"
        stroke={bgColor ?? "black"}
        strokeWidth={strokeWidth}
      />
      <path
        d="M20.375 23H39.625"
        stroke={strokeColor ?? "white"}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.375 30H39.625"
        stroke={strokeColor ?? "white"}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.375 37H31.75"
        stroke={strokeColor ?? "white"}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.125 37H41.375"
        stroke={strokeColor ?? "white"}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.75 34.375V39.625"
        stroke={strokeColor ?? "white"}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ListButtonIcon;
