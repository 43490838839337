import styled from 'styled-components';
import { Fragment } from 'react/jsx-runtime';

import { useNavigate } from 'react-router-dom';
import globalStyles from 'common/src/data/globalStyles';
import  { VideoIntroDuration } from 'common/src/components/IntroVideo';
import { useSyncStateWithSocket } from '@hooks/socket/useSyncStateWithSocket';
import { useSocketIO } from 'common/src/providers/sockets/SocketIOProvider';
import { useEffect, useRef, useState } from 'react';
import MenuButton from 'common/src/web_components/MenuButton';

function Splash() {
  const { syncMultipleStatesWithSocket } = useSyncStateWithSocket();
  const { emitSocketEvent, socket, room } = useSocketIO();
  const navigate = useNavigate();
  const [playIntro, doPlayIntro] = useState<boolean>(false);
  const [videoEnded, setVideoEnded] = useState<boolean>(false);
  const videoTimer = useRef(null);
  
  useEffect(() => {
    syncMultipleStatesWithSocket({
      navigating: false,
    });
    if (import.meta.env.VITE_IS_WEB_BUILD) {
      handleVideoPlay();
    }
  }, []);

  const handleVideoEnd = () => {
    videoTimer.current && clearTimeout(videoTimer.current);
    setVideoEnded(true);
    doPlayIntro(false);
    syncMultipleStatesWithSocket({
      navigating: true,
    });
    setTimeout(() => {
      emitSocketEvent('navigation', {
        path: '/rexall',
        id: socket?.id,
        room,
      });
      syncMultipleStatesWithSocket({
        playFullIntroVideo: false,
      });
  
      navigate('/rexall');
    }, 0);
  };

  const handleVideoPlay = () => {
    doPlayIntro(true);
    syncMultipleStatesWithSocket({
      playFullIntroVideo: true,
    });
    videoTimer.current = setTimeout(() => {
      handleVideoEnd();
    }, VideoIntroDuration);
  }
  return (
    <Fragment>
      <MenuButton onMenuClicked={()=>{
        syncMultipleStatesWithSocket({
          menuOpen: true,
          menuIndex: null
        });
      }} />
      <VideoElement
        muted
        autoPlay
        loop
        playsInline
        // src={'/assets/video/rexall_intro_short.mp4'}
        src={!import.meta.env.VITE_IS_WEB_BUILD ? '/assets/video/rexall_intro_short.mp4' : '/assets/video/rexall_intro_web.mp4'}
        onClick={() => { playIntro && handleVideoEnd() }}
      />
      {!videoEnded && <ButtonsWrapper>
        <VideoButton isVisible={playIntro} onClick={handleVideoEnd}>Tap to Skip</VideoButton>
        <VideoButton isVisible={!playIntro} onClick={handleVideoPlay}>Play</VideoButton>
      </ButtonsWrapper>}
    </Fragment>
  );
}

export default Splash;

const VideoElement = styled.video`
  height: 100%;
  width: 100%;
  object-fit: contain;
  background-color: ${globalStyles.colors.black};
`;

const ButtonsWrapper = styled.div`
  position: absolute;
  top: 80%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding: 20px;
  transition: opacity 1s ease-in-out;
`;

const VideoButton = styled.div<{isVisible: boolean}>`
  position: absolute;
  font-size: 1vw;
  text-transform: uppercase;
  text-align: center;
  color: ${globalStyles.colors.second}CC;
  padding: 20px;
  margin: 0 10px;
  cursor: pointer;
  transition: opacity 1s ease-in-out;
  visibility: ${({isVisible})  => isVisible ? 'visible' : 'hidden'};
  opacity: ${({isVisible})  => isVisible ? 1 : 0};
`;