/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, PropsWithChildren } from "react";
import styled from "styled-components"
import Logo from "../../components/Logo";
import Splash from "../../components/Splash";
import globalStyles from "../../data/globalStyles";

const ContentLayout: FC<PropsWithChildren<any>> = ({children, ...props}) => {
  return (
    <Wrapper {...props}>
      <Splash withOrigin={false} background={globalStyles.colors.main}/>
      <Logo/>
      <ContentWrapper>
        {children}
      </ContentWrapper>
    </Wrapper>
  )
}

export default ContentLayout

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
`

const ContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
