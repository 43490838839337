/* eslint-disable react-hooks/rules-of-hooks */
import { useRef } from 'react';
import * as THREE from 'three';
import { useGLTF } from '../../lib';
import { useLoader } from '@react-three/fiber';
import { TextureLoader } from 'three';

function ApartmentModel({ selectedUnit, ...props }) {
  if (!selectedUnit) return null;
  const type = selectedUnit?.floorplanCode;
  const GLTF = useGLTF(`/models/${type}.glb`);

  const {
    nodes: {
      mesh_0: { geometry: geo },
    },
  } = GLTF;

  const t = useLoader(TextureLoader, `/textures/floorplans/${type}.webp`);
  t.flipY = false;

  const modelRef = useRef<THREE.Mesh>(null);

  return (
    <group {...props}>
      <mesh geometry={geo} scale={100} ref={modelRef}>
        {/* <TextureTransitionMaterial
          textures={modelTextures}
          triggerIndex={textureID}
          ref={materialRef}
        /> */}
        <meshBasicMaterial map={t} />
      </mesh>
    </group>
  );
}

export default ApartmentModel;
