export const aerialMarkersData = [
  {
    src: "/assets/images/aerial/park.webp",
    x: 2900,
    y: 400,
    label: "High Park",
    line: {
      x1: 100,
      y1: 100,
      x2: 100,
      y2: 400
    }
  },
  {
    src: "/assets/images/aerial/lake.webp",
    x: 4800,
    y: 300,
    label: "Lake Ontario",
    line: {
      x1: 100,
      y1: 100,
      x2: 100,
      y2: 400
    }
  },
  {
    src: "/assets/images/aerial/liberty.webp",
    x: 3900,
    y: 400,
    label: "Liberty Village",
    line: {
      x1: 100,
      y1: 100,
      x2: 100,
      y2: 400
    }
  },
]

export type TAerialData = {
  src: string,
  x: number,
  y: number,
  label: string,
  line: {
    x1: number,
    y1: number,
    x2: number,
    y2: number
  }
}