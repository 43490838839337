import styled from 'styled-components';

import Button from 'common/src/components/Button';
import GridGallery from 'common/src/components/GridGallery';
import TransitionDiv from 'common/src/components/TransitionDiv';
import Popup from 'common/src/components/Popup';

import globalStyles from 'common/src/data/globalStyles';
import { useStore } from '@state/store';
import galleryPaths from 'common/src/data/galleryPaths';
import { useSyncStateWithSocket } from '@hooks/socket/useSyncStateWithSocket';
import MenuButton from 'common/src/web_components/MenuButton';
import { useEffect, useState } from 'react';
import CloseIcon from 'common/src/components/CloseIcon';
import Carousel from 'common/src/web_components/Carousel';

function Gallery() {
  const [fullOpen, setFullOpen] = useState(false);
  const { gallerySection, galleryIndex } = useStore((s) => ({
    gallerySection: s.gallerySection,
    galleryIndex: s.galleryIndex
  }));
  const { syncMultipleStatesWithSocket } = useSyncStateWithSocket();
  const sections = ['interiors', 'rexall', 'local area'];

  function onSelected(n) {
    syncMultipleStatesWithSocket({
      galleryIndex: n,
    });
  }
  const galleries = [
    <GridGallery
      indexState={galleryIndex}
      onSelectedImage={onSelected}
      paths={galleryPaths.thumbs.interiors}
      stagger
    />,
    <GridGallery
      indexState={galleryIndex}
      onSelectedImage={onSelected}
      paths={galleryPaths.thumbs.rexall}
      stagger
    />,
    <GridGallery
      indexState={galleryIndex}
      onSelectedImage={onSelected}
      paths={galleryPaths.thumbs['local area']}
      stagger
    />,
  ];

  useEffect(() => {
    if (galleryIndex !== null) {
      setTimeout(() => {
        setFullOpen(true);
      }, 550);
    }
  }, [galleryIndex]);
  return (
    <GalleryWrapper>
      <MenuButton onMenuClicked={()=>{
        syncMultipleStatesWithSocket({
          menuOpen: true,
          menuIndex: null
        });
      }} />
      <TransitionDiv
        childrenArr={galleries}
        triggerIndex={sections.indexOf(gallerySection)}
        durationMS={500}
        className="gridGalleryWrapper"
      />
      <Sections>
        {sections.map((label, i) => {
          return (
            <Button
              key={`galleryBtn${i}`}
              label={label}
              active={label === gallerySection}
              onClick={() => {
                syncMultipleStatesWithSocket({
                  gallerySection: label as any,
                  galleryIndex: null,
                });
              }}
            />
          );
        })}
      </Sections>
      <Popup
        openState={fullOpen}
        setOpenState={setFullOpen}
        durationMS={600}
        closeColor="transparent"
      >
        <Carousel slides={galleryPaths[gallerySection]}/>
        <CloseIcon
          className="closeIcon"
          bgColor={globalStyles.colors.second}
          strokeColor={globalStyles.colors.white}
          strokeWidth={2}
          onClick={() => {
            setFullOpen(false);
            setTimeout(() => {
              syncMultipleStatesWithSocket({
                galleryIndex: null,
              });
            }, 650);
          }}
        />
      </Popup>
    </GalleryWrapper>
  );
}

export default Gallery;

const GalleryWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: black;
  --gridSize: 90vh;

  .gridGalleryWrapper {
    top: calc((100% - var(--gridSize)) / 2);
    left: calc((100% - var(--gridSize)) / 2);
    width: var(--gridSize);
    height: var(--gridSize);
  }
  .closeIcon {
    position: absolute;
    top: ${globalStyles.margin};
    right: ${globalStyles.margin};
    width: clamp(30px, 4rem, 50px);
    height: clamp(30px, 4rem, 50px);
    pointer-events: all;
    z-index: 5;
    cursor: pointer;
  }
`;

const Sections = styled.div`
  position: absolute;
  bottom: ${globalStyles.margin};
  left: ${globalStyles.margin};
  display: flex;
  gap: ${globalStyles.margin};
`;

const Buttons = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  color: ${globalStyles.colors.white};
  
  & p{
    padding: ${globalStyles.margin};
    font-size: 1rem;
    :nth-of-type(1){
      background: linear-gradient(to right, ${globalStyles.colors.black}, transparent);
    }
    :nth-of-type(2){
      background: linear-gradient(to left, ${globalStyles.colors.black}, transparent);
    }
  }
`;

const FullScreenImg = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: ${globalStyles.colors.black};
`;
