import { BrowserRouter, Route, Routes } from "react-router-dom"
import { useSocketIO } from "common/src/providers/sockets/SocketIOProvider"
import { useEffect } from "react"
import DefaultLayout from "./DefaultLayout"
import '@radix-ui/themes/styles.css';

import { useStore } from "@state/store"
import { fetchData } from "common/src/lib/fetchData"

function App() {
  const { emitSocketEvent } = useSocketIO()
  const { fullUnits, syncStates } = useStore(s => ({
    fullUnits: s.fullUnits,
    syncStates: s.syncStates
  }))

  useEffect(() => {
    fetchData()
      .then((formatedData) => {
        syncStates({
          fullUnits: formatedData,
          filteredUnits: formatedData,
        });
      })
      .catch(() => {
        //TODO SET OFFLINE DATA
      });
  }, [])
  useEffect(() => {
    emitSocketEvent("forceJoin", {
      newRoom: "room1",
      path: window.location.pathname,
      isIpad: true,
    })
  }, [])
  useEffect(() => {
		if(fullUnits){
				let minLevel = Infinity
				let maxLevel = 1
				let minArea = Infinity
				let maxArea = 1
				for (let unit of fullUnits) {
					if (unit.status) {
						if (unit.level < minLevel) minLevel = unit.level
						if (unit.level > maxLevel) maxLevel = unit.level
						if (unit.area < minArea) minArea = unit.area
						if (unit.area > maxArea) maxArea = unit.area
					}
				}
        syncStates({
          minLevel: minLevel,
          maxLevel: maxLevel,
          levelRange: [minLevel, maxLevel],
          minArea: minArea,
          maxArea: maxArea,
          areaRange: [minArea, maxArea]
        })
		}
	}, [fullUnits])

  return <BrowserRouter>
      <Routes>
        <Route path="*" element={<DefaultLayout />} />
      </Routes>
    </BrowserRouter>
}

export default App
