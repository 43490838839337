import styled from 'styled-components';
import Splash from '../Splash';
import globalStyles from '../../data/globalStyles';
import AnimatedCircles from '../AnimatedCircles';
import { useMemo } from 'react';
import { useStore } from '../../../../rexall-controller/src/state/store';

type TNoFavoritesPromptProps = {
  size?: 'small' | 'large';
};

function NoFavoritesPrompt({size = 'large'}: TNoFavoritesPromptProps) {


  const { navigating } =
    useStore((s) => ({
      navigating: s.navigating,
    }));
  
  const colors = useMemo(() => {
    return [
      '#3c4628',
      '#ffffff',
      '#171515',
      '#8e7600',
    ];
    }, []);

  return (<NoFavoritesWrapper>
    <AnimatedCircles colorsArr={colors} cue={!navigating}/>
    <NoFavPrompt className="centerABS" size={size}>
      YOU
      HAVE 
      <br />
      NO 
      FAVORITES
      <br />
      YET
      <br />
    </NoFavPrompt>
  </NoFavoritesWrapper>);
}

export default NoFavoritesPrompt;

const NoFavoritesWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const NoFavPrompt = styled.div<{ size: string }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 60%;
  display: grid;
  place-content: center;
  text-align: center;
  font-size: ${(props) =>  props.size === 'small' ? '2rem' : '4rem'};
  font-family: main;
  color: ${globalStyles.colors.second};
`;

