import styled from 'styled-components';
import { useEffect } from 'react';

import globalStyles from 'common/src/data/globalStyles';
import MapController from './components/ControllerMap';

interface IImmersionMapControlProps {
  activeCategory: string;
  activePOI: number;
  onDestroy: () => void;
}

function ImmersionMapControl({activeCategory, activePOI, onDestroy}: IImmersionMapControlProps) {

  useEffect(() => {
    return () => {
      onDestroy();
    };
  }, []);

  return (
    <ImmersionMapControlWrapper>
      <Content>
        <MapController category={activeCategory} activeId={activePOI} />
      </Content>
    </ImmersionMapControlWrapper>
  );
}

export default ImmersionMapControl;

const ImmersionMapControlWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  & h1 {
    color: ${globalStyles.colors.white};
    font-family: title;
    font-size: 5rem;
  }

  & h3 {
    color: ${globalStyles.colors.second};
    font-family: subtitle;
    font-size: 3rem;
  }

  h6 {
    color: ${globalStyles.colors.third};
    text-transform: uppercase;
    font-size: 1.5rem;
    font-family: main;
  }
`;
