import styled from "styled-components"
import { useState } from "react"

function Square({ text }: { text: string }) {
  const [activeMesh, setActiveMesh] = useState<string>(null)

  return (
    <Wrapper>
      {text}
      <br />
      <button
        onClick={() => {
          setActiveMesh("building")
        }}
      >
        set building
      </button>
      {activeMesh ? `active: ${activeMesh[0]}  ` : ""}
      <br />
      clear <br />
      <button
        onClick={() => {
          setActiveMesh("clear")
        }}
      >
        clear
      </button>
    </Wrapper>
  )
}

export default Square

const Wrapper = styled.div`
  width: 60%;
  height: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1rem;
  background-color: teal;
  color: purple;
  display: grid;
  place-content: center;
  text-align: center;
`
