/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react"
import { Socket } from "socket.io-client"
import SocketClient from "../../lib/socket/io.client"

type Room = "room1" | "room2" | "disconnect" | null
type RoomStatus = {
  room1: boolean
  room2: boolean
  attempted: string | null
} | null

type SocketIOContextType = {
  socket: Socket | null
  room: Room | null
  roomTaken: boolean
  roomStatus: RoomStatus
  isOffline?: boolean
  setOffline?: (val: boolean) => void
  setRoomTaken?: (isTaken: boolean) => void
  joinRoom: (args: { room: Room; payload: any }) => void
  disconnectSocket?: (room: Room) => void
  leaveCurrentRoom?: () => void
  emitSocketEvent?: (eventName: any, data: any) => void
  emitSyncStateEvent?: (data: any, force?: boolean) => void
  onSocketEvent?: (eventName: any, callback: (data: any) => void) => void
  onSyncState?: (
    callback: (stateToUpdate: string, payload: any) => void,
  ) => void
  onGotNavigation?: (callback: (data: any) => void) => void
  onGotReload?: (callback: () => void) => void
}

const SocketIOContext = createContext<SocketIOContextType>({
  socket: null,
  room: null,
  roomTaken: false,
  roomStatus: null,
  setRoomTaken: () => {},
  joinRoom: () => {},
  disconnectSocket: () => {},
  leaveCurrentRoom: () => {},
  emitSocketEvent: () => {},
  emitSyncStateEvent: () => {},
  onSocketEvent: () => {},
  onSyncState: (callback: (stateToUpdate: string, payload: any) => void) => {},
  onGotNavigation: (callback: (data: any) => void) => {},
  onGotReload: (callback: () => void) => {},
})

type Action =
  | { type: "SET_ROOM"; payload: Room | null }
  | { type: "SET_ROOM_TAKEN"; payload: boolean }
  | { type: "ROOM_STATUS"; payload: RoomStatus }

const initialState = {
  room: null as Room | null,
  roomTaken: false,
  roomStatus: null as RoomStatus,
}

const reducer = (state: typeof initialState, action: Action) => {
  switch (action.type) {
    case "SET_ROOM":
      return { ...state, room: action.payload }
    case "SET_ROOM_TAKEN":
      return { ...state, roomTaken: action.payload }
    case "ROOM_STATUS":
      return { ...state, roomStatus: action.payload }
    default:
      return state
  }
}

export const useSocketIO = (): SocketIOContextType =>
  useContext(SocketIOContext)

type Host = `https://${string}` | `http://${string}`

type SocketIOURL = `${Host}:${number}`

interface SocketIOProviderProps {
  url: SocketIOURL
}

/**
 * Function to create a socket using socket.io
 * @param url - The URL for the socket connection
 * @returns Socket instance
 */
const createSocket = (url: string) => {
  if (!import.meta.env.VITE_IS_WEB_BUILD) {
    const socket = SocketClient.getInstance(url);
    return socket?.getSocket();
  }
  return null;
}

export const SocketIOProvider: FC<
  SocketIOProviderProps & PropsWithChildren
> = ({ url, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [isOffline, setOffline] = useState<boolean>(false);
  
  // Create the socket instance when the component mounts
  const socket = createSocket(url)

  const joinRoom = (args: { room: Room; payload: any }) => {
    if (isOffline) return;
    const { room, payload } = args
    socket?.emit("joinRoom", {
      room,
      isIpad: payload.isIpad,
    })
  }

  const disconnectSocket = (room: Room) => {
    if (isOffline) return;
    socket?.emit("leaveRoom", room)
    dispatch({ type: "SET_ROOM", payload: null })
  }

  const leaveCurrentRoom = () => {
    if (isOffline) return;
    // console.log("Leave current room:", state.room, socket?.id)

    disconnectSocket(state.room)
    socket?.disconnect()
    setTimeout(() => {
      window.location.reload()
    }, 200)
  }

  const emitSocketEvent = (eventName: any, data: any) => {
    if (isOffline) return;
    socket?.emit(eventName, data)
  }

  const emitSyncStateEvent = (data: any, force?: boolean) => {
    if (!force && isOffline) return;
    socket?.emit("syncState", {
      room: state.room,
      ...data,
      id: socket?.id,
    })
  }

  const onSocketEvent = (eventName: any, callback: (data: any) => void) => {
    socket?.on(eventName, callback)
  }

  const onSyncState = (
    callback: (stateToUpdate: string, payload: any) => void,
  ) => {
    socket?.on("got-syncState", (data: any) => {
      // console.log("Client got-syncState: ", data)
      if (data.id === socket?.id) {
        return
      }

      const { stateToUpdate, payload } = data
      callback(stateToUpdate, payload)
    })
  }

  const onGotNavigation = (callback: (data: any) => void) => {
    socket?.on("got-navigation", (payload: any) => {
      // console.log("Client got-navigation", payload)
      if (payload.id !== socket?.id) {
        // console.log("Execute got-navigation", payload)
        callback(payload)
      }
    })
  }

  const onGotReload = (callback: () => void) => {
    socket?.on("got-reload", (payload: any) => {
      if (payload.id !== socket?.id) {
        callback()
      }
    })
  }

  const setRoomTaken = (isTaken: boolean) => {
    dispatch({ type: "SET_ROOM_TAKEN", payload: isTaken })
  }

  // Mount socket instance
  useEffect(() => {
    if(socket === null || isOffline) return;
    // console.log("SocketIOProvider mounted", url)

    socket?.on("connect", () => {
      // console.log("Socket connected Woop woop")
    })

    socket?.on("error", (error: any) => {
      console.error("Socket error:", error)
      // Should we close the socket here?
      // socket?.close()
    })

    socket?.on("disconnect", () => {
      // console.log("Socket disconnected")
      socket?.removeAllListeners()
      // Socket is automatically closed
      // socket?.close()
    })

    socket?.on("reconnect_attempt", (attemptNumber: number) => {
      // console.log(`Reconnect attempt: ${attemptNumber}`)
    })

    // Custom States
    socket?.on("joinedRoom", (data) => {
      // console.log("Joined:", data)
      dispatch({ type: "SET_ROOM", payload: data.room })
    })

    socket?.on("roomTaken", (data: RoomStatus) => {
      // console.log("roomTaken", data)
      dispatch({ type: "SET_ROOM_TAKEN", payload: true })
      dispatch({ type: "ROOM_STATUS", payload: data })
    })

    socket?.on("switchedRoom", (data) => {
      // console.log("switchedRoom", data)
      dispatch({ type: "SET_ROOM", payload: data.room })
    })

    socket?.on("disconnectAll", (room: Room) => {
      socket?.emit("leaveRoom", room)
      socket?.disconnect()
      dispatch({ type: "SET_ROOM", payload: null })
    })
  }, [])

  return (
    <SocketIOContext.Provider
      value={{
        socket,
        room: state.room,
        roomTaken: state.roomTaken,
        roomStatus: state.roomStatus,
        isOffline,
        setOffline,
        joinRoom,
        disconnectSocket,
        leaveCurrentRoom,
        emitSocketEvent,
        emitSyncStateEvent,
        setRoomTaken,
        onSocketEvent,
        onSyncState,
        onGotNavigation,
        onGotReload,
      }}
    >
      {children}
    </SocketIOContext.Provider>
  )
}
