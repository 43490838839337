const globalStyles = {
  colors: {
    main: "#3C441C",
    second: "#A6855A",
    third: "#AEB7B3",
    accent: "#8E3D20",
    orange:"#CC7643",
    white: "#FFFFFF",
    black: "#000000",
    light: "#FFFFFF",
    medium: "#A6855A",
    dark: "#3C441C",
    red: "#BE3D20",
    eggShellWhite: "#FFFFFF",

  },
  fontFamily: "Avenir",
  fontSize: {
    xs: "14px",
    s: "19px",
    m: "24px",
    l: "32px",
    xl: "40px",
    xxl: "48px",
    xxxl: "56px",
  },
  margin: "3vh",
  borderRadius: "20px"
}

export default globalStyles

