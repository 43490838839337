import { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import ReactDOMServer from "react-dom/server"

import LoadingIcon from "../SVGIcons/LoadingIcon"
import EmailTemplate from "../EmailTemplate"

import globalStyles from "../../data/globalStyles"
import { validation } from "../../utils/validation"

type TForm = {
  name: string | undefined | null
  surname: string | undefined | null
  email: string | undefined | null
  link?: string | undefined | null
}

const initialForm:TForm = {
  name: null,
  surname: null,
  email: null,
}

function Form({encodedFav}) {
  const promptRef = useRef<HTMLHeadingElement>(null)
  const [formData, setFormData] = useState<TForm | null>(initialForm)
  const [formMessage, setFormMessage] = useState("")
  const [readyToSend, setReadyToSend] = useState(false)
  const [sending, setSending] = useState(false)
  const url = `https://rexall-app-shared.vercel.app?${encodedFav}`

  function resetPrompt() {
    if(!promptRef.current) return
    promptRef.current.style.opacity = "0"
    setTimeout(() => {
      setFormMessage("")
    }, 800)
  }

  function handleSubmit(e) {
    e.preventDefault()
    const form = document.getElementById("form") as HTMLFormElement
    const errors = validation(formData)
    if (errors !== undefined) {
      setFormMessage(errors)
      if(!promptRef.current) return
      promptRef.current.style.opacity = "1"
    } else {
      setFormData({ ...formData, link: url } as TForm)
      setSending(true)
      setReadyToSend(true)
      form.reset()
    }
  }

  useEffect(() => {
    if (formData && readyToSend) {
      
      const htmlTemplate = ReactDOMServer.renderToStaticMarkup(
        <EmailTemplate
          link={url}
        />
      )
      fetch(`https://api-gateway.vmiservers.com/mailer/send`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...formData,
          from: `Leasing at Origin <sales@originstclair.app>`,
          html: htmlTemplate,
          subject: `Hi ${formData.name} ${formData.surname}, Here are your favorites.`,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            setReadyToSend(false)
            setFormData(initialForm)
            setSending(false)
            setFormMessage("Email sent")
            if(!promptRef.current) return
            promptRef.current.style.opacity = "1"
          }
        })
        .catch((err) => {
          setReadyToSend(false)
          setFormData(initialForm)
          setSending(false)
          setFormMessage("Email failed to send. Pleas try again later.")
          if(!promptRef.current) return
          promptRef.current.style.opacity = "1"
          console.error(
            "There has been a problem with your fetch operation:",
            err
          )
        })
    }
    return () => {
      setReadyToSend(false)
      setFormMessage("")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyToSend])

  return (
    <FormWrapper>
      <h1>
        Share Your Favorites
      </h1>
      <h6 ref={promptRef}>{formMessage}</h6>
      <form id="form" autoComplete="off">
        <input
          placeholder="First Name"
          name="fName"
          type="text"
          max="20"
          autoComplete="off"
          onInput={(e) => {
            setFormData({
              ...formData,
              name: (e.target as HTMLInputElement).value,
            } as TForm)
            resetPrompt()
          }}
          style={{
            gridColumn: "1 / span 3"
          }}
        />

        <input
          placeholder="Last Name"
          name="lName"
          type="text"
          max="20"
          autoComplete="off"
          onInput={(e) => {
            setFormData({
              ...formData,
              surname: (e.target as HTMLInputElement).value,
            } as TForm)
            resetPrompt()
          }}
          style={{
            gridColumn: "4 / span 3"
          }}
        />

        <input
          placeholder="Email"
          name="Email"
          type="Email"
          max="30"
          autoComplete="off"
          onInput={(e) => {
            setFormData({
              ...formData,
              email: (e.target as HTMLInputElement).value,
            } as TForm)
            resetPrompt()
          }}
          style={{
            gridColumn: "1 / span 6"
          }}
        />
        <button
          className="Email"
          onClick={(e) => {
            handleSubmit(e)
          }}
          style={{
            gridColumn: "3 / span 2"
          }}
        >
          {sending ? (
            <LoadingIcon color={globalStyles.colors.main} />
          ) : (
            "SUBMIT"
          )}
        </button>
      </form>
    </FormWrapper>
  )
}

export default Form

const FormWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${globalStyles.colors.main};
  
  & h1 {
    width: 100%;
    height: 30%;
    font-family: main;
    text-align: center;
    font-size: 2.8rem;
    padding: 2%;
    letter-spacing: 0.1rem;
    color: ${globalStyles.colors.second};
  }
  
  & h6 {
    width: 100%;
    height: 10%;
    text-align: center;
    font-size: 1.5rem;
    transition: opacity 0.8s ease-in-out;
    color: ${globalStyles.colors.white};
    opacity: 0;
    font-family: title;
  }

  & form {
    color: ${globalStyles.colors.main};
    width: 80%;
    height: 70%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-gap: 10px;

    & input {
      width: 100%;
      height: 4rem;
      border: none;
      border: 0.1rem solid ${globalStyles.colors.white};
      padding: 1rem;
      border-radius: 0;
      background-color: transparent;
      color: ${globalStyles.colors.white} !important;
      font-size: 1.3rem;
      pointer-events: all;
      cursor: pointer;
      font-family: title;

      ::placeholder {
        color: ${globalStyles.colors.white};
        letter-spacing: 0.2rem;
        font-size: 1.3rem;
        opacity: 0.8;
      }
      :focus-visible {
        outline: none;
        background-color: transparent;
      }
      :-webkit-autofill,
      :-webkit-autofill:hover,
      :-webkit-autofill:focus,
      :-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        color: ${globalStyles.colors.white} !important;
      }
    }

    & button {
      background: none;
      border: none;
      font-size: 1.5rem;
      background-color: ${globalStyles.colors.second};
      color: ${globalStyles.colors.white};
      border-radius: 50px;
      width: 100%;
      height: 100%;
      pointer-events: all;
      cursor: pointer;
      display: grid;
      place-content: center;
      font-family: main;
      font-size: 1rem;
      font-weight: 100;
    }
  }
`
