import TSVGPropTypes from '../SVGPropTypes';

function CubeIcon({
  strokeWidth,
  strokeColor = "white",
  bgColor = "black",
  ...props
}: TSVGPropTypes) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 61 61"
      width="100%"
      height="100%"
      {...props}
    >

      <path
        d="M0.5 30C0.5 13.7076 13.7076 0.5 30 0.5C46.2924 0.5 59.5 13.7076 59.5 30C59.5 46.2924 46.2924 59.5 30 59.5C13.7076 59.5 0.5 46.2924 0.5 30Z"

				fill={bgColor}
      />
      <path
        d="M40.5 35.3945V24.6055C40.5 24.4502 40.4587 24.2977 40.3803 24.1637C40.3019 24.0297 40.1893 23.919 40.054 23.8428L30.429 18.4288C30.298 18.3551 30.1503 18.3164 30 18.3164C29.8497 18.3164 29.702 18.3551 29.571 18.4288L19.946 23.8428C19.8107 23.919 19.6981 24.0297 19.6197 24.1637C19.5413 24.2977 19.5 24.4502 19.5 24.6055V35.3945C19.5 35.5497 19.5413 35.7022 19.6197 35.8362C19.6981 35.9702 19.8107 36.081 19.946 36.1571L29.571 41.5712C29.702 41.6448 29.8497 41.6835 30 41.6835C30.1503 41.6835 30.298 41.6448 30.429 41.5712L40.054 36.1571C40.1893 36.081 40.3019 35.9702 40.3803 35.8362C40.4587 35.7022 40.5 35.5497 40.5 35.3945Z"
        stroke={strokeColor}
				strokeWidth={strokeWidth}
				fill={bgColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.3793 24.1622L30.1037 29.9999L19.6211 24.1611"
        stroke={strokeColor}
				strokeWidth={strokeWidth}
				fill={bgColor }
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.1037 30L30.001 41.6836"
        stroke={strokeColor}
				strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CubeIcon;
