import styled from "styled-components"
import globalStyles from "../data/globalStyles"
import Logo from './Logo'
import grain from '../assets/grain.svg'
import Origin from "./Origin"
import { FC } from "react";
import { MouseEvent } from 'react';

interface ISplashProps {
  opacity?: string
  withOrigin?: boolean
  background?: string
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
}

const Splash: FC<ISplashProps> = ({opacity="0.5", withOrigin=true, background, onClick }) => {
  return <LogoWrapper background={background} onClick={(ev) => {
    onClick && onClick(ev)
  }}>
    <Logo opacity={opacity}/>
    {withOrigin && <Origin></Origin>}
  </LogoWrapper>
}

const LogoWrapper = styled.div<{ background?: string }>`
  width: 100%;
  height: 100%;
  background: url(${grain}), lightgray 50% / cover no-repeat;
  background-color: ${(props) =>  props.background || globalStyles.colors.main};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 0;
`

export default Splash