import { forwardRef, useImperativeHandle, useRef } from "react";
import styled from "styled-components"
import { isVideoPlaying } from "../../utils/video";

export const VideoIntroPath = '/assets/video/rexall_intro.mp4';
export const VideoIntroDuration = 31 * 1000; // 31 seconds

const IntroVideo = forwardRef((_, ref) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useImperativeHandle(ref, () => ({
    restartVideo: () => {
      if (videoRef.current) {
        videoRef.current.currentTime = 0;
        if (!isVideoPlaying(videoRef.current)) {
          videoRef.current.play().catch(e => console.error('Error while trying to play video:', e));
        }
      }
    },
    setCurrentTime: (val: number) => { if (videoRef.current) { videoRef.current.currentTime = val } },
    play: () => {
      if (videoRef.current && !isVideoPlaying(videoRef.current)) {
        videoRef.current.play().catch(e => console.error('Error while trying to play video:', e));
      }
    },
    pause: () => {
      if (videoRef.current) {
        videoRef.current.pause();
      }
    }
  }));
  return (
    <VideoElement
      ref={videoRef}
      muted
      playsInline
      src={VideoIntroPath}
      className='centerABS'
    />
  )
});

export default IntroVideo

const VideoElement = styled.video`
  height: 100%;
  width: 100%;
  object-fit: cover;
`