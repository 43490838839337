import styled from "styled-components"

import { TUnit } from "../../lib/formatData"
import { organizeAspects } from "../../utils/functions"
import { UnitViewPan } from "./UnitViewPan";

function UnitView({data, isWebComponent, ...props}: { data:TUnit, isWebComponent?: boolean, [x:string]: unknown}) {
  const totalViews = 3
  const maxLevel = 11
  const height = data
		? Math.floor((totalViews / maxLevel) * data.level)
		: 0
  const views = data ? organizeAspects([...data["aspect"]]) : ["N"]
  const files = [
    "/assets/images/views/4.webp",
    "/assets/images/views/6.webp",
    "/assets/images/views/9.webp",
    "/assets/images/views/12.webp",
  ]
  const offset = {
    N: "-75%",
    E: "-150%",
    S: "75%",
    W: "150%",
  }
  return (
    !isWebComponent ? <ViewImage
      style={{
        backgroundImage: `url(${files[height]})`,
        backgroundPositionX: offset[views[0]]
      }}
      {...props}
    >
    </ViewImage> : 
    <UnitPanWrapper>
      <UnitViewPan src={files[height]} offset={offset[views[0]]}></UnitViewPan>
    </UnitPanWrapper>
  )
}

export default UnitView

const ViewImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: auto 101%;
  background-repeat: repeat-x;
  opacity: 0;
`
const UnitPanWrapper = styled.div`
  width: 100%;
  height: 100%;
`