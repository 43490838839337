import SVGCircle from '../SVGCircle';

type TMapMarkerProps = {
  circleradius: number;
  bgcolor?: string;
  bordercolor?: string;
  borderwidth?: number;
  text: string | number;
  textcolor?: string | undefined;
  textSize?: number | undefined;
  [x: string]: unknown;
};

function MapMarker(props: TMapMarkerProps) {
  const {
    circleradius,
    bgcolor,
    bordercolor,
    borderwidth,
    text,
    textcolor,
    textSize,
  } = props;
  return (
    <SVGCircle
      radius={circleradius}
      bgcolor={bgcolor ?? 'white'}
      bordercolor={bordercolor ?? 'white'}
      borderwidth={borderwidth ?? 1}
      {...props}
    >
      <text
        fill={textcolor ?? 'black'}
        fontSize={textSize ?? circleradius / 2}
        x={'50%'}
        y={'50%'}
        textAnchor="middle"
      >
        <tspan alignmentBaseline="central">{text}</tspan>
      </text>
    </SVGCircle>
  );
}

export default MapMarker;
