import TSVGPropTypes from '../SVGPropTypes';

function EyeIcon({
  strokeWidth,
  strokeColor = 'white',
  bgColor = 'black',
  ...props
}: TSVGPropTypes) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 61 61"
      width="100%"
      height="100%"
      {...props}
    >
      <path
        d="M0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30Z"
        fill={bgColor}
        strokeWidth={strokeWidth}
      />
      <path
        d="M30 22.124C21.25 22.124 17.75 29.9999 17.75 29.9999C17.75 29.9999 21.25 37.874 30 37.874C38.75 37.874 42.25 29.9999 42.25 29.9999C42.25 29.9999 38.75 22.124 30 22.124Z"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 34.375C32.4162 34.375 34.375 32.4162 34.375 30C34.375 27.5838 32.4162 25.625 30 25.625C27.5838 25.625 25.625 27.5838 25.625 30C25.625 32.4162 27.5838 34.375 30 34.375Z"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default EyeIcon;
