import TSVGPropTypes from '../SVGPropTypes';

function FilterButtonIcon({
  strokeWidth,
  strokeColor,
  bgColor,
  ...props
}: TSVGPropTypes) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30Z"
        fill={bgColor ?? "black"}
      />
      <path
        d="M0.5 30C0.5 13.7076 13.7076 0.5 30 0.5C46.2924 0.5 59.5 13.7076 59.5 30C59.5 46.2924 46.2924 59.5 30 59.5C13.7076 59.5 0.5 46.2924 0.5 30Z"
        stroke={bgColor ?? "black"}
        strokeWidth={strokeWidth}
      />
      <path
        d="M32.1875 34.8115L20.375 34.8115"
        stroke={strokeColor ?? "white"}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.625 34.8115L36.5625 34.8115"
        stroke={strokeColor ?? "white"}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.375 36.999C35.5831 36.999 36.5625 36.0196 36.5625 34.8115C36.5625 33.6034 35.5831 32.624 34.375 32.624C33.1669 32.624 32.1875 33.6034 32.1875 34.8115C32.1875 36.0196 33.1669 36.999 34.375 36.999Z"
        stroke={strokeColor ?? "white"}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.1875 25.1866L20.375 25.1865"
        stroke={strokeColor ?? "white"}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.625 25.1865L29.5625 25.1866"
        stroke={strokeColor ?? "white"}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.375 27.374C28.5831 27.374 29.5625 26.3946 29.5625 25.1865C29.5625 23.9784 28.5831 22.999 27.375 22.999C26.1669 22.999 25.1875 23.9784 25.1875 25.1865C25.1875 26.3946 26.1669 27.374 27.375 27.374Z"
        stroke={strokeColor ?? "white"}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default FilterButtonIcon;
