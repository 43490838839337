import styled from 'styled-components';

import TransitionDiv from 'common/src/components/TransitionDiv';
// import UnitView from 'common/src/components/UnitView';
import UnitDetails from 'common/src/components/UnitDetails';
import Button from 'common/src/components/Button';
import HeartIcon from 'common/src/components/SVGIcons/HeartIcon';
import EyeIcon from 'common/src/components/SVGIcons/EyeIcon';
import PlanIcon from 'common/src/components/SVGIcons/PlanIcon';
import CubeIcon from 'common/src/components/SVGIcons/CubeIcon';
import Pland2DWrapper from 'common/src/components/FloorplanManager/Plan2DWrapper';

import globalStyles from 'common/src/data/globalStyles';
import { TUnit } from 'common/src/lib/formatData';
import { toggleArrayItem } from '../../utils/functions';
import { TInitialState } from '../../data/initialState';
import gsap from 'gsap';
import UnitView from '../../components/UnitView';
import { useEffect, useState } from 'react';
interface IUnitCardProps
  extends Pick<
    TInitialState,
    'unitCardOpen' | 'unitCardSection' | 'favorites' | 'palette'
  > {
  data: TUnit;
  excludeFinishes?: boolean;
  onFavouritesToggled: (toggled: string[]) => void;
  onPaletteClicked: (i: number) => void;
  onUnitCardSectionClicked: (i: number) => void;
  onCloseClicked: () => void;
}

function UnitCard({
  unitCardOpen,
  unitCardSection,
  favorites,
  palette,
  data,
  excludeFinishes,
  onFavouritesToggled,
  onPaletteClicked,
  onCloseClicked,
  onUnitCardSectionClicked,
}: IUnitCardProps) {
  const finishes = ['Light', 'Medium', 'Dark'];
  const sections = [
    <></>,
    <></>,
  ];

  console.log(data?.type)


  const isFave = () =>
    favorites ? favorites.some((e) => e === data?.unit) : false;
  const iconsArr = [CubeIcon, PlanIcon, EyeIcon];

  useEffect(() => {
    if (unitCardSection === 1) {
      gsap.to('.plan2d', {
        autoAlpha: 1,
        stagger: 0.1,
        duration: 0.6,
        ease: 'power3.inOut',
      });
      gsap.to('.view', {
        autoAlpha: 0,
        stagger: 0.1,
        duration: 0.6,
        ease: 'power3.inOut',
      });
    } else if (unitCardSection === 2) {
      gsap.to('.view', {
        autoAlpha: 1,
        stagger: 0.1,
        duration: 0.6,
        ease: 'power3.inOut',
      });
      gsap.to('.plan2d', {
        autoAlpha: 0,
        stagger: 0.1,
        duration: 0.6,
        ease: 'power3.inOut',
      });
    }
     else if (unitCardSection === 0) {
      gsap.to('.view', {
        autoAlpha: 0,
        stagger: 0.1,
        duration: 0.6,
        ease: 'power3.inOut',
      });
      gsap.to('.plan2d', {
        autoAlpha: 0,
        stagger: 0.1,
        duration: 0.6,
        ease: 'power3.inOut',
      });
    }
  }, [unitCardSection])

  return (
    <UnitCardWrapper
      style={{
        opacity: unitCardOpen ? 1 : 0,
      }}
    >
      {data && (
        <UnitDetails
          unitData={data}
          favorites={favorites}
          favoriteClick={() => {
            const toggled = toggleArrayItem(favorites, data.unit);
            onFavouritesToggled(toggled);
          }}
          closeClick={() => {
            onCloseClicked();
          }}
          className="detailsCard"
        />
      )}
      {!excludeFinishes && (
        <Finishes
          style={{
            opacity: unitCardSection === 0 ? 1 : 0,
            pointerEvents: unitCardSection === 0 ? 'all' : 'none',
          }}
        >
          {finishes.map((label, i) => {
            return (
              <Button
                key={`specBtn${i}`}
                label={label}
                active={i === palette}
                onClick={() => {
                  onPaletteClicked(i);
                }}
              />
            );
          })}
        </Finishes>
      )}
      <Icons>
        {iconsArr.map((Icon, i) => {
          return (
            <Icon
              key={`unitCardIcon${i}`}
              bgColor={
                unitCardSection === i
                  ? globalStyles.colors.white
                  : globalStyles.colors.black
              }
              strokeColor={
                unitCardSection === i
                  ? globalStyles.colors.black
                  : globalStyles.colors.white
              }
              strokeWidth={0.7}
              className="sectionIcon"
              onClick={() => {
                onUnitCardSectionClicked(i);
              }}
            />
          );
        })}
        <HeartIcon
          strokeColor={'white'}
          strokeWidth={0.7}
          bgColor={'black'}
          heartColor={isFave() ? 'white' : 'black'}
          className="sectionIcon"
          onClick={() => {
            const toggled = toggleArrayItem(favorites, data.unit);
            onFavouritesToggled(toggled);
            // syncStates({
            //   favorites: toggled,
            // });
          }}
        />
      </Icons>
      <SectionWrapper>
      <Pland2DWrapper className='plan2d'>
      <img
        src={`/assets/images/floorplans/2d/${data?.type}.png`}
        className="plan"
      />

      <img
        src={`/assets/images/floorplates/${data?.type}/${data?.type}${data?.level}.jpg`}
        className="plate"
      />
    </Pland2DWrapper>
    <UnitView data={data} className="view" isWebComponent={true} />,
      </SectionWrapper>
    </UnitCardWrapper>
  );
}

export default UnitCard;

const UnitCardWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;

  .close {
    width: 2rem;
    height: 2rem;
    position: absolute;
    right: 0%;
    top: 0%;
    pointer-events: all;
    cursor: pointer;
  }

  .favIconCard {
    width: 1rem;
    height: 1rem;
  }

  .sectionIcon {
    width: 4rem;
    height: 4rem;
    pointer-events: all;
    cursor: pointer;
    > * {
      transition: fill 0.5s ease-in-out;
    }
  }

  .detailsCard {
    bottom: unset;
    width: 30%;
    height: 30%;
    top: ${globalStyles.margin};
    background-color: #0000007b;
    border-color: ${globalStyles.colors.main};
    & h1 {
      color: ${globalStyles.colors.light};
      padding-bottom: 1rem;
    }
    & h3,
    h3 span {
      color: ${globalStyles.colors.white};
    }
  }
`;

const SectionWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;

  .plan {
    position: absolute;
    width: calc(65% - ${globalStyles.margin});
    height: 70vh;
    object-fit: contain;
  }

  .plate {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 4rem;
    width: 15%;
    object-fit: contain;
    filter: invert(100%) saturate(0);
  }

  .view {
    background-size: 150% auto;
    background-position-y: 50%;
    background-color: ${globalStyles.colors.black};
  }
`;

const Finishes = styled.div`
  position: absolute;
  bottom: ${globalStyles.margin};
  left: ${globalStyles.margin};
  display: flex;
  gap: ${globalStyles.margin};
  z-index: 2;
  transition: opacity 0.8s ease-in-out;
`;

const Icons = styled.div`
  position: absolute;
  bottom: ${globalStyles.margin};
  right: ${globalStyles.margin};
  display: flex;
  gap: ${globalStyles.margin};
  z-index: 2;
`;
