const galleryPaths = {
  interiors: [
    "/assets/images/interiors/master.webp",
    "/assets/images/interiors/door-knob.webp",
    "/assets/images/interiors/bed.webp",
    "/assets/images/interiors/bed-table.webp",
    "/assets/images/interiors/master-2.webp",
    "/assets/images/interiors/chair.webp",
    "/assets/images/interiors/living-room.webp",
    "/assets/images/interiors/bar.webp",
    "/assets/images/interiors/carpet.webp",
    "/assets/images/interiors/coffee-table.webp",
    "/assets/images/interiors/sink.webp",
    "/assets/images/interiors/deco.webp",
  ],
  rexall: [
    "/assets/images/exteriors/hero.webp",
    "/assets/images/amenities/chefs-room.webp",
    "/assets/images/amenities/fitness-centre.webp",
    "/assets/images/amenities/fitness-yoga.webp",
    "/assets/images/exteriors/hummingbird.webp",
    "/assets/images/amenities/lobby.webp",
    "/assets/images/amenities/media-room.webp",
    "/assets/images/amenities/social-lounge.webp",
    "/assets/images/exteriors/terrace.webp",
    "/assets/images/amenities/kids-room.webp",
    "/assets/images/interiors/deco.webp",
    "/assets/images/map/9.webp",
  ],
  "local area": [
    "/assets/images/map/1.webp",
    "/assets/images/map/2.webp",
    "/assets/images/map/8.webp",
    "/assets/images/map/11.webp",
    "/assets/images/map/12.webp",
    "/assets/images/map/14.webp",
    "/assets/images/map/15.webp",
    "/assets/images/map/20.webp",
    "/assets/images/map/26.webp",
    "/assets/images/map/28.webp",
    "/assets/images/map/32.webp",
    "/assets/images/map/33.webp",
  ],
  thumbs: {
    interiors: [
      "/assets/images/interiors/thumb/master.webp",
      "/assets/images/interiors/thumb/door-knob.webp",
      "/assets/images/interiors/thumb/bed.webp",
      "/assets/images/interiors/thumb/bed-table.webp",
      "/assets/images/interiors/thumb/master-2.webp",
      "/assets/images/interiors/thumb/chair.webp",
      "/assets/images/interiors/thumb/living-room.webp",
      "/assets/images/interiors/thumb/bar.webp",
      "/assets/images/interiors/thumb/carpet.webp",
      "/assets/images/interiors/thumb/coffee-table.webp",
      "/assets/images/interiors/thumb/sink.webp",
      "/assets/images/interiors/thumb/deco.webp",
    ],
    rexall: [
      "/assets/images/exteriors/thumb/hero.webp",
      "/assets/images/amenities/thumb/chefs-room.webp",
      "/assets/images/amenities/thumb/fitness-centre.webp",
      "/assets/images/amenities/thumb/fitness-yoga.webp",
      "/assets/images/exteriors/thumb/hummingbird.webp",
      "/assets/images/amenities/thumb/lobby.webp",
      "/assets/images/amenities/thumb/media-room.webp",
      "/assets/images/amenities/thumb/social-lounge.webp",
      "/assets/images/exteriors/thumb/terrace.webp",
      "/assets/images/amenities/thumb/kids-room.webp",
      "/assets/images/interiors/thumb/deco.webp",
      "/assets/images/map/thumb/9.webp",
    ],
    "local area": [
      "/assets/images/map/thumb/1.webp",
      "/assets/images/map/thumb/2.webp",
      "/assets/images/map/thumb/8.webp",
      "/assets/images/map/thumb/11.webp",
      "/assets/images/map/thumb/12.webp",
      "/assets/images/map/thumb/14.webp",
      "/assets/images/map/thumb/15.webp",
      "/assets/images/map/thumb/20.webp",
      "/assets/images/map/thumb/26.webp",
      "/assets/images/map/thumb/28.webp",
      "/assets/images/map/thumb/32.webp",
      "/assets/images/map/thumb/33.webp",
    ]
  }
}

export default galleryPaths